import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './Welcome.css';
import XSDataContext from './context/XSDataContext';
import imgNext from './images/icon_next.png';
import imgQuote from './images/icon_quote.png';
import imgClose from './images/icon_close.png';
import WishesComp from './WishesComp';
import { musicData, stoneData, taLabel } from './beans/Constrants';
import { formatDate, inAnimation, outAnimation } from './util/CommonUtil';
import imgMineEdit from './images/icon_mine_edit.png';
import imgMineMusic from './images/icon_mine_music.png';
import imgMineMusicMute from './images/icon_mine_music_mute.png';
import SoundBtn from './components/SoundBtn';
import XSMusicContext from './context/XSMusicContext';
import TombstoneComp from './TombstoneComp';
import Lottie from 'lottie-react';
import { animate, cubicBezier, linear } from 'popmotion';
import { xslog } from './util/XSLog';
import { doClose } from './util/NativeBridge';
import { Environment } from './util/environment';
interface ParticipateProps {
    detail: any;
    relation: string;
    goNext: () => void;
}

const Participate = forwardRef<any, ParticipateProps>((props, ref) => {
    const { statusBarHeight, environment, inApp, ghostLottie } = React.useContext(XSDataContext);
    const { bgPlayerRef, musicPlayerRef, startPlayBgMusic } = React.useContext(XSMusicContext);
    const [step, setStep] = useState(-1)
    const [showBtn, setShowBtn] = useState(false)
    const [currentAudio, setCurrentAudio] = useState(null);
    const [musicPlaying, setMusicPlaying] = useState(false)

    const [showContent, setShowContent] = useState("")

    const [clickTime, setClickTime] = useState(0)
    const containerRef = useRef(null);
    const btnRef = useRef(null);
    const [windowHeight, setWindowHeight] = useState(0)

    const [btnClickable, setBtnClickable] = useState(false)

    useEffect(() => {
        if (showBtn) {
            setTimeout(() => {
                animate({
                    from: 0,
                    to: 1,
                    ease: linear,
                    duration: 300,
                    onUpdate: (progress) => {
                        if (btnRef.current) {
                            btnRef.current.style.opacity = progress
                        }
                    },
                    onComplete: () => {
                        setBtnClickable(true)
                    }
                })
            }, 200)
        } else {
            if (btnRef.current) {
                btnRef.current.style.opacity = 0
            }
            setBtnClickable(false)
        }
    }, [showBtn]);

    const contentText1 = `这是${taLabel(props.detail.sex)}为自己精心挑选的「${stoneData[props.detail.skin].name}」\n${taLabel(props.detail.sex)}在${formatDate(new Date(props.detail.dieday))}离开了这个世界，因为\n“${props.detail.reason}”\n${taLabel(props.detail.sex)}给世界留下的最后一句话是\n“${props.detail.epitaph}”`;

    const contentText2 = `你听到了吗\n没错 这是${taLabel(props.detail.sex)}专门给自己请的葬礼乐队\n「${musicData[props.detail.bgm].name}」`;


    useEffect(() => {
        xslog('step is ' + step)
        let interval;
        if (step >= 0 && step < 2) {

            if (contentRef.current) {
                contentRef.current.style.opacity = 1;
            }
            setShowBtn(false)
            let index = 0;
            let textS = ""
            if (step == 0) {
                textS = contentText1;
            } else { textS = contentText2; }
            setShowContent("")
            interval = setInterval(() => {
                setShowContent((prev) => prev + textS[index++]);
                if (index >= textS.length - 1) {
                    clearInterval(interval);
                    setShowBtn(true)
                }
            }, 50); // 控制每个字符出现的速度
        } else {
            setShowBtn(false)
        }
        return () => clearInterval(interval);
    }, [step]);

    const next = () => {
        let now = Date.now()
        if (now - clickTime < 1000) {
            return;
        }
        setClickTime(now)
        if (step < 1) {

            if (step == 0) {
                animate({
                    from: 0,
                    to: 1,
                    ease: linear,
                    duration: 300,
                    onUpdate: (progress) => {
                        if (contentRef.current) {
                            contentRef.current.style.opacity = 1 - progress;
                        }
                        if (btnRef.current) {
                            btnRef.current.style.opacity = 1 - progress;
                        }
                    },
                    onComplete: () => {
                        if (bgPlayerRef.current) {
                            bgPlayerRef.current.pause();
                        }
                        const newAudio = new Audio(musicData[props.detail.bgm].url);
                        newAudio.loop = true;
                        musicPlayerRef.current = newAudio
                        setCurrentAudio(newAudio);
                        setMusicPlaying(true)
                        setStep((oldV) => oldV + 1)
                        if (contentRef.current) {
                            setShowContent("")
                        }
                    }
                })

            } else {

                setStep((oldV) => oldV + 1)
            }
        } else if (step == 1) {
            if (props.detail.lastwish.length > 0) {
                goWishes();
            } else {
                goNextPage()
            }

        } else {
            goNextPage()
        }
    }

    const goNextPage = () => {
        if (rootRef.current) {
            outAnimation(rootRef.current, () => {
                props.goNext()
            })
        } else {
            props.goNext()
        }
    }

    const goWishes = () => {
        setStep(3)
        if (containerRef.current) {
            containerRef.current.style.filter = 'blur(10px)'
        }
    }


    useEffect(() => {

        if (currentAudio) {

            currentAudio.play();
        }
        return () => {
            if (currentAudio) {
                currentAudio.pause();
            }
        };
    }, [currentAudio]);

    useEffect(() => {
        setWindowHeight(window.innerHeight);
        enterAnimation()
        return () => { }
    }, []);
    const rootRef = useRef(null);
    const lottieRef = useRef(null);
    const contentRef = useRef(null);

    const enterAnimation = () => {
        if (contentRef.current) {
            contentRef.current.style.opacity = 0;
        }

        if (rootRef.current) {
            rootRef.current.style.opacity = 0;
        }

        setTimeout(() => {

            animate({
                from: 0,
                to: 1,
                ease: linear,
                duration: 1000,
                onUpdate: (progress) => {
                    if (rootRef.current) {
                        rootRef.current.style.opacity = progress;
                    }
                },
                onComplete: () => {
                    const ghostTransX = 200
                    if (lottieRef.current) {
                        lottieRef.current.style.transform = `rotateY(180deg) translate(${ghostTransX}px,0px)`;
                    }
                    setTimeout(() => {
                        animate({
                            from: 0,
                            to: 1,
                            ease: cubicBezier(0, 0, 0.45, 0.99),
                            duration: 800,
                            onUpdate: (progress) => {

                                if (lottieRef.current) {
                                    lottieRef.current.style.transform = `rotateY(180deg) translate(${ghostTransX - ghostTransX * progress}px,0px)`;
                                }
                            },
                            onComplete: () => {

                                if (contentRef.current) {
                                    contentRef.current.style.opacity = 1;
                                }
                                setStep(0)
                            }
                        })
                    }, 300)



                }
            })
        }, 500)
    }

    const contentClick = () => {
        if (step == 1) {
            if (musicPlaying) {
                if (currentAudio) {
                    currentAudio.pause()
                }
                setMusicPlaying(false)
            } else {
                if (currentAudio) {
                    currentAudio.play()
                }
                setMusicPlaying(true)
            }
        } else if (step == 2) {
            goWishes();
        }
    }









    return (
        <div ref={rootRef} style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>

            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }} ref={containerRef}>
                <div style={{ width: '100%', position: 'absolute', top: '0px' }}>
                    {props.detail.skin >= 0 && <TombstoneComp edit={false} detail={{ ...props.detail }} />}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', position: 'absolute', top: window.innerHeight <= 700 ? `${window.innerHeight - 233}px` : `${window.innerHeight - 293}px` }}>
                    <div ref={contentRef} style={{ display: 'flex', flexDirection: 'row', width: '100%', opacity: 0 }} >

                        <img src={imgQuote} style={{ width: '24px', height: '24px', marginLeft: `${(environment !== Environment.WECHAT) ? 20 : 28}px` }} />
                        <span style={{
                            marginTop: '12px', whiteSpace: 'pre-line',
                            height: step == 0 || step == -1 ? '148px' : '97px', fontSize: step == 0 ? `${(environment !== Environment.WECHAT) ? 14 : 15}px` : '17px', lineHeight: step == 0 ? '30px' : '34px'
                        }}>
                            {showContent}
                        </span>
                        {step != 0 && step != -1 && <div onClick={contentClick} style={{ position: 'absolute', top: '15px', left: step == 1 ? '140px' : '200px' }}>
                            {step == 1 ?

                                // <>葬礼bgm：{musicData[props.detail.bgm].name}</>

                                <div onClick={() => {
                                    if (musicPlaying) {
                                        if (currentAudio) {
                                            currentAudio.pause()
                                        }
                                        setMusicPlaying(false)
                                    } else {
                                        if (currentAudio) {
                                            currentAudio.play()
                                        }
                                        setMusicPlaying(true)
                                    }
                                }} style={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '115px', height: '26px',
                                    border: '1px solid rgba(255, 255, 255, 0.3)', backgroundColor: 'rgba(255, 255, 255, 0.4)', borderRadius: '5px'
                                }}>
                                    <img src={musicPlaying ? imgMineMusic : imgMineMusicMute} style={{ width: '14px', height: '14px', margin: '0 5px 0 5px' }} />
                                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '131px' }}>
                                        <div className='marquee' style={{ whiteSpace: 'nowrap', fontSize: '11px', color: '#424242' }}>{props.detail.bgm >= 0 ? `葬礼bgm:${musicData[props.detail.bgm].name}` : ''}</div></div>
                                </div>
                                :

                                <div style={{
                                    display: 'flex', flexDirection: 'row', marginRight: '11px', alignItems: 'center', justifyContent: 'center', width: '44vw', height: '33px',
                                    border: '1px solid rgba(255, 255, 255, 0.3)', backgroundColor: 'rgba(255, 255, 255, 0.4)'
                                }} onClick={contentClick}>
                                    <img src={imgMineEdit} style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                                    <span style={{ fontSize: '13px', color: '#424242' }}>遗愿清单</span>
                                </div>



                            }
                        </div>}


                    </div>

                    <div ref={lottieRef} style={{
                        pointerEvents: 'none',
                        width: 200, height: 200, transform: 'rotateY(180deg) translate(-1000px,0px)',
                        display: 'flex', justifyContent: 'center', position: 'absolute', top: '-180px', left: '-34px',
                    }}>
                        {ghostLottie && <Lottie animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
                    </div>
                </div>

                <div style={{
                    display: 'flex', flexDirection: 'row', position: 'absolute', bottom: (window.innerHeight <= 700) ? '10px' : '57px',
                    width: '100%', justifyContent: 'center', alignItems: 'center'
                }} >
                    <SoundBtn onClick={() => { next() }} style={{ pointerEvents: btnClickable ? 'auto' : 'none' }}>
                        <div className='Btn' style={{ visibility: showBtn ? 'visible' : 'hidden', opacity: 0, width: '303px', height: '42px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} ref={btnRef}>
                            <img src={imgNext} style={{ width: '26px', height: '26px' }} />
                        </div>
                    </SoundBtn>
                </div>
            </div>
            {step == 3 ?
                <Wishespage goNext={() => { next() }} detail={props.detail} relation={props.relation} windowHeight={windowHeight} /> : <></>}



            {(inApp && step != 3) &&
                <div style={{
                    width: '100%',
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center', justifyContent: 'center',
                    alignContent: 'center',
                    position: 'absolute', zIndex: 100,
                    top: statusBarHeight > 0 ? statusBarHeight + 5 : 21
                }} >
                    <div style={{ flexGrow: 1 }} />
                    <img onClick={() => {
                        doClose()
                    }} src={imgClose} style={{ width: '24px', height: '24px', margin: '0 20px 0 0' }} />


                </div>
            }

        </div>
    );
})
interface WishespageProps {
    windowHeight: number;
    detail: any;
    relation: string;
    goNext: () => void;
}


const Wishespage = forwardRef<any, WishespageProps>((props, ref) => {

    const { statusBarHeight, inApp, ghostLottie } = React.useContext(XSDataContext);
    const rootRef = useRef(null);
    const tvRef = useRef(null);
    const [contentText, setContentText] = useState(null)
    const [showContent, setShowContent] = useState("")
    const [btnClickable, setBtnClickable] = useState(false)
    const [showBtn, setShowBtn] = useState(false)
    const btnRef = useRef(null);

    useEffect(() => {
        if (contentText !== null && contentText !== undefined && contentText !== '') {
            if (tvRef.current) {
                clearInterval(tvRef.current)
            }
            let index = 0;
            setShowContent('')
            tvRef.current = setInterval(() => {
                setShowContent((prev) => {
                    let n = contentText[index++];
                    if (n !== undefined) {
                        return prev + n
                    } else {
                        return prev
                    }
                });
                if (index >= contentText.length - 1) {
                    setShowBtn(true)
                    if (tvRef.current) {
                        clearInterval(tvRef.current)
                    }
                }
            }, 50); // 控制每个字符出现的速度
        }
        return () => {
            if (tvRef.current) {
                clearInterval(tvRef.current)
            }
        };
    }, [contentText]);

    useEffect(() => {
        if (showBtn) {
            setTimeout(() => {
                animate({
                    from: 0,
                    to: 1,
                    ease: linear,
                    duration: 300,
                    onUpdate: (progress) => {
                        if (btnRef.current) {
                            btnRef.current.style.opacity = progress
                        }
                    },
                    onComplete: () => {
                        setBtnClickable(true)
                    }
                })
            }, 200)
        } else {
            if (btnRef.current) {
                btnRef.current.style.opacity = 0
            }
            setBtnClickable(false)
        }
    }, [showBtn]);

    useEffect(() => {
        if (rootRef.current) {
            inAnimation(rootRef.current, () => {
                const text = screenLevel() == 3 ? `这是${taLabel(props.detail.sex)}的遗愿清单 点击撕下便签 帮${taLabel(props.detail.sex)}实现愿望!`
                    : `这是${taLabel(props.detail.sex)}的遗愿清单\n点击撕下便签\n帮${taLabel(props.detail.sex)}实现愿望!`;
                setContentText(text)
            })
        }
        return () => { };
    }, []);

    const screenLevel = () => {
        if (props.windowHeight >= (inApp ? 25 : 0) + 165 + 20 + 129 * 3 + 58 + 30) {
            return 1;
        } else if (props.windowHeight >= (inApp ? 25 : 0) + 165 + 129 * 3 + 58 + 10) {
            return 2;
        } else return 3;
    }

    const wishMarginTop = () => {
        let sl = screenLevel();
        if (sl == 1) {
            return 20;
        } else {
            return 0;
        }
    }

    const buttomMarginBottom = () => {
        let sl = screenLevel();
        if (sl == 1) {
            return 30;
        } else {
            return 10;
        }
    }

    const [lastwishes, setLastwishes] = useState(props.detail.lastwish)
    return (
        <div ref={rootRef} style={{ opacity: 0, display: 'flex', flexDirection: 'column', height: '100%', position: 'absolute', width: '100%' }} onClick={(e) => { e.preventDefault() }}>
            {screenLevel() == 3 ?
                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', left: '15px', marginTop: `${statusBarHeight + 6}px` }} >

                    <img src={imgQuote} style={{ width: '24px', height: '24px' }} />
                    <p style={{ marginTop: '12px', fontSize: '13px', lineHeight: '13px', height: '25px', marginBottom: '11px', whiteSpace: 'pre-line' }}>
                        {showContent}
                    </p>
                </div>
                : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '165px', position: 'relative', marginTop: `${inApp ? 25 : 0}px` }}>

                    <div style={{
                        pointerEvents: 'none', transform: 'rotateY(180deg)',
                        width: 200, height: 200, overflow: 'hidden',
                        display: 'flex', justifyContent: 'center', position: 'absolute', bottom: '-35px', left: '-12px',
                    }}>
                        {ghostLottie && <Lottie animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', left: '149px', bottom: '0px' }} >

                        <img src={imgQuote} style={{ width: '24px', height: '24px' }} />
                        <p style={{ marginTop: '12px', fontSize: '17px', lineHeight: '34px', height: '85px', whiteSpace: 'pre-line' }}>
                            {showContent}
                        </p>
                    </div>
                </div>}
            <div style={{ width: '100%', position: 'relative', top: `${wishMarginTop()}px` }}>
                <WishesComp visitor={true} sex={props.detail.sex} wishes={lastwishes} setWishes={(data) => { setLastwishes(data) }} relation={props.relation} gap={0} />

            </div>

            <div style={{
                display: 'flex', flexDirection: 'row', position: 'absolute', bottom: `${buttomMarginBottom()}px`,
                width: '100%', justifyContent: 'center', alignItems: 'center'
            }} >
                <SoundBtn onClick={(e) => {
                    props.goNext();
                    e.preventDefault()
                }} style={{ pointerEvents: btnClickable ? 'auto' : 'none' }}>
                    <div className='Btn' style={{ opacity: 0, width: '303px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} ref={btnRef}>
                        <img src={imgNext} style={{ width: '26px', height: '26px' }} />
                    </div>
                </SoundBtn>
            </div>

        </div>)
});

export default Participate;


