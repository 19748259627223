import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './Music.css';

import XSDataContext from './context/XSDataContext';
import imgSelBg from './images/icon_sel_bg.png';
import imgTitle from './images/img_title_music.png';

import imgPause from './images/icon_music_pause.png';
import imgPlay from './images/icon_music_play.png';
import { xslog } from './util/XSLog';

import { musicData } from './beans/Constrants';
import SoundBtn from './components/SoundBtn';
import XSCreateContext from './context/XSCreateContext';
import XSMusicContext from './context/XSMusicContext';
import { inAnimation, outAnimation } from './util/CommonUtil';

interface MusicProps {
  goNext: () => void;
}
const Music = forwardRef<any, MusicProps>((props, ref) => {

  const { statusBarHeight } = React.useContext(XSDataContext);
  const { createInfo, setCreateInfo } = React.useContext(XSCreateContext);
  const { bgPlayerRef ,musicPlayerRef,playClickMusicSound} = React.useContext(XSMusicContext);
  const [index, setIndex] = useState(-1);
  const [currentAudio, setCurrentAudio] = useState(null);

  const [currentAudioIndex, setCurrentAudioIndex] = useState(-1);
  const rootRef = useRef(null);
  const gridContainerRef = useRef(null);


  const imgStyle = {
    width: '100px',
    height: '100px'
  }

  const selectMusic = (_index) => {
    xslog("selectMusic", _index, currentAudioIndex, currentAudio)
    setIndex(_index)
    if (currentAudioIndex == _index) {
      setCurrentAudioIndex(-1)
      if (currentAudio) {
        currentAudio.pause();
        // currentAudio.currentTime = 0; // Reset the audio to the start
      }
    } else {
      if (currentAudio) {
        currentAudio.pause();
        // currentAudio.currentTime = 0; // Reset the audio to the start
      }
      setCurrentAudioIndex(_index)
      const newAudio = new Audio(musicData[_index].prevurl);
      newAudio.loop = true;
      musicPlayerRef.current = newAudio
      setCurrentAudio(newAudio);
      // newAudio.play();
    }
  }

  const Music = (item) => {
    return (<div onClick={() => { 
      playClickMusicSound()
      selectMusic(item.key) }} key={item.key} style={{ display: 'flex', position: 'relative', flexDirection: 'column', alignItems: 'center', width: '150px', height: '123px', marginBottom: `${((window.innerHeight > 700) ? 20 : 12)}px` }}>
      <img src={item.img} style={imgStyle} className={`Music ${(currentAudioIndex === item.key) ? 'MusicRotating' : ''}`} />
      <img src={(currentAudioIndex === item.key) ? imgPause : imgPlay} style={{ position: 'absolute', top: '23px', width: '54px', height: '54px' }} />
      <div style={{ color: '#424242', fontSize: '15px', marginTop: '7px' }}>   {item.sname}</div>
    </div>)
  }
  const SelHeight = 206;
  const SelWidth = 210;
  const SelTop = () => {
    if (gridContainerRef.current == undefined) {
      return 0;
    }
    const rect = gridContainerRef.current.getBoundingClientRect();
    const gridTop = rect.top;
    return gridTop -48 + (Math.floor(index / 2)) * (123 + ((window.innerHeight > 700) ? 20 : 12)) 
  }

  const SelLeft = () => {
    return (window.innerWidth - SelWidth * 2) / 3 + (window.innerWidth / 2) * (index % 2)
  }


  useEffect(() => {

    setIndex(createInfo.musicIndex)
    inAnimation(rootRef.current,()=>{})
    return (() => {
      if (bgPlayerRef.current && bgPlayerRef.current.paused) {
        bgPlayerRef.current.currentTime = 0;
        bgPlayerRef.current.play().catch(error => {
          xslog("bgPlayer error",error);
        });;
      }
    })
  }, []);

  useEffect(() => {

    if (currentAudio) {
      if(bgPlayerRef.current){
        bgPlayerRef.current.pause()
      }
      xslog("useEffect currentAudio", currentAudio)
      currentAudio.play().catch(error => {
        xslog("currentAudio error",error);
      });;;
    }
    return () => {
      if (currentAudio) {
        currentAudio.pause();
      }
    };
  }, [currentAudio]);








  return (
    <div ref={rootRef} style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100vw', alignItems: 'center' }}>
      <img src={imgTitle} style={{ width: `${35500/393}%`, marginTop: `${statusBarHeight + ((window.innerHeight > 700) ? 56 : 28)}px` }} />
      <img src={imgSelBg} style={{ width: `${SelWidth}px`, height: `${SelHeight}px`, position: 'absolute', top: `${SelTop()}px`, left: `${SelLeft()}px` }} />
      
      <div ref={gridContainerRef} className='MusicContainer' style={{ columnGap: `${(window.innerWidth - 270) / 3}px`, marginTop: `${((window.innerHeight > 700) ? 25 : 0)}px` }}>
        {musicData.map(item => (
          Music(item)
        ))}
      </div>
      {index >= 0 ? <SoundBtn className='Btn' style={{ height: `48px`, width: `313px`, position: 'absolute', bottom: `${((window.innerHeight > 700) ? 75 : 10)}px` }} onClick={() => {
        let _createInfo = createInfo
        _createInfo.musicIndex = index
        setCreateInfo(_createInfo);
       
      outAnimation( rootRef.current,()=>{   props.goNext()})  
      }}>就它了</SoundBtn> : <></>}
    </div>
  );
})

export default Music;




