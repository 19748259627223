import React from 'react';
import { CreateInfo } from '../beans/DataTypes';


interface XSCreateContextType {
   createInfo: CreateInfo;
    setCreateInfo:(info:CreateInfo)=>void;

}


const XSCreateContext = React.createContext<XSCreateContextType>({
    createInfo: null,
    setCreateInfo:(info:CreateInfo)=>{}
});

export default XSCreateContext;