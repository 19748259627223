import React, { forwardRef, useEffect } from 'react';
import './Wishes.css';

import XSDataContext from './context/XSDataContext';
import imgShareClose from './images/icon_share_close.png';
import imgShareLink from './images/icon_share_link.png';
import imgSharePyq from './images/icon_share_pyq.png';
import imgShareQQ from './images/icon_share_qq.png';
import imgShareQzone from './images/icon_share_qzone.png';
import imgShareSave from './images/icon_share_save.png';
import imgShareWechat from './images/icon_share_wechat.png';
import imgShareXs from './images/icon_share_xs.png';

import { ShareData } from './beans/DataTypes';
import { SHARE_URL } from './beans/Environment';
import { shareNative } from './util/NativeBridge';
import { notify } from './util/NotifyUtil';
import { xslog } from './util/XSLog';
import { Environment } from './util/environment';


interface ShareProps {
  detail: any;
  shareImageUrl: any;
  onClose: () => void;
}
const Share = forwardRef<any, ShareProps>((props, ref) => {


  const {  environment } = React.useContext(XSDataContext);
  const { user } = React.useContext(XSDataContext);








  useEffect(() => {


    return () => {

    };
  }, []);


  const share = (index) => {
    shareNative(new ShareData(index, user.uid, index === 3 ? props.shareImageUrl.largeUrl : props.shareImageUrl.normalUrl,
      props.detail.birthday, props.detail.dieday, ""
    ), () => {
      // if(environment == Environment.IOS_APP && index ==5){
      //   notify('保存成功')
      // }
     })
  }

  const shareStyle = {
    width: '50px', height: '71px'
  }


  const copyToClipboard = async () => {
    const text = `${SHARE_URL + user.uid}`;
    // try {
    //   await navigator.clipboard.writeText(text);
    //  notify('复制成功');
    // } catch (err) {
    //   xslog(err);
    //   notify('复制失败');
    // }

    const oldway = () => {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        notify('复制成功');
      } catch (err) {
        notify('复制失败');
        xslog('Fallback copy failed: ', err);
      } finally {
        document.body.removeChild(textArea);
      }
    }
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);
        notify('复制成功');
      } catch (err) {
        // notify('复制失败');
        xslog('Failed to copy: ', err);
        oldway()
      }
    } else {
      // Fallback for older browsers
      oldway()
    }
  };


  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => { props.onClose() }} >

      <div style={{ flexGrow: 1 }}> </div>
      <div style={{ textAlign: 'center', width: '100%', height: window.innerHeight - 296 - 60, marginBottom: 0 }}>
        <img onClick={(e) => { e.stopPropagation() }} src={props.shareImageUrl.normalUrl} style={{ maxWidth: '100%', maxHeight: '100%' }}></img>

      </div>

      <div onClick={(e) => { e.stopPropagation() }} style={{ width: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column', padding: '20px 20px 40px 20px', position: 'relative' }}>


        <div style={{ display: 'flex', width: 'calc(100% - 40px)', flexDirection: 'column', backgroundColor: 'rgba(34, 34, 34, 0.45)', borderRadius: '20px', padding: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ fontFamily: '', fontSize: '15px', color: '#F9F8F8' }}>分享至</span>
            <div style={{ flexGrow: 1 }} />
            <img src={imgShareClose} onClick={() => { props.onClose() }} style={{ width: '20px', height: '20px' }} />
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4,50px)', rowGap: '18px', justifyContent: 'space-between', marginTop: '15px', width: 'calc(100%)' }}>
            <img src={imgSharePyq} style={shareStyle} onClick={() => { share(0) }} />
            <img src={imgShareWechat} style={shareStyle} onClick={() => { share(1) }} />
            <img src={imgShareQzone} style={shareStyle} onClick={() => { share(2) }} />
            <img src={imgShareQQ} style={shareStyle} onClick={() => { share(3) }} />
            <img src={imgShareXs} style={shareStyle} onClick={() => { share(4) }} />
            <img src={imgShareSave} style={shareStyle} onClick={() => { share(5) }} />

            <img src={imgShareLink} style={shareStyle} onClick={() => {
              copyToClipboard()
            }} />
          </div>
        </div>


      </div>


    </div>
  );
})

export default Share;




