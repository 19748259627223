import React, { useEffect, useRef, useState } from 'react';
import { Notify } from 'react-vant';
import CheckIn from './CheckIn';
import Confirm from './Confirm';
import XSDataContext from './context/XSDataContext';
import './Create.css';
import Epitaph from './Epitaph';
import imgBack from './images/icon_back.png';
import imgClose from './images/icon_close.png';
import imgGhost from './images/img_ghost.png';
import Music from './Music';
import Tombstone from './Tombstone';
import { xslog } from './util/XSLog';
import Welcome from './Welcome';
import Wishes from './Wishes';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Relation from './Relation';
import Participate from './Participate';
import { ERROR_CODE, XsHttpCient } from './util/HttpUtil';
import DetailView from './DetailView';
import { notify } from './util/NotifyUtil';
import { Environment } from './util/environment';
import { v4 as uuidv4 } from 'uuid';
import { WX_APPID, WX_REDIRECT } from './beans/Environment';
import { LOCALSTORAGE_KEY } from './beans/Constrants';
import { WebUserInfo } from './beans/DataTypes';
import XSMusicContext from './context/XSMusicContext';
import { goDownload } from './util/CommonUtil';

export enum Page {
  relation, participate, detailview
}

Notify.setDefaultOptions({ color: '#FF0000', background: '#000000' });

function FuneralDetail() {
  const { statusBarHeight, environment,setWebUser, inApp, user } = React.useContext(XSDataContext);

  const { bgPlayerRef, startPlayBgMusic } = React.useContext(XSMusicContext);
  const [relation, setRelation] = useState('')
  const [detail, setDetail] = useState({})
  const titleBarHeight = statusBarHeight + 31

  const navigate = useNavigate();

  const params = useParams();



  window.onNativeBack = () => {

    return false;
  }

  function goto(_page: Page) {
    // if (page == Page.welcome && _page == Page.checkin) {
    //   ghostRef.current.style.width = '100px';
    //   ghostRef.current.style.height = '100px';
    //   ghostRef.current.style.margin = '58px 0 0 0';
    // }else if(page == Page.checkin && _page ==Page.tombstone){
    //   ghostRef.current.style.visibility = 'hidden';
    // }
    setPage(_page)

  }



  const [page, setPage] = useState<Page>(null)
  const [fromParticipate, setfromParticipate] = useState(false)

  const loadData = () => {

    const doload = () => {
      if (user != null && user.uid != null && user.uid != '' && user.uid === params.id) {

        startPlayBgMusic();
        navigate('/home')
        return
      }

      XsHttpCient.get(`funeral/detail?uidStr=${params.id}`).then((res) => {
        xslog(res);
        let detail = res.data.data;
        setDetail(detail)
        document.title = `欢迎来到${detail.name}的赛博葬礼`
        if (!detail.joined) {

          startPlayBgMusic();
          setPage(Page.relation)
        } else {
          setPage(Page.detailview)
        }

      }).catch((error) => {
        xslog(error)
        if (error.code === ERROR_CODE.funeral_not_exist) {
          notify("葬礼不存在")
          if (inApp) {
            navigate('/')
          } else {
            goDownload(environment,navigate);
          }
        } else { notify(error.msg) }


      });
    }

    doload();

    // if (inApp) {
    //   doload()
    // } else {
    //   let uuid = localStorage.getItem('xsuuid')
    //   if (uuid === null || uuid === undefined || uuid === '') {
    //     uuid = uuidv4();
    //     localStorage.setItem('xsuuid', uuid)
    //   }
    //   window.xsuuid = uuid;
    //   doload()
    // }

  }

  const location = useLocation();

  const doParticipate = () => {


    XsHttpCient.post(`funeral/join`, { uidStr: params.id, relation: relation }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      xslog(res);
      setfromParticipate(true);
      setPage(Page.detailview)

    }).catch((error) => {
      notify(error.msg)
      if(error && error.code && error.code ===ERROR_CODE.funeral_not_exist){
        if (inApp) {
          navigate('/')
        } else {
          goDownload(environment,navigate);
        }
      }

    });



  }

  const [callbackData, setCallbackData] = useState(null)
  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);
    const isCallback = queryParams.get('isCallback');
    const name = queryParams.get('name');
    const avatar = queryParams.get('avatar');



    if (!inApp) {
      let uuid = localStorage.getItem('xsuuid')
      if (uuid === null || uuid === undefined || uuid === '') {
        uuid = uuidv4();
        localStorage.setItem('xsuuid', uuid)
      }
      window.xsuuid = uuid;
    }

    if (isCallback === 'step2') {
      // // ,JSON.stringify({
      // //   detail:props.detail,
      // //   relation:item
      // // })
      // notify(`name ${name} avatar ${avatar}`)

      let tempdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY.tempauthdata))
      setDetail(tempdata.detail)
      document.title = `欢迎来到${tempdata.detail.name}的赛博葬礼`
      if (name != null && name != '') {
        let wu = new WebUserInfo(0, name, avatar);
        let str = JSON.stringify(wu)
        xslog('WebUserInfo is ' + str);
        localStorage.setItem(LOCALSTORAGE_KEY.webuser, str)
        setWebUser(wu)
      }
      setCallbackData({
        relation: tempdata.relation,
      })
      setPage(Page.relation)

      startPlayBgMusic();
    } else {
      loadData()
    }



    return (() => {
      if (bgPlayerRef.current) {
        bgPlayerRef.current.pause();
      }
    })
  }, [])

  function pageContent() {
    if (page === Page.relation)
      return (<Relation uid={params.id} callbackdata={callbackData} detail={detail} goNext={(_relation) => {

        xslog("relation done " + _relation);
        setRelation(_relation)

if(bgPlayerRef.current && bgPlayerRef.current.paused){
      startPlayBgMusic();}
        goto(Page.participate)
      }} />)
    else if (page === Page.participate)
      return (<Participate relation={relation} detail={detail} goNext={() => {
        doParticipate()
      }} />)
    else if (page === Page.detailview) {
      return (<DetailView uid={params.id} fromParticipate={fromParticipate} />)
    }
    else return <></>
  }

  return (
    <div className='Main' style={{ display: 'block' }}>
      {/* <div style={{ height: '50px' }}></div>
      {environment}<br />
      {navigator.userAgent || navigator.vendor || window.opera}
      <br /> */}
      {pageContent()}

    </div>
  );
}

export default FuneralDetail;

