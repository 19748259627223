export class CheckinInfo {
    name: string;
    birthday: string;
    reason: string;
    newAdd: Boolean = false;
    constructor(name: string, birthday: string, reason: string) {
        this.name = name;
        this.birthday = birthday;
        this.reason = reason;
    }
}

export class ShareData {
    shareWay: number;
    uid: String;
    url: String;
    startDate: number;
    endDate: number;
    toUid:String;

    constructor( shareWay: number,uid: String,url: String, startDate: number,endDate: number,toUid:String){
        this.shareWay = shareWay;
        this.uid = uid;
        this.url = url;
        this.startDate = startDate;
        this.endDate = endDate;
        this.toUid = toUid
    }
}

export class UserInfo{
    uid: string;
    token: string;
    name: string;
    avatar: string;
    birthData:number;
}

export class WebUserInfo{
    //0 wechat;1 qq;2 web custom
    type:number;
    name: string;
    avatar: string;
    constructor( type:number,name: string,avatar: string){
        this.type = type;
        this.name = name;
        this.avatar = avatar;
    }
}

export class CreateInfo{
    name:string;
    birthday:number;
    reason:string;
    stoneIndex = -1;
    musicIndex = -1;
    avatar:string;
    words:string;
    wishes = [{ key: 0, content: '', isAdd: false }, { key: 1, content: '', isAdd: true }];
}