
import stoneNormal from '../images/stone_normal.png';
import stoneGold from '../images/stone_gold.png';
import stonePink from '../images/stone_pink.png';
import stoneKfc from '../images/stone_kfc.png';
import stoneApple from '../images/stone_apple.png';
import stoneBrand from '../images/stone_brand.png';

import stoneBtnNormal from '../images/stone_btn_normal.png';
import stoneBtnGold from '../images/stone_btn_gold.png';
import stoneBtnPink from '../images/stone_btn_pink.png';
import stoneBtnKfc from '../images/stone_btn_kfc.png';
import stoneBtnApple from '../images/stone_btn_apple.png';
import stoneBtnBrand from '../images/stone_btn_brand.png';

import stoneBgNormal from '../images/stonebg_normal.png';
import stoneBgGold from '../images/stonebg_gold.png';
import stoneBgPink from '../images/stonebg_pink.png';
import stoneBgKfc from '../images/stonebg_kfc.png';
import stoneBgApple from '../images/stonebg_apple.png';
import stoneBgBrand from '../images/stonebg_brand.png';

import stoneBgViewNormal from '../images/stonebg_view_normal.png';
import stoneBgViewGold from '../images/stonebg_view_gold.png';
import stoneBgViewPink from '../images/stonebg_view_pink.png';
import stoneBgViewKfc from '../images/stonebg_view_kfc.png';
import stoneBgViewApple from '../images/stonebg_view_apple.png';
import stoneBgViewBrand from '../images/stonebg_view_brand.png';

import muimg1 from '../images/img_music1.png';
import muimg2 from '../images/img_music2.png';
import muimg3 from '../images/img_music3.png';
import muimg4 from '../images/img_music4.png';
import muimg5 from '../images/img_music5.png';
import muimg6 from '../images/img_music6.png';

export const StoneDataKey = {
    normal: 0,
    apple: 1,
    gold: 2,
    kfc: 3,
    pink: 4,
    brand: 5
}

export const stoneData = [{ key: StoneDataKey.normal, btn: stoneBtnNormal, img: stoneNormal, eidtBg: stoneBgNormal, viewBg: stoneBgViewNormal, name: '原汁原味传统墓碑' },
{ key: StoneDataKey.apple, btn: stoneBtnApple, img: stoneApple, eidtBg: stoneBgApple, viewBg: stoneBgViewApple, name: 'iTomb苹果墓碑' },
{ key: StoneDataKey.gold, btn: stoneBtnGold, img: stoneGold, eidtBg: stoneBgGold, viewBg: stoneBgViewGold, name: '奢华土豪金墓碑' },
{ key: StoneDataKey.kfc, btn: stoneBtnKfc, img: stoneKfc, eidtBg: stoneBgKfc, viewBg: stoneBgViewKfc, name: '疯狂星期四联名墓碑' },
{ key: StoneDataKey.pink, btn: stoneBtnPink, img: stonePink, eidtBg: stoneBgPink, viewBg: stoneBgViewPink, name: '粉色少女心墓碑' },
{ key: StoneDataKey.brand, btn: stoneBtnBrand, img: stoneBrand, eidtBg: stoneBgBrand, viewBg: stoneBgViewBrand, name: '很想你墓碑' }]

export const bgmusic = "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_bg.mp3";

export const musicData = [{ key: 0, img: muimg1, sname: '原汁原味白事乐队', name: '原汁原味白事乐队-二泉映月', prevurl: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_two_prev.mp3" , url: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_two.mp3" },
{ key: 1, img: muimg2, sname: '死亡摇滚乐队', name: '死亡摇滚乐队-Zombie', prevurl: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_rock_prev.mp3" , url: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_rock.mp3" },
{ key: 2, img: muimg3, sname: '上流交响唱诗班', name: '上流交响唱诗班-安魂曲', prevurl: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_soul.mp3" , url: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_soul.mp3" },
{ key: 3, img: muimg4, sname: '偶像女团', name: '偶像女团-끝엔 악마 같은 bye', prevurl: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_girl.mp3", url: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_girl.mp3" },
{ key: 4, img: muimg5, sname: '中专Rapper', name: '中专Rapper-Damn（美式男孩M3）', prevurl: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_rapper.mp3", url: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_rapper.mp3" },
{ key: 5, img: muimg6, sname: '非著名相声演员', name: '非著名相声演员-郭德纲相声精选', prevurl: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_gdg.mp3", url: "https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/music/funeral_music_gdg.mp3" }]



export const LOCALSTORAGE_KEY = {
    webuser: 'webuser',
    tempauthdata: 'tempauthdata',
    downloadGuide: 'downloadGuide'
}

export const taLabel = (sex: number) => {
    // sex 0-男；1-女
    if (sex == 0) {
        return "他"
    } else if (sex == 1) {
        return "她"
    } else {
        return 'ta'
    }
}

export const DefaultAvatars = ['https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/avatar/img_avatar_1.png', 'https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/avatar/img_avatar_2.png', 'https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/avatar/img_avatar_3.png', 'https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/avatar/img_avatar_4.png', 'https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/avatar/img_avatar_5.png', 'https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/avatar/img_avatar_6.png']