import React, { useEffect, useRef, useState } from 'react';
import { Notify, Popup } from 'react-vant';
import CheckIn from './CheckIn';
import Confirm from './Confirm';
import XSDataContext from './context/XSDataContext';
import './Create.css';
import Epitaph from './Epitaph';
import imgBack from './images/icon_back.png';
import imgClose from './images/icon_close.png';
import imgGhost from './images/img_ghost.png';
import Music from './Music';
import Tombstone from './Tombstone';
import { xslog } from './util/XSLog';
import Welcome from './Welcome';
import Wishes from './Wishes';
import XSCreateContext from './context/XSCreateContext';
import { XSCreateProvider } from './context/XSCreateProvier';
import { doClose } from './util/NativeBridge';
import XSMusicContext from './context/XSMusicContext';

export enum Page {
  welcome, checkin, tombstone, epitaph, music, wishes, confirm
}

Notify.setDefaultOptions({ color: '#FF0000', background: '#000000' });

function Create() {
  const welcomeRef = useRef(null);
  const checkinRef = useRef(null);
  const tombstoneRef = useRef(null);
  const epitaphRef = useRef(null);
  const musicRef = useRef(null);
  const wishesRef = useRef(null);
  const confirmRef = useRef(null);
  const ghostRef = useRef(null);
  const { statusBarHeight } = React.useContext(XSDataContext);

  const { bgPlayerRef, startPlayBgMusic } = React.useContext(XSMusicContext);
  const titleBarHeight = statusBarHeight + 31




  window.onNativeBack = () => {

    if (page == Page.welcome) {
      return false;
    } else {
      goBack();
      return true;
    }
  }

  function goto(_page: Page) {
    // if (page == Page.welcome && _page == Page.checkin) {
    //   ghostRef.current.style.width = '100px';
    //   ghostRef.current.style.height = '100px';
    //   ghostRef.current.style.margin = '58px 0 0 0';
    // }else if(page == Page.checkin && _page ==Page.tombstone){
    //   ghostRef.current.style.visibility = 'hidden';
    // }
    setPage(_page)

  }

  function goBack() {
    if (page == Page.checkin) {
      setPage(Page.welcome)
    } else if (page == Page.tombstone) {
      setPage(Page.checkin)
    } else if (page == Page.epitaph) {
      setPage(Page.tombstone)
    } else if (page == Page.music) {
      setPage(Page.epitaph)
    } else if (page == Page.wishes) {
      setPage(Page.music)
    } else if (page == Page.confirm) {
      setPage(Page.wishes)
    }

  }

  const [page, setPage] = useState(Page.welcome)

  const pageInfo = () => {
    if (page === Page.welcome) {
      return ""
    } else if (page === Page.checkin) {
      return "1/5"
    } else if (page === Page.tombstone) {
      return "2/5"
    } else if (page === Page.epitaph) {
      return "3/5"
    }
    else if (page === Page.music) {
      return "4/5"
    }
    else if (page === Page.wishes) {
      return "5/5"
    }
    return ""
  }

  const ghostClassName = () => {
    if (page === Page.welcome) {
      return "Ghost"
    } else if (page == Page.checkin) {
      return "GhostCheckIn"
    } else if (page == Page.confirm) {
      return "GhostConfirm"
    } else {
      return "GhostOther"
    }
  }

  useEffect(() => {
    // if(window.cjlk!=undefined){
    // initStatusBarHeight(window.cjlk.getStatusBarheight())
    // }

    startPlayBgMusic();
    return (() => {
      if (bgPlayerRef.current) {
        bgPlayerRef.current.pause();
      }
    })
  }, [])

  function pageContent() {
    if (page == Page.welcome)
      return (<Welcome ref={welcomeRef} goNext={() => {
        goto(Page.checkin)
      }} />)
    else if (page == Page.checkin)
      return (<CheckIn ref={checkinRef} goNext={() => {
        goto(Page.tombstone)
      }} />)
    else if (page == Page.tombstone)
      return (<Tombstone ref={tombstoneRef} goNext={() => { goto(Page.epitaph) }} />)
    else if (page == Page.epitaph)
      return (<Epitaph ref={epitaphRef} goNext={() => { goto(Page.music) }} />)
    else if (page == Page.music)
      return (<Music ref={musicRef} goNext={() => { goto(Page.wishes) }} />)
    else if (page == Page.wishes)
      return (<Wishes ref={wishesRef} goNext={() => { goto(Page.confirm) }} />)
    else if (page == Page.confirm)
      return (<Confirm ref={confirmRef} goNext={() => { goto(Page.tombstone) }} />)
  }

  function CloseBtn() {

    const { createInfo } = React.useContext(XSCreateContext);
    const [confirm ,setConfirm] = useState(false)

    const clickClose = () => {
      if (createInfo == null) {
        doClose()
      } else {
        setConfirm(true)
      }
    }

    return (<><img onClick={() => {
      clickClose()
    }} src={imgClose} style={{ width: '24px', height: '24px', margin: '0 20px 0 0' }} />
    
    <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} style={{
        backgroundColor: 'transparent',
      }} visible={confirm} onClose={() => setConfirm(false)}>
        <div className='CommonDialog' style={{ width: '315px',  flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ width: '280px', margin: '20px 0 0 0 ',lineHeight:'30px', textAlign: 'center', fontFamily: 'ipix' }}>退出后之前编辑的内容将清空</span>

          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '22px 0 20px 0', alignItems: 'center', justifyContent: 'center' }}>
            <div onClick={() => { setConfirm(false) }} className='Btn' style={{ width: '112px', height: '42px', fontSize: '17px', fontFamily: 'ipix' }}>取消</div>
            <div onClick={() => { doClose(); }} className='Btn' style={{ width: '112px', height: '42px', marginLeft: '23px', fontSize: '17px', fontFamily: 'ipix' }}>确定</div>
          </div>
        </div>

      </Popup>
    </>)
  }

  return (
    <XSCreateProvider>
      <div className='Main' style={{ display: 'block'}}>

        <div style={{
          height:'100%',
          display: 'flex' 
        }}>
          {pageContent()}
        </div>
        <div style={{
          width: '100vw',
          display: 'flex', height: '30px',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          position: 'absolute',
          top: statusBarHeight

        }} >
          <img src={imgBack} style={{ width: '24px', height: '24px', margin: '0 0 0 20px', visibility: page == Page.welcome ? 'hidden' : 'visible' }} onClick={goBack} />

          <div style={{ textAlign: 'center', fontSize: '17px', fontFamily: 'ipix', color: '#000000', opacity: 0.5 }}>{pageInfo()}</div>

          <CloseBtn />
        </div>

        {/* <img ref={ghostRef} src={imgGhost} className={ghostClassName()} /> */}

      </div></XSCreateProvider>
  );
}

export default Create;











