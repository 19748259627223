import { List } from 'antd';
import React, { CSSProperties, forwardRef, useEffect, useRef, useState } from 'react';
import './CheckIn.css';
import XSDataContext from './context/XSDataContext';
import imgBtn from './images/btn_checkin2.png';
import imgEdit from './images/btn_checin_edit.png';

import imgDialogClose from './images/icon_dialog_close.png';
import { xslog } from './util/XSLog';

import { DatetimePicker, Popup } from 'react-vant';
import { CheckinInfo, CreateInfo } from './beans/DataTypes';
import { notify } from './util/NotifyUtil';
import useKeyboardStatus from './util/useKeyboardStatus';
import useKeyboardHeight from './util/useKeyboardHeight';
import { ContentAudit, ContentAuditScene, XsHttpCient } from './util/HttpUtil';
import XSCreateContext from './context/XSCreateContext';
import { formatDate, isEmpty, outAnimation, replaceN, shortShowLabel } from './util/CommonUtil';
import SoundBtn from './components/SoundBtn';
import { animate, cubicBezier, linear } from 'popmotion';


import Lottie from 'lottie-react';
import { stoneData } from './beans/Constrants';
import { Environment } from './util/environment';

interface CheckInProps {
  goNext: () => void;
}
const CheckIn = forwardRef<any, CheckInProps>((props, ref) => {
  const { keyboardEvent, environment,onKeyBoardShow, user, ghostLottie } = React.useContext(XSDataContext);
  const { createInfo, setCreateInfo } = React.useContext(XSCreateContext);
  const [checkinFinish, setCheckinFinish] = useState(false)


  const [infoData, setInfoData] = useState([])
  const [checkinShow, setCheckinShow] = useState(false)
  const [birthdayShow, setBirthdayShow] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectBirthdayValue, setSelectBirthdayValue] = useState(0);

  const scrollContainerRef = useRef(null);
  const datePickerRef = useRef(null);
  const inputNameRef = useRef(null);
  const inputReasonRef = useRef(null);
  const inputDialogRef = useRef(null);

  const rootRef = useRef(null);
  const listRef = useRef(null);
  const lottieRef = useRef(null);
  const lottieInterRef = useRef(null);

  const [wh,setWh] = useState(0)







  const handleScroll = () => {
    if(scrollContainerRef.current){
      const scrollTop = scrollContainerRef.current.scrollTop;
      const scrollHeight = scrollContainerRef.current.scrollHeight;
      const clientHeight = scrollContainerRef.current.clientHeight;

      xslog(`scrollTop ${scrollTop}   scrollHeight ${scrollHeight - clientHeight}`);

      let totalHeight = checkinFinish ? 349 : 289
      let blockHeight = 25
      let range = totalHeight - blockHeight
      let p = range * scrollTop / (scrollHeight - clientHeight)

      xslog(`handleScroll ${p}  `);
    setScrollPosition(p)}

  };

  const [scrollToBottom, setscrollToBottom] = useState(0)

  useEffect(() => {
    XsHttpCient.get('funeral/list').then((res) => {
      xslog("funeral/list result ", res);
      setInfoData(res.data.data)
      setWh(window.innerHeight)

      if (createInfo) {
        let info = new CheckinInfo(createInfo.name, formatDate(new Date(createInfo.birthday)), createInfo.reason)
        info.newAdd = true
        setInfoData((prevItems) => [...prevItems, info]);
        setCheckinFinish(true);
        handleScroll()
      }
      setscrollToBottom((prev) => prev + 1);
    }).catch((error) => {
      xslog(error)
      notify(error.msg);
    });




    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener('scroll', handleScroll);

    // Initial scroll values
    handleScroll();
    enterAnimation()


    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);






  const enterAnimation = () => {
    listRef.current.style.opacity = 0;
    const ghostTransY = 145
    if (lottieRef.current) {
      lottieRef.current.style.transform = `translate(${ghostTransY}px,0px)`;
    }

    setTimeout(() => {
      animate({
        from: 0,
        to: 1,
        ease: cubicBezier(0, 0, 0.45, 0.99),
        duration: 800,
        onUpdate: (progress) => {
          if (lottieRef.current) {
            lottieRef.current.style.transform = `translate(${ghostTransY - ghostTransY * progress}px,0px)`;
          }
        },
        onComplete: () => {

        }
      })
      animate({
        from: 0,
        to: 1,
        ease: linear,
        duration: 500,
        onUpdate: (progress) => {

          if (listRef.current) {
            listRef.current.style.opacity = progress;
          }
        },
        onComplete: () => { }
      })
    }, 300)



  }

  useEffect(() => {

    if (checkinFinish || scrollToBottom > 0) {
      const scrollContainer = scrollContainerRef.current;

      scrollContainer.removeEventListener('scroll', handleScroll);
      scrollContainer.addEventListener('scroll', handleScroll);

      // Initial scroll values
      handleScroll();

      scrollContainer.scrollTop = scrollContainer.scrollHeight
    }

  }, [checkinFinish, scrollToBottom]);



  useEffect(() => {
    if (inputDialogRef.current && environment ===Environment.ANDROID_APP) {
      if (keyboardEvent.show) {
        const rect = inputDialogRef.current.getBoundingClientRect();
        let vH = (window.innerHeight - keyboardEvent.height);
        let transY = 0;
        if (vH > rect.height) {
          transY = (vH - rect.height) / 2 - rect.top;
        } else {
          transY = vH - rect.bottom;
        }
        inputDialogRef.current.style.transform = `translateY(${transY}px)`
      } else {
        inputDialogRef.current.style.transform = ``
      }
    }
  }, [keyboardEvent]);

  const nameValue = () => {
    let name = "";
    if (createInfo && createInfo.name !== '') {
      name = createInfo.name
    } else {
      name = user.name
    }
    if (name.length > 4) {
      name = name.substring(0, 4)
    }
    return name
  }

  const [nameInput, setNameInput] = useState(nameValue())

  const reasonValue = () => {
    if (createInfo && createInfo.reason !== '') {
      return createInfo.reason
    }
    return ""
  }

  const [reasonInput, setReasonInput] = useState(reasonValue())

  const curBirthdayDate = () => {
    let birth: number = selectBirthdayValue
    xslog(`curBirthdayDate 1 ${birth}`)
    if (birth == 0) {
      if (createInfo) {
        birth = createInfo.birthday;
      }
    }
    if (birth == 0) {
      birth = user.birthData;
    }
    return new Date(Number(birth));
  }

  const birthValue = () => {
    let birth = curBirthdayDate()

    xslog("birth " + birth);
    if (birth == null) {
      return ""
    } else {
      return formatDate(birth)
    }
  }




  let selBirthday = undefined;
  let data = []
  return (
    <div ref={rootRef} style={{ display: 'flex', position: 'relative', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>

      <div ref={listRef} className='ListBg List' style={{
        margin: '',

        position: 'absolute',
        padding: '0 19px 53px 19px',
        width: `308px`, top: `${((wh<window.innerHeight?window.innerHeight:wh) - 506) * 191 / 342}px`,
        height: `453px`, opacity: 0,
      }}>
        <div style={{ fontSize: '30px', color: '#424242', marginTop: '20px' }}> 赛博墓园入园登记表</div>
        <div style={{ display: 'flex', width: '100%', height: '100%', marginTop: '20px' }}>
          <div style={{
            display: 'flex', flexDirection: 'column', marginRight: '3.5px', width: '100%', height: 'calc( 100% - 2px )', border: '1px solid #424242'
          }}>
            <div className='ItemBorder' style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #424242',
                      height:'37px', width: '100%' }}>
              <div style={{ marginLeft: '10px', fontSize: '12px', color: '#424242', WebkitTextStroke: '0.2px #424242' }}>姓名</div>
              <div style={{ marginLeft: '40px', fontSize: '12px', color: '#424242', WebkitTextStroke: '0.2px #424242' }}>生日</div>
              <div style={{ marginLeft: '46px', fontSize: '12px', color: '#424242', WebkitTextStroke: '0.2px #424242' }}>为什么离开这个世界</div>
            </div>

            <div
              id="scrollableDiv" ref={scrollContainerRef} className='HiddenScrollBar'
              style={{
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                height: checkinFinish ? '353px' : '293px',

              }}
            >

              <List className='no-border HiddenScrollBar'
              style={{overflowX:'hidden',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',}}
                dataSource={infoData}
                renderItem={(item) => (
                  <List.Item className='ant-list-item' style={{ borderBottom: 'none', padding: '0' }}>
                    <div className='ItemBorder' style={{
                      display: 'flex', alignItems:'center',
                      borderBottom: `${infoData[infoData.length - 1] === item ? '0px' : '1px'} solid #424242`, backgroundColor: item.newAdd ? 'rgba(189, 238, 213, 0.3)' : ''
                    }}>
                      <div style={{  width: '58px',lineHeight:'18px',
                      margin:'9px 0  9px 10px' }} className='ItemText'>{item.name}</div>
                      <div style={{width: '66px',lineHeight:'18px',
                      margin:'9px 0 9px 5px', }} className='ItemText'>{item.birthday}</div>
                      <div style={{ 
                      width:item.newAdd ?'133px':'149px',lineHeight:'18px',
                      margin:'9px 0 9px 8px',
                          fontFamily: 'qssx',
                          fontSize: '13px',
                          color: '#7E7E7E',
                          wordBreak:'break-all'  }} >{replaceN(item.reason)}</div>
                      {
                        item.newAdd ? <div style={{ padding: '5px 5px 5px 4px'}}  onClick={() => {
                          setCheckinShow(true)
                        }}>
                <img src={imgEdit} style={{ width: '17px', height: '17px' }} /></div> : <></>
                      }

                    </div>
                  </List.Item>
                )}
                locale={{
                  emptyText: <></>,
                }}
              />

            </div>

            {!checkinFinish ?
              <SoundBtn style={{ display: 'block', alignSelf: 'center', width: '308px', height: '60px', position: 'relative', top: '3px' }} onClick={() => {
                setCheckinShow(true)
              }}>
                <img src={imgBtn} style={{ width: '100%', height: '100%' }} />
              </SoundBtn> : <></>}


          </div>
          <div style={{ width: '5px', height: checkinFinish ? '349px' : '289px', marginTop: '37px', backgroundColor: 'rgba(66, 66, 66, 0.2)' }}>
            <div style={{ width: '5px', height: '25px', backgroundColor: '#424242', marginTop: `${scrollPosition}px` }}></div>
          </div>
        </div>

        <div ref={lottieRef} style={{
          pointerEvents: 'none',
          width: 165, height: 165, overflow: 'hidden',
          display: 'flex', justifyContent: 'center', position: 'absolute', top: -115, right: 0
        }}>
          {ghostLottie && <Lottie style={{ marginRight: -40 }} animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
        </div>

      </div>



      {checkinFinish ? <SoundBtn className='Btn OkBtn' style={{ width: '313px', height: '48px', position: 'absolute', bottom: `${((window.innerHeight - 506) * 151 / 342 - 58) * 2 / 3}px` }} onClick={() => { outAnimation(rootRef.current, () => { props.goNext() }) }}>就这样</SoundBtn> : <></>}





      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} style={{
        width: "100%", height: '100%', backgroundColor: 'transparent',
      }} visible={checkinShow} onClose={() => setCheckinShow(false)}>
        <div style={{ width: "100%", height: '100%', display: 'flex', position: 'relative', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}
          onClick={() => { setCheckinShow(false); }}>
          <div ref={inputDialogRef} onClick={(event) => { event.stopPropagation(); }} style={{ width: "318px", height: '330px', display: 'flex', flexDirection: 'column' }} className='CheckinDialogBg' >
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '23px' }}>
              <span style={{ width: '70px', lineHeight: '40px', height: '40px', textAlign: 'center', marginLeft: '25px', fontSize: '25px', color: '#000000', fontFamily: 'ipix' }}>姓&nbsp;&nbsp;&nbsp;名</span>
              <input  ref={inputNameRef} placeholder='点击输入' maxLength={4} style={{ width: '172px', height: '38px', marginLeft: '20px' }} className='Input' type="text" value={nameInput} onChange={(e) => { setNameInput(e.target.value) }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '18px' }}>
              <span style={{ width: '70px', lineHeight: '40px', height: '40px', textAlign: 'center', marginLeft: '25px', fontSize: '25px', color: '#000000', fontFamily: 'ipix' }}>生&nbsp;&nbsp;&nbsp;日</span>

              <div onClick={() => { setBirthdayShow(true) }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '172px', height: '38px', alignContent: 'center', marginLeft: '20px', textAlign: 'center' }} className='Input' >
                {birthValue()}
              </div>


            </div>
            <div style={{ marginTop: '18px', marginLeft: '25px', fontSize: '25px', color: '#000000', fontFamily: 'ipix' }}>为什么离开这个世界？</div>
            <textarea  ref={inputReasonRef} placeholder='点击输入' maxLength={20} style={{ overflowY:'hidden', marginTop: '8px', marginLeft: '25px', width: '236px', height: '43px', padding:'10px 13px 10px 13px' ,textAlign:'center' }} className='Input'  value={reasonInput} onChange={(e) => { setReasonInput(e.target.value) }} />
            <SoundBtn className="Btn" style={{ width: '94px', height: '42px', alignSelf: 'center', margin: 'auto', marginTop: '13px', fontFamily: 'ipix' }}
              onClick={() => {
                let name = nameInput//inputNameRef.current.value
                let reason = reasonInput//inputReasonRef.current.value
                xslog(`name ${name}  reason ${reason}`)
                if (isEmpty(name)) {
                  notify("请登记姓名")
                  return
                }
                if (isEmpty(reason)) {
                  notify("请登记为什么离开这个世界")
                  return
                }

                ContentAudit(ContentAuditScene.name, name, () => {

                  ContentAudit(ContentAuditScene.reason, reason, () => {
                    setCheckinShow(false)
                    let info = new CheckinInfo(name, formatDate(curBirthdayDate()), reason)
                    info.newAdd = true

                    let _createInfo = createInfo
                    if (_createInfo == null) {
                      _createInfo = new CreateInfo()
                    }
                    _createInfo.name = name
                    _createInfo.birthday = curBirthdayDate().getTime()
                    _createInfo.reason = reason
                    setCreateInfo(_createInfo);
                    let preVV = infoData
                    if (preVV.length > 0 && preVV[preVV.length - 1].newAdd) {
                      const updatedItems = preVV.map(item =>
                        item.newAdd ? info : item
                      );
                      setInfoData(updatedItems);
                    } else {
                      setInfoData((prevItems) => [...prevItems, info]);

                      handleScroll();
                    }


                    setCheckinFinish(true);
                  }, (e) => { notify(e.msg) })
                }, (e) => { notify(e.msg) })




              }}
            >确认</SoundBtn>


          </div>
        </div>

      </Popup>
      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} position='bottom' style={{ width: "100vw", height: '324px', backgroundColor: 'transparent' }} visible={birthdayShow} onClose={() => setBirthdayShow(false)}>
        <div className='DateDialogBorder' style={{ flex: 1, backgroundColor: '#FFFFFF', alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', }}>
          <img src={imgDialogClose} style={{ width: '26px', height: '26px', marginTop: '10px', marginRight: '10px', alignSelf: 'end' }} onClick={() => setBirthdayShow(false)} />
          <div style={{ fontFamily: 'ipix', color: '#424242', fontSize: '20px', marginTop: '5px' }}>你来到这个世界的时间是? </div>
          <div style={{ width: '100%', marginTop: '20px' }}>
            <DatetimePicker ref={datePickerRef}
              title='选择年月日' showToolbar={false}
              type='date' itemHeight='30px'
              minDate={new Date(1970, 0, 1)}
              maxDate={new Date(Date.now() - 1000 * 60 * 60 * 24)}
              value={curBirthdayDate()}
              formatter={(type: string, val: string) => {
                if (type === 'year') {
                  return `${val}年`
                }
                if (type === 'month') {
                  return `${val}月`
                } if (type === 'day') {
                  return `${val}日`
                }
                return val
              }}
              onChange={(val) => { selBirthday = val }}
            />
          </div>
          <SoundBtn className="Btn" style={{ width: '313px', height: '42px', alignSelf: 'center', margin: 'auto', marginTop: '13px', fontFamily: 'ipix' }} onClick={() => {
            if (selBirthday) {
              setSelectBirthdayValue(selBirthday)
              selBirthday = undefined
            }
            setBirthdayShow(false)
          }}>好了</SoundBtn>
        </div>



      </Popup>

    </div>
  );
})

export default CheckIn;




