import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './Tombstone.css';

import XSDataContext from './context/XSDataContext';
import imgSelBg from './images/icon_sel_bg.png';
import imgTitle from './images/img_title_tombstone.png';

import { stoneData } from './beans/Constrants';
import SoundBtn from './components/SoundBtn';
import XSCreateContext from './context/XSCreateContext';
import { inAnimation, outAnimation } from './util/CommonUtil';
import XSMusicContext from './context/XSMusicContext';

interface TombstoneProps {
  goNext: () => void;
}
const Tombstone = forwardRef<any, TombstoneProps>((props, ref) => {

  const { statusBarHeight } = React.useContext(XSDataContext);

  const { createInfo, setCreateInfo } = React.useContext(XSCreateContext);    
  const { playClickMusicSound,playClickTombSound } = React.useContext(XSMusicContext);
  const [index, setIndex] = useState(-1);
  const gridContainerRef = useRef(null);
  const rootRef = useRef(null);
  

  const imgStyle = {
    width: '150px',
    height: '146px'
  }

  const Stone = (item) => {
    return (<div onClick={() => {
      playClickTombSound();
      setIndex(item.key) }} key={item.key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '150px', height: '146px', marginBottom: `${((window.innerHeight > 700) ? 20 : 12)}px` }}>
      <img src={item.img} style={imgStyle} />
      {/* <div style={{ color: '#424242', fontSize: '15px', marginTop: '10px' }}>   {item.name}</div> */}
    </div>)
  }
  const SelHeight = 206;
  const SelWidth = 210;
  const SelTop = () => {
    if (gridContainerRef.current == undefined) {
      return 0;
    }
    const rect = gridContainerRef.current.getBoundingClientRect();
    const gridTop = rect.top;
    return gridTop  -35 + (Math.floor(index / 2)) * (146 + ((window.innerHeight > 700) ? 20 : 12)) 

    // return gridTop -48 + (Math.floor(index / 2)) * (123 + ((window.innerHeight > 700) ? 20 : 12)) 
  }

  const SelLeft = () => {
    if(index <0){
      return -2000
    }
    // return (window.innerWidth - SelWidth * 2) / 3 + (window.innerWidth / 2) * (index % 2)
    return (window.innerWidth-300)/3+75-SelWidth/2 +(index % 2)*(150+(window.innerWidth-300)/3)
  }


  useEffect(() => {
    setIndex(createInfo.stoneIndex)
    inAnimation(rootRef.current,()=>{})
    return () => {

    };
  }, []);








  return (
    <div ref={rootRef} style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100vw', alignItems: 'center' }}>
      <img src={imgTitle} style={{ width: `${35200/393}%`,  marginTop: `${statusBarHeight + ((window.innerHeight > 700) ? 56 : 28)}px` }} />
      <img src={imgSelBg} style={{ width: `${SelWidth}px`, height: `${SelHeight}px`, position: 'absolute', top: `${SelTop()}px`, left: `${SelLeft()}px` }} />
      <div ref={gridContainerRef} className='StoneContainer' style={{zIndex:100, columnGap: `${(window.innerWidth - 300) / 3}px`, marginTop: `${((window.innerHeight > 700) ? 25 : 0)}px` }}>
        {stoneData.map(item => (
          Stone(item)
        ))}
      </div>
      {index >= 0 ? <SoundBtn className='Btn' style={{ zIndex:101,height: `48px`, width: `313px`, position: 'absolute', bottom: `${((window.innerHeight > 700) ? 49 : 10)}px` }} onClick={() => {
        let _createInfo = createInfo
        _createInfo.stoneIndex = index
        setCreateInfo(_createInfo);
       
        outAnimation( rootRef.current,()=>{   props.goNext()})  
      }}>就它了</SoundBtn> : <></>}
    </div>
  );
})

export default Tombstone;




