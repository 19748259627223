import React, { CSSProperties, forwardRef, useEffect, useRef, useState } from 'react';
import { List } from 'antd';
import './App.css';
import imgOffer from './images/icon_message_offer.png';
import imgWish from './images/icon_message_wish.png';
import './MessageAutoScrollList.css';
import { xslog } from './util/XSLog';
import { replaceHttpsUrl, shortShowLabel } from './util/CommonUtil';

interface MessagesAutoScrollListProps {
  mine: Boolean;
  userlabel: string;
  style: CSSProperties;
  data: any[];
}

const MessagesAutoScrollList = forwardRef<any, MessagesAutoScrollListProps>((props, ref) => {
  const listRef = useRef(null);

  const step = 1;
  const timerRef = useRef(null);
  const startAutoScroll = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
    timerRef.current = setInterval(() => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        // xslog(`scrollTop ${scrollTop} scrollHeight ${scrollHeight} clientHeight ${clientHeight}`);
        if (scrollTop + clientHeight >= scrollHeight || scrollTop + + clientHeight + step >= scrollHeight) {
          listRef.current.scrollTop = 0;
        } else {

          listRef.current.scrollTop += step; // 你可以调整这个值来控制滚动速度
        }
      }
    }, 1000 / 60); // 调整这个值来控制滚动频率
  }

  const [selfHeight,setSelfHeight] = useState(354);

  useEffect(() => {
  if(listRef.current){
    let nh = listRef.current.offsetHeight;
    if(nh>0){
      setSelfHeight(nh)
    }
  }

    startAutoScroll()

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    };
  }, []);

  useEffect(() => {
    listRef.current.scrollTop = 0;
    startAutoScroll()

  }, [props.data]);




  // "id": 1,
  // 			"message": "一路走好",
  // 			"offeringsName": "果篮",
  // 			"offeringsUrl": "http://files.cijian.link/615f0eb74a21447ebf36488ec87e6071.jpg",
  // 			"read": true,
  // 			"user": {
  // 				"avatar": "http://files.cijian.link/615f0eb74a21447ebf36488ec87e6071.jpg",
  // 				"name": "隔壁老王"
  // 			}

  return (
    <div className='MessageContainer' style={{
      ...props.style, width: '100%', overflow: 'auto', overflowY: 'scroll',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    }} ref={listRef} onTouchStart={() => {
      xslog("onTouchStart");
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
    } onTouchEnd={() => {
      xslog("onTouchEnd")
      startAutoScroll();
    }}>
      <List className='no-border no-border-header'
        size="large"
        style={{}}
        locale={{
          emptyText: <></>,
        }}
        header={<div style={{ height:`${selfHeight}px`, opacity: 0 }}></div>}
        footer={<div style={{ height: `${selfHeight}px`, opacity: 0 }}></div>}
        dataSource={props.data}
        renderItem={item =>
          <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', width: 'calc(100% - 40px)', margin: '0 0 10px 20px', backgroundColor: 'rgba(255, 255, 255, 0.4)', border: '1px solid rgba(255, 255, 255, 0.3)', borderRadius: '5px' }}>

            <img src={replaceHttpsUrl(item.user.avatar)} style={{ width: '31px', height: '31px', margin: '10px 10px 10px 15px', borderRadius: '3px', border: '1px solid rgba(255, 255, 255, 0.3)' }} />

            {/* content ： 供品名称或遗愿清单
type: 0-供品；1-遗愿清单
message: 供品留言内容 */}

            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, margin: '10px 15px 0 0' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '13px', textAlign: 'center' }}>
                <span style={{ fontSize: '11px', lineHeight: '13px', color: '#8A4A50', fontFamily: 'ipix' }}>{item.type == 0 ? '送上供品' : `要为${props.userlabel}实现遗愿`} 「{item.type == 0 ?item.content:shortShowLabel(item.content, 10)}」</span>
                <img src={item.type == 0 ? imgWish : imgOffer} style={{ width: '12px', height: '13px', transform: 'translate(0,-1px)' }}></img>
              </div>

              {(item.type == 0 && item.message !== undefined && item.message !== null && item.message !== "") && <span style={{ fontSize: '12px', lineHeight: '18px', color: '#424242', margin: '3px 6px 0 0 ', fontFamily: 'ipix' }}>“{item.message}” </span>}
              <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', alignItems: 'center', margin: '6px 0px 10px 0', fontFamily: 'ipix' }}>
                <span style={{ fontSize: '10px', color: '#424242', opacity: 0.6 }}>——「{item.user.relation}」</span>
                <span style={{ fontSize: '10px', color: '#424242', opacity: 0.6 }}>{item.user.name}</span>
              </div>





            </div>

            {(item.isNew && props.mine) && <div style={{ backgroundColor: '#F85F5F', width: 7, height: 7, borderRadius: '50%', position: 'absolute', top: 3, right: 3 }} />}



          </div>

        }
      />
    </div>
  );
});

export default MessagesAutoScrollList;