import { animate, linear } from "popmotion";
import { xslog } from "./XSLog";
import { format } from 'date-fns';
import { CSSProperties } from "react";
import { NavigateFunction } from "react-router-dom";
import { Environment } from "./environment";
import { BASE_URL } from "../beans/Environment";

export const formatDate = (dateV) => {
  return format(dateV, 'yyyy.MM.dd');
};

export const formatDateByFormat = (ms, formatstr) => {
  let dateV = new Date(ms);
  return format(dateV, formatstr);
};

export const outAnimation = (ele: HTMLElement, done: () => void) => {
  animate({
    from: 0,
    to: 1,
    ease: linear,
    duration: 300,
    onUpdate: (progress) => {
      try {
        (ele.style as CSSProperties).opacity = 1 - progress;
      } catch (error) {
        xslog(error)
      }
    },
    onComplete: () => {
      done();

    }
  })
}

export const inAnimation = (ele: HTMLElement, done: () => void) => {
  (ele.style as CSSProperties).opacity = 0;
  animate({
    from: 0,
    to: 1,
    ease: linear,
    duration: 300,
    onUpdate: (progress) => {
      try {
        (ele.style as CSSProperties).opacity = progress;
      } catch (error) {
        xslog(error)
      }
    },
    onComplete: () => {
      done();

    }
  })
}

export const shortShowLabel = (label: string, length: number) => {
  if (label.length <= length) {
    return label;
  } else {
    return label.substring(0, length) + "...";
  }
}

export const replaceHttpsUrl = (url: string) => {
  if (url.startsWith('http://')) {
    // 如果是，则将其替换为https://
    return url.replace('http://', 'https://');
  }
  // 如果不是http开头，直接返回原URL
  return url;
}

export const replaceN = (text: string) => {
  return text.replace('/\n/g', '');
}

export const goDownload = (environment: Environment, navigate: NavigateFunction,hasBack?:boolean) => {
  if (environment == Environment.QQ_APP) {
    window.location.href = `${BASE_URL}/download${hasBack?'/canback':''}`
  } else {
    navigate(`/download${hasBack?'/canback':''}`)
  }
}

export const isEmpty = (label:string) => {
  return (label === null || label === undefined || label ==='' || label.trim() === '')
}

export function shuffleArray<T>(array: T[]): T[] {
  // 从数组的最后一个元素开始迭代
  for (let i = array.length - 1; i > 0; i--) {
    // 生成一个从 0 到 i 之间的随机整数
    const j = Math.floor(Math.random() * (i + 1));
    // 交换位置 i 和 j 处的元素
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}