import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { XsHttpCient } from './util/HttpUtil';
import { Environment } from './util/environment';
import { xslog } from './util/XSLog';
import XSDataContext from './context/XSDataContext';
import { getUserInfo } from './util/NativeBridge';
import { UserInfo } from './beans/DataTypes';
import { goDownload } from './util/CommonUtil';



function Home() {
    const { statusBarHeight, setStatusBarHeight, keyboardEvent, onKeyBoardShow, environment, user, setUser } = React.useContext(XSDataContext);

    const navigate = useNavigate();
    const loadData = (_uid: String) => {
        xslog(`user.uid ${user.uid}   _uid ${_uid}`)

        XsHttpCient.get(`funeral/detail?uidStr=${_uid}`).then((res) => {
            xslog(res);
            navigate('/mine')
        }).catch((error) => {
            //notify(error.msg);

            navigate('/create')
        });
    }



    useEffect(() => {
        xslog('Home')
        if (user != null && user.uid != null) {
            loadData(user.uid)
        } else {
            goDownload(environment, navigate)
        }
        return () => { };

    }, [])
    return <></>;
};

export default Home;