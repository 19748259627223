import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './Wishes.css';

import XSDataContext from './context/XSDataContext';
import imgTipsAdd from './images/img_tips_add.png';
import imgTipsB from './images/img_tips_b.png';
import imgTipsG from './images/img_tips_g.png';
import imgTipsR from './images/img_tips_r.png';
import imgTitle from './images/img_title_wished.png';
import imgTipsClose from './images/img_tips_close.png';

import { xslog } from './util/XSLog';
import { Popup } from 'react-vant';
import { ContentAudit, ContentAuditScene, XsHttpCient } from './util/HttpUtil';
import { notify } from './util/NotifyUtil';
import { DefaultAvatars, LOCALSTORAGE_KEY, taLabel } from './beans/Constrants';
import { Environment } from './util/environment';
import { isEmpty, replaceHttpsUrl } from './util/CommonUtil';
import imgChangeAvatar from './images/icon_change_avatar.png';
import { WebUserInfo } from './beans/DataTypes';


interface WishesCompProps {
  visitor: boolean;
  sex: number;
  wishes?: any[];
  setWishes?: ([]) => void;
  relation?: string;
  onFinish?: (update: boolean) => void;
  gap?: number;
}
const WishesComp = forwardRef<any, WishesCompProps>((props, ref) => {


  const { statusBarHeight, environment,keyboardEvent, inApp, user, webUser ,setWebUser} = React.useContext(XSDataContext);


  const [wishesData, setWishesData] = useState([]);

  const [confirm, setConfirm] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);

  const [curWish, setCurWish] = useState(null);
  const gridContainerRef = useRef(null);

  const [fillUserInfo, setFillUserInfo] = useState(false);
  const [fillAvatarIndex, setFillAvatarIndex] = useState(0)
  const [fillUserName, setFilluserName] = useState("")


  const imgStyle = {
    width: '100px',
    height: '100px'
  }

  const ITEM_WIDTH = 114;


  useEffect(() => {
    if (environment === Environment.ANDROID_APP) {
      if (gridContainerRef.current) {
        if (keyboardEvent.show) {
          const rect = gridContainerRef.current.getBoundingClientRect();
          let vH = (window.innerHeight - keyboardEvent.height);
          let transY = 0;
          if (vH > rect.height) {
            transY = (vH - rect.height) / 2 - rect.top;
          } else {
            transY = vH - rect.bottom;
          }
          gridContainerRef.current.style.transform = `translateY(${transY}px)`
          // setShowMaskAndDm(true)
        } else {
          gridContainerRef.current.style.transform = ``
          // setShowMaskAndDm(false)
        }

      }
    } else {

    }
  }, [keyboardEvent]);

  const tipsBg = (tip) => {
    if (tip.isAdd) {
      return imgTipsAdd
    }
    let tipsIndex = tip.key;
    if (tipsIndex === 0 || tipsIndex === 4 || tipsIndex == 6) {
      return imgTipsR;
    } else if (tipsIndex === 1 || tipsIndex === 5 || tipsIndex == 7) {
      return imgTipsB;
    } else {
      return imgTipsG;
    }
  }

  const handleTextChange = (index, value) => {

    xslog('handleTextChange', index, value)
    const newTextValues = wishesData.map((item, i) =>
      i === index ? { ...item, content: value } : item
    );
    setWishesData(newTextValues);
  };

  const handleInput = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const toAdd = (item) => {
    let index = item.key;
    const newTextValues = wishesData.map((item, i) =>
      i === index ? { ...item, isAdd: false, } : item
    );

    xslog('toAdd 1', newTextValues)
    if (index != 8) {
      newTextValues.push({
        key: index + 1, content: '', isAdd: true, user: null, newEdit: true
      })
    }
    xslog('toAdd 2', newTextValues)
    setWishesData(newTextValues);
  };

  const toClose = (item) => {
    let toRemoveIndex = item.key;
    var newArray = wishesData.filter((_, index) => index !== toRemoveIndex);
    const newTextValues = newArray.map((item, i) => (
      { ...item, key: i }
    )
    );
    if (newTextValues.length == 0 || (newTextValues.length != 9 && !newTextValues[newTextValues.length - 1].isAdd)) {
      newTextValues.push({
        key: newTextValues.length, content: '', isAdd: true, user: null, newEdit: true
      })
    }
    setWishesData(newTextValues);
  };

  useEffect(() => {

    xslog('wishesData', wishesData)
  }, [wishesData]);

  const onClick = (item) => {
    if (props.visitor) {
      if (!item.teared) {
        setCurWish(item)
        setConfirm(true)
      }
    }
  }


 


  const onBack = () => {
    if (dataChanged()) {
      setConfirmSave(true)
    } else {
      if (props.onFinish) {
        props.onFinish(false)
      }
    }
  };

  React.useImperativeHandle(ref, () => ({
    onBack
  }));


  const marginB = ()=>{
    xslog(` props.gap ${props.gap}`)
    if(props.gap!=null){
      return props.gap;
    }else return (window.innerHeight > 700) ? 8 : 3;
  }

  const marginB2 = ()=>{
    xslog(` props.gap ${props.gap}`)
    if(props.gap!=null){
      return props.gap;
    }else return 11;
  }


  const WishTip = (item) => {
    return (
      <div key={item.key} onClick={() => { onClick(item) }} style={{
        display: 'flex', position: 'relative',
        flexDirection: 'column', alignItems: 'center', width: `${ITEM_WIDTH}px`, height: `${129+marginB()+marginB2()}px`, marginBottom: `${marginB()}px`
      }}>
        <div onClick={() => {
          if (item.isAdd) {
            toAdd(item)
          }
        }}
          style={{
            opacity: item.teared && props.visitor ? 0.5 : 1,
            backgroundImage: `url(${tipsBg(item)})`, backgroundSize: item.isAdd ? '60px 72px' : '108px 108px', backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat', display: 'flex', position: 'relative', justifyContent: 'center',
            flexDirection: 'column', alignItems: 'center', width: '108px', height: '108px'
          }}>

          {(!(item.isAdd || item.newEdit)) && <div style={{ lineHeight: '15px', width: '81px', marginTop: '10px', height: 'auto', visibility: item.isAdd || item.newEdit ? 'hidden' : 'visible' }} className='TipsInput' >{item.content}</div>}

          {item.newEdit ? <textarea value={item.content} onChange={(e) => {
            handleTextChange(item.key, e.target.value);
            handleInput(e);
          }} placeholder='点击输入' maxLength={20} style={{ lineHeight: '15px', width: '81px', marginTop: '10px', textAlign: 'center', visibility: item.isAdd ? 'hidden' : 'visible' }} className='TipsInput' ></textarea> : <></>}


          {item.newEdit && !item.isAdd ? <img onClick={() => { toClose(item) }} src={imgTipsClose} style={{ width: '18px', height: '18px', position: 'absolute', top: '5px', left: '0px' }} /> : <></>}

        </div>
        {item.tearCount > 0 ?
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative', height: '19px', padding: '0 5px 0 5px', 
          marginTop: `${marginB2()}px`, border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '2px 2px 2px 2px', backgroundColor: 'rgba(255,255, 255, 0.41)', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative', width: `${11 + (item.userAvatar.length - 1) * 8}px`, height: '11px' }}>
              {item.userAvatar.map((item, index) => (
                <img src={replaceHttpsUrl(item)} style={{ width: '10px', height: '10px', border: '0.5px solid #FFFFFF', borderRadius: '50%', position: 'absolute', left: `${8 * (index)}px`, top: '0px' }} />
              ))}
            </div>
            <span style={{ marginLeft: '3px', fontSize: '9px', color: '#008D17' }}>{`${item.tearCount > 99 ? '99+' : `${item.tearCount}`}人已为${props.visitor ? taLabel(props.sex) : '你'}撕下`}</span>
          </div>
          :
          <></>
        }

{(item.checkError&&!props.visitor) && <div style={{ position: 'absolute', bottom: '10px', fontSize: '10px', color: '#ff0000' }}>{'内容疑似违规' }</div>}


      </div>)
  }


  useEffect(() => {
    const initWishes = () => {
      let ws = [...props.wishes];
      if (ws == null || ws == undefined) {
        ws = []
      }
      ws.forEach((item, index, arr) => {
        item.key = index
      })
      if (!props.visitor && ws.length < 9) {
        ws.push({
          key: ws.length, content: '', isAdd: true, user: null, newEdit: true
        })
      }
      return ws
    }
    setWishesData(initWishes())
    return () => {

    };
  }, []);


  const dataChanged = () => {
    let oldData = props.wishes
    if (oldData == null) {
      oldData = []
    }
    let newData = wishesData;
    if (newData == null) {
      newData = []
    }
    if (oldData.length == newData.length) {
      return false
    }

    for (let i = 0; i < newData.length; i++) {
      let c = newData[i].content;
      if (i >= oldData.length && !isEmpty(c)) {
        return true;
      }
    }
    return false;
  }


  const doSaveChange = ()=>{
    if (!inApp && !webUser) {
      setFillUserInfo(true);
    }else{
      doSaveChange()
    }

  }
  const saveChange = () => {
    let newWishes = []

    let oldData = props.wishes
    if (oldData == null) {
      oldData = []
    }

    let newData = wishesData;
    if (newData == null) {
      newData = []
    }
    for (let i = 0; i < newData.length; i++) {
      let c = newData[i].content;
      if (i >= oldData.length && !isEmpty(c)) {
        newWishes.push(c)
      }
    }
    if (newWishes.length > 0) {

      let result = oldData.length;
    let failresult = 0
    let newWishesData = [...wishesData];
    const checkResult = () =>{
      if (result === wishesData.length ) {
        if (failresult == 0) {
          XsHttpCient.post(`funeral/addLastwish`, newWishes).then((res) => {
            if (props.onFinish) {
              props.onFinish(true)
            }
    
          }).catch((error) => {
            notify(error.msg);
          });
        } else {
          setWishesData(newWishesData)
        }
      }
    }

    newWishesData.forEach((item, index, arr) => {
      if(index>=oldData.length){
      let c = item.content
      if (isEmpty(c)) {
        result += 1;
        item.checkError = false
        checkResult()
      } else {
        ContentAudit(ContentAuditScene.list, c, () => {
          result += 1;
          item.checkError = false
          checkResult()
        }, (err) => {
          result += 1;
          failresult += 1;
          item.checkError = true
          checkResult()
        })
      }}
    });
     
    }
    
  }

  const changeFillAvatar = () => {
    let newindex = fillAvatarIndex + 1;
    if (newindex >= DefaultAvatars.length) {
      newindex = 0;
    }
    setFillAvatarIndex(newindex);
  }


  const impYourWish = () => {
    if (!inApp && !webUser) {
      setFillUserInfo(true)
      return;
    }else{
      doImpYourWish()
    }
  }
  const doImpYourWish = (wu?: WebUserInfo) => {
    let realWu = webUser;
    if (wu) {
      realWu = wu;
    }
    let wish = curWish

    let poData = { id: wish.id, relation: props.relation };
    let un = ""
    let ua = ""
    if (inApp) {
      un = user.name;
      ua = user.avatar;
    } else if (realWu) {
      un = realWu.name;
      ua = realWu.avatar;
      poData['userName'] = un;
      poData['userAvatar'] = ua;
    }

    XsHttpCient.post('funeral/tearLastwish', poData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      if (un === '') {
        un = "游客";
      }
      if (ua === '') {
        ua = DefaultAvatars[0];
      }

      const newItem = (oldItem, ua) => {
        let newItem = { ...oldItem };
        newItem.teared = true;
        newItem.userAvatar = [];
        if (oldItem.userAvatar != null) {
          newItem.userAvatar = [...oldItem.userAvatar];
        }
        newItem.userAvatar.push(ua);
        if (newItem.userAvatar.length > 3) {
          newItem.userAvatar = newItem.userAvatar.slice(-3);
        }
        newItem.tearCount = oldItem.tearCount + 1;
        return newItem;
      }
      const newTextValues = wishesData.map((item, i) =>
        i === wish.key ? newItem(item, ua) : item
      );
      setWishesData(newTextValues);
      props.setWishes(newTextValues)

    }).catch((error) => {
      xslog(error)
      notify(error.msg)
    });


  }

  const fillAndImpYourWish = () => {
    if (isEmpty(fillUserName)) {
      notify('请输入名字');
      return
    }
    ContentAudit(ContentAuditScene.infoName, fillUserName, () => {
      let wu = new WebUserInfo(2, fillUserName, DefaultAvatars[fillAvatarIndex]);
      let str = JSON.stringify(wu)
      xslog('WebUserInfo is ' + str);
      localStorage.setItem(LOCALSTORAGE_KEY.webuser, str)
      setWebUser(wu)
      doImpYourWish(wu)
      setFillUserInfo(false)
    }, (error) => {
      notify(error.msg);
    })


  }

  const doYourWish = ()=>{

  }







  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <div ref={gridContainerRef} className='WishesContainer' style={{ columnGap: `${(window.innerWidth - 3 * ITEM_WIDTH) / 4}px` }}>
        {wishesData.map(item => (
          WishTip(item)
        ))}
      </div>
      {dataChanged() ? <div onClick={saveChange} className='Btn' style={{ width: '300px', height: '48px', position: 'absolute', bottom: '60px' }}>保存</div>
        : <></>}


      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} style={{
        backgroundColor: 'transparent',
      }} visible={confirm} onClose={() => setConfirm(false)}>
        <div className='CommonDialog' style={{ width: '315px', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ width: '280px', margin: '20px 0 0 0 ', lineHeight: '30px', textAlign: 'center', fontFamily: 'ipix' }}>{`你确定能真的帮${taLabel(props.sex)}实现这个愿望才可以撕下哦，你真的确定吗？`}</span>

          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '22px 0 20px 0', alignItems: 'center', justifyContent: 'center' }}>
            <div onClick={() => { setConfirm(false) }} className='Btn' style={{ width: '112px', height: '42px', fontSize: '17px', fontFamily: 'ipix' }}>取消</div>
            <div onClick={() => { setConfirm(false); impYourWish(); }} className='Btn' style={{ width: '112px', height: '42px', marginLeft: '23px', fontSize: '17px', fontFamily: 'ipix' }}>确定</div>
          </div>
        </div>

      </Popup>

      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} style={{
        backgroundColor: 'transparent',
      }} visible={confirmSave} onClose={() => setConfirmSave(false)}>
        <div className='CommonDialog' style={{ width: '315px', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ width: '280px', margin: '20px 0 0 0 ', lineHeight: '30px', textAlign: 'center', fontFamily: 'ipix' }}>是否保存刚刚编辑的内容
            <br />确定吗?</span>

          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '22px 0 20px 0', alignItems: 'center', justifyContent: 'center' }}>
            <div onClick={() => {
                setConfirmSave(false);
                if (props.onFinish) {
                  props.onFinish(false)
                }

            }} className='Btn' style={{ width: '112px', height: '42px', fontSize: '17px', fontFamily: 'ipix' }}>不保存</div>
            <div onClick={() => {
              saveChange()
            }} className='Btn' style={{ width: '112px', height: '42px', marginLeft: '23px', fontSize: '17px', fontFamily: 'ipix' }}>保存</div>
          </div>
        </div>

      </Popup>

      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} style={{
        backgroundColor: 'transparent',
      }} visible={fillUserInfo} onClose={() => setFillUserInfo(false)}>
        <div className='CommonDialog' style={{ width: '333px', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ margin: '20px 0 0 0 ', lineHeight: '30px', textAlign: 'center', fontFamily: 'ipix', fontSize: '20px' }}>且慢！最后请留一下你的信息</span>
          <div onClick={changeFillAvatar} style={{ position: "relative", height: '90px', display: 'flex', flexDirection: 'row', margin: '10px 0 10px 0', alignItems: 'center', justifyContent: 'center' }}>
            <img src={DefaultAvatars[fillAvatarIndex]} style={{ width: '70px', height: '70px', border: '2px solid #424242', borderRadius: '5px' }} />
            <img src={imgChangeAvatar} style={{ width: '57px', height: '16px', position: 'absolute', bottom: '0px' }} />

          </div>

          <input placeholder={`你的名字是?  (要写个${taLabel(props.sex)}认得的哦)`} maxLength={4} className='Input' style={{ fontFamily: 'ipix', width: 'calc(100% - 60px)', height: '40px', fontSize: '15px' }} type="text" value={fillUserName} onChange={(e) => { setFilluserName(e.target.value) }} />

          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '20px 0 20px 0', alignItems: 'center', justifyContent: 'center' }}>
            <div onClick={() => {
              setFillUserInfo(false);


            }} className='Btn' style={{ width: '112px', height: '42px', fontSize: '17px', fontFamily: 'ipix' }}>取消</div>
            <div onClick={() => {
              fillAndImpYourWish();
            }} className='Btn' style={{ width: '112px', height: '42px', marginLeft: '23px', fontSize: '17px', fontFamily: 'ipix' }}>确定</div>
          </div>
        </div>

      </Popup>

    </div>
  );
})

export default WishesComp;




