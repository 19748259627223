// components/WechatAuth.js
import React, { useEffect } from 'react';
import { WX_APPID, WX_REDIRECT } from '../beans/Environment';
import { xslog } from './XSLog';


export const getWechatAuthUrl = (uid) => {
    // const appId = WX_APPID;
    // const redirectUri = encodeURIComponent(`${WX_REDIRECT}?uuid=${window.xsuuid}`);
    // const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;

    // window.location.href = authUrl;
    const appId = WX_APPID;
    let wxredi = `${WX_REDIRECT}?uid=${uid}&uuid=${window.xsuuid}`
// wxredi = `http://test.cijianlink.com/view/${uid}?isCallback=true&name=abc&avatar=eee`
    const redirectUri = encodeURIComponent(wxredi);

    // const redirectUri = encodeURIComponent(`${window.location.origin}/view/${uid}?uuid=${window.xsuuid}`);
    const state = 'STATE';
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
};


export const doWechatAuth = (uid) => {
    let url = getWechatAuthUrl(uid);
    xslog(`doWechatAuth url ${url}`)

    window.location.href = url;

    // window.location.href = "https://www.163.com";
    // window.location.replace("https://www.163.com");
};
