import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './Wishes.css';

import XSDataContext from './context/XSDataContext';
import imgTipsAdd from './images/img_tips_add.png';
import imgTipsB from './images/img_tips_b.png';
import imgTipsG from './images/img_tips_g.png';
import imgTipsR from './images/img_tips_r.png';
import imgTitle from './images/img_title_wished.png';
import imgTipsClose from './images/img_tips_close.png';

import { xslog } from './util/XSLog';
import XSCreateContext from './context/XSCreateContext';
import { ContentAudit, ContentAuditScene } from './util/HttpUtil';
import SoundBtn from './components/SoundBtn';
import { inAnimation, isEmpty, outAnimation } from './util/CommonUtil';
import { Environment } from './util/environment';
import { notify } from './util/NotifyUtil';


interface WishesProps {
  goNext: () => void;
}
const Wishes = forwardRef<any, WishesProps>((props, ref) => {


  const [showMaskAndDm, setShowMaskAndDm] = useState(false);
  const { statusBarHeight, environment, keyboardEvent } = React.useContext(XSDataContext);
  const { createInfo, setCreateInfo } = React.useContext(XSCreateContext);
  //{ key: 0, content: '', isAdd: false }, { key: 1, content: '', isAdd: true }
  const [wishesData, setWishesData] = useState([]);
  const [wh, setWh] = useState(0)

  const rootRef = useRef(null);
  const gridContainerRef = useRef(null);


  useEffect(() => {
    setWishesData(createInfo.wishes)

    inAnimation(rootRef.current, () => { })
    setWh(window.innerHeight)
    return () => {


    };
  }, []);
  const imgStyle = {
    width: '100px',
    height: '100px'
  }

  const tipsBg = (tip) => {
    if (tip.isAdd) {
      return imgTipsAdd
    }
    let tipsIndex = tip.key;
    if (tipsIndex === 0 || tipsIndex === 4 || tipsIndex == 6) {
      return imgTipsR;
    } else if (tipsIndex === 1 || tipsIndex === 5 || tipsIndex == 7) {
      return imgTipsB;
    } else {
      return imgTipsG;
    }
  }

  const handleTextChange = (index, value) => {

    xslog('handleTextChange', index, value)
    const newTextValues = wishesData.map((item, i) =>
      i === index ? { ...item, content: value } : item
    );
    setWishesData(newTextValues);
  };

  const handleInput = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const toAdd = (item) => {
    let index = item.key;
    const newTextValues = wishesData.map((item, i) =>
      i === index ? { ...item, isAdd: false } : item
    );

    xslog('toAdd 1', newTextValues)
    if (index != 8) {
      newTextValues.push({
        key: index + 1, content: '', isAdd: true
      })
    }
    xslog('toAdd 2', newTextValues)
    setWishesData(newTextValues);
  };

  const toClose = (item) => {
    let toRemoveIndex = item.key;
    var newArray = wishesData.filter((_, index) => index !== toRemoveIndex);
    const newTextValues = newArray.map((item, i) => (
      { ...item, key: i }
    )
    );
    if (newTextValues.length == 0 || (newTextValues.length != 9 && !newTextValues[newTextValues.length - 1].isAdd)) {
      newTextValues.push({
        key: newTextValues.length, content: '', isAdd: true, user: null, newEdit: true
      })
    }
    setWishesData(newTextValues);
  };

  useEffect(() => {

    xslog('wishesData', wishesData)
  }, [wishesData]);

  useEffect(() => {
    if (environment === Environment.ANDROID_APP) {
      if (gridContainerRef.current) {
        if (keyboardEvent.show) {
          const rect = gridContainerRef.current.getBoundingClientRect();
          let vH = (window.innerHeight - keyboardEvent.height);
          let transY = 0;
          if (vH > rect.height) {
            transY = (vH - rect.height) / 2 - rect.top;
          } else {
            transY = vH - rect.bottom;
          }
          gridContainerRef.current.style.transform = `translateY(${transY}px)`
          setShowMaskAndDm(true)
        } else {
          gridContainerRef.current.style.transform = ``
          setShowMaskAndDm(false)
        }

      }
    } else {
      if (gridContainerRef.current) {
        if (keyboardEvent.show) {
          setShowMaskAndDm(true)
        } else {
          setShowMaskAndDm(false)
        }
      }
    }
  }, [keyboardEvent]);


  const WishTip = (item) => {
    return (<div onClick={() => {
      if (item.isAdd) {
        toAdd(item)
      }
    }} key={item.key}
      style={{
        backgroundImage: `url(${tipsBg(item)})`, backgroundSize: `${item.isAdd ? '60px 72px' : '108px 108px'}`, backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', display: 'flex', position: 'relative', justifyContent: 'center',
        flexDirection: 'column', alignItems: 'center', width: '108px', height: '108px', marginBottom: `${((wh > 700) ? 30 : 0)}px`
      }}>

      <textarea value={item.content} onChange={(e) => {
        handleTextChange(item.key, e.target.value);
        handleInput(e);
      }} placeholder='点击输入' maxLength={20} style={{ lineHeight: '15px', width: '81px', height: 'auto', marginTop: '10px', visibility: item.isAdd ? 'hidden' : 'visible' }} className='TipsInput' ></textarea>

      <img onClick={() => { toClose(item) }} src={imgTipsClose} style={{ width: '18px', height: '18px', position: 'absolute', top: '5px', left: '0px', visibility: item.isAdd ? 'hidden' : 'visible' }} />
      <div style={{ position: 'absolute', bottom: '-10px', fontSize: '10px', color: '#ff0000' }}>{item.checkError ? '内容疑似违规' : ''}</div>
    </div>)
  }





  const clickNext = () => {
    let result = 0;
    let failresult = 0
    let newWishesData = [...wishesData];


    let newCount = 0;
    newWishesData.forEach((item, index, arr) => {
      let c = item.content
      if (isEmpty(c)) {
      } else {
        newCount+=1
      }
    });
    if(newCount<=0){
      notify('请至少写一个遗愿噢～')
      return;
    }


    function checkResult() {
      if (result === wishesData.length ) {
        if (failresult == 0) {
          let _createInfo = createInfo
          _createInfo.wishes = wishesData
          setCreateInfo(_createInfo);
          outAnimation(rootRef.current, () => { props.goNext() })
        } else {
          setWishesData(newWishesData)
        }
      }
    }

    newWishesData.forEach((item, index, arr) => {
      let c = item.content
      if (isEmpty(c)) {
        result += 1;
        item.checkError = false
        checkResult()
      } else {
        ContentAudit(ContentAuditScene.list, c, () => {
          result += 1;
          item.checkError = false
          checkResult()
        }, (err) => {
          result += 1;
          failresult += 1;
          item.checkError = true
          checkResult()


        })
      }
    });


  }







  return (
    <div ref={rootRef} style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100vw', alignItems: 'center' }}>
      <img src={imgTitle} style={{ width: `${36200 / 393}%`, marginTop: `${statusBarHeight + ((wh > 700) ? 56 : 28)}px` }} />
      {showMaskAndDm && (
        <div style={{ pointerEvents: 'none', backgroundColor: 'rgba(0,0,0,0.2)', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute' }}>

        </div>)}

      <div ref={gridContainerRef} className='WishesContainer' style={{ columnGap: `${(window.innerWidth - 324) / 4}px`, marginTop: `${((wh > 700) ? 10 : 0)}px` }}>
        {wishesData.map(item => (
          WishTip(item)
        ))}
      </div>
      <SoundBtn className='Btn' style={{ height: `48px`, width: `313px`, position: 'absolute', bottom: `${((wh > 700) ? 75 : 10)}px` }} onClick={() => { clickNext() }}>先这样</SoundBtn>
      <div style={{ fontSize: '12px', opacity: 0.5, position: 'absolute', bottom: `${((wh > 700) ? 45 : 0)}px` }} >ps: 之后也可以再添加哦</div>

    </div>
  );
})

export default Wishes;




