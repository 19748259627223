import Lottie from 'lottie-react';
import { animate, cubicBezier, linear } from 'popmotion';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Popup } from 'react-vant';
import { musicData } from './beans/Constrants';
import SoundBtn from './components/SoundBtn';
import XSDataContext from './context/XSDataContext';
import XSMusicContext from './context/XSMusicContext';
import './Create.css';
import imgBack from './images/icon_back.png';
import imgClose from './images/icon_close.png';
import imgMineEdit from './images/icon_mine_edit.png';
import imgMineMusic from './images/icon_mine_music.png';
import imgMineMusicMute from './images/icon_mine_music_mute.png';
import imgQuote from './images/icon_quote.png';
import imgRemake from './images/icon_remake.png';
import imgShare from './images/icon_share.png';
import imgShareSmall from './images/img_invite_small.png';
import AutoScrollList from './MessageAutoScrollList';
import './Mine.css';
import Share from './Share';
import TombstoneComp from './TombstoneComp';
import { XsHttpCient } from './util/HttpUtil';
import { doClose } from './util/NativeBridge';
import { notify } from './util/NotifyUtil';
import WishesComp from './WishesComp';
import { info } from 'console';
import { xslog } from './util/XSLog';
import { inAnimation } from './util/CommonUtil';

function Mine() {
  const { statusBarHeight, inApp, user, ghostLottie } = React.useContext(XSDataContext);
  const { musicPlayerRef } = React.useContext(XSMusicContext);
  const titleBarHeight = statusBarHeight + 31
  const [showContent, setShowContent] = useState("")
  const params = useParams();
  const [showShare, setShowShare] = useState(false);  
  const [marqeeClass, setMarqeeClass] = useState('');

  const [showWishes, setShowWishes] = useState(false);
  const [remake, setShowRemake] = useState(false);

  const [currentAudio, setCurrentAudio] = useState(null);
  const [musicPlaying, setMusicPlaying] = useState(false)

  const [contentText, setContentText] = useState(null);

  const [showBtn, setShowBtn] = useState(false)
  const rootRef = useRef(null);
  const lottieRef = useRef(null);
  const wishesCompRef = useRef(null);
  const navigate = useNavigate();
  const [detail, setDetail] = useState({
    "avatar": "",
    "birthday": 0,
    "createTime": 0,
    "dieday": 0,
    "epitaph": "",
    "skin": -1,
    "id": 0,
    "bgm": -1,
    "message": 0,
    "name": "",
    "offerings": 0,
    "reason": "",
    "updateTime": 0,
    "visitCount": 0,
    "sex": -1,
    "lastwish": [],
    "hasNewWish": false
  })

  const [offerings, setOfferings] = useState([])

  let interval;

  useEffect(() => {
    if (contentText) {
      let index = 0;
      interval = setInterval(() => {
        setShowContent((prev) => prev + contentText[index++]);
        if (index >= contentText.length - 1) {
          clearInterval(interval);
          setShowBtn(true)
        }
      }, 50); // 控制每个字符出现的速度
    }
    return () => clearInterval(interval);
  }, [contentText]);

  useEffect(() => {
    const handlePlay = () => {
      setMusicPlaying(true);
    };
    if (currentAudio) {
      currentAudio.addEventListener('play', handlePlay);
      currentAudio.loop = true;
      currentAudio.play().catch(() => {
        setMusicPlaying(false);
      });
    }
    return () => {
      if (currentAudio) {
        currentAudio.removeEventListener('play', handlePlay);
        currentAudio.pause();
      }
    };
  }, [currentAudio]);

  useEffect(() => {
    if (showWishes) {
      window.onNativeBack = () => {
        xslog('onNativeBack 1', showWishes, wishesCompRef.current)
        if (wishesCompRef.current) {
          wishesCompRef.current.onBack();
          return true;
        } else {
          return false;
        }
      }
    } else {
      window.onNativeBack = () => {
        xslog('onNativeBack 2', showWishes, wishesCompRef.current)

        return false;
      }
    }
  }, [showWishes]);



  useEffect(() => {
    xslog('Mine onShow')


    window.shareFinish = () => {
      setShowShare(false)
    }
    if (params['fromcreate'] === '1') {
    } else {
      if (rootRef.current) {
        rootRef.current.style.opacity = 0;
      }
    }
    loadData()
    loadOfferings()
    return () => {
      if (currentAudio) {
        currentAudio.pause();
      }
    };
  }, [])
  const tipsContent = `记得邀请朋友们来看看你\n他们也许会给你送些意想不到供品\n跟你说些平时不会说的话\n说不定...还能实现你的愿望`
  const ghostTransY = 180
  const enterAnimation = () => {
    // contentRef.current.style.opacity = 0;
    rootRef.current.style.opacity = 0;

    animate({
      from: 0,
      to: 1,
      ease: linear,
      duration: 1000,
      onUpdate: (progress) => {
        rootRef.current.style.opacity = progress;
      },
      onComplete: () => {
        if (detail.message <= 0) {
          setTimeout(() => {

            if (lottieRef.current) {
              lottieRef.current.style.transform = `translate(${ghostTransY}px,0px)`;
            }

            setTimeout(() => {
              animate({
                from: 0,
                to: 1,
                ease: cubicBezier(0, 0, 0.45, 0.99),
                duration: 800,
                onUpdate: (progress) => {
                  if (lottieRef.current) {
                    lottieRef.current.style.transform = `translate(${ghostTransY - ghostTransY * progress}px,0px)`;
                  }
                },
                onComplete: () => {

                }
              })
            }, 800)

            setContentText(tipsContent);
          }, 200)
        }


      }
    })

  }

  const loadData = () => {
    XsHttpCient.get(`funeral/detail?uidStr=${user.uid}`).then((res) => {
      // res.data.data.hasNewWish = true;
      setDetail(res.data.data)
      const newAudio = new Audio(musicData[res.data.data.bgm].url);
      newAudio.loop = true;
      musicPlayerRef.current = newAudio;
      setCurrentAudio(newAudio);
      if (params['fromcreate'] === '1') {
        enterAnimation()
      } else {
        if (lottieRef.current) {
          lottieRef.current.style.transform = `translate(0px,0px)`;
        }
        setShowContent(tipsContent)
        setShowBtn(true)
        if (rootRef.current) {
          inAnimation(rootRef.current, () => { })
        }
        // setContentText(tipsContent);
      }

      setMarqeeClass('marquee')
      // setMusicPlaying(true)

    }).catch((error) => {
      notify(error.msg)
    });
  }

  const loadOfferings = () => {
    XsHttpCient.get(`funeral/getOfferings?uidStr=${user.uid}`).then((res) => {
      setOfferings(res.data.data)
    }).catch((error) => {

    });
  }

  const doremake = () => {
    XsHttpCient.post(`funeral/delete`, {}).then((res) => {
      setShowRemake(false)
      navigate('/')
    }).catch((error) => {
      notify(error.msg)
      setShowRemake(false)
    });

  }

  const [shareImageUrl, setShareImageUrl] = useState({normalUrl:'',largeUrl:''});

  const clickShowShare = () => {
    const doit = () => {
      setShowShare(true)
    }
    if (shareImageUrl !== null && shareImageUrl.normalUrl!==null && shareImageUrl.normalUrl!=='') {
      doit()
    } else {
      XsHttpCient.get('funeral/getShareImgUrl').then((res) => {

        setShareImageUrl(res.data.data)
        doit()
      }).catch((error) => {
        notify(error.msg)
      });
    }
  }

  const btnRef = useRef(null);
  const infoRef = useRef(null);
  useEffect(() => {
    if (showBtn) {
      if (params['fromcreate'] === '1') {
        setTimeout(() => {
          animate({
            from: 0,
            to: 1,
            ease: linear,
            duration: 300,
            onUpdate: (progress) => {
              if (btnRef.current) {
                btnRef.current.style.opacity = progress
              }
              if (infoRef.current) {
                infoRef.current.style.opacity = progress
              }
            }
          })
        }, 200)
      } else {
        if (btnRef.current) {
          btnRef.current.style.opacity = 1
        }
        if (infoRef.current) {
          infoRef.current.style.opacity = 1
        }
      }
    } else {
      if (btnRef.current) {
        btnRef.current.style.opacity = 0
      }
      if (infoRef.current) {
        infoRef.current.style.opacity = 0
      }
    }
  }, [showBtn]);



  return (
    <div ref={rootRef} className='Main' style={{ display: 'flex', position: 'relative', opacity: 0,overflow:'hidden' }}>
      {detail.skin != -1 && <div style={{ width: '100%', position: 'absolute', top: '0px', zIndex: 98, filter: showShare || showWishes ? 'blur(10px)' : '' }}>
        <TombstoneComp edit={false} detail={{ ...detail }} />
      </div>}

      <div style={{ display: 'flex', width: '100vw', height: '100%', flexDirection: 'column', alignItems: 'center', filter: showShare || showWishes ? 'blur(10px)' : '', zIndex: 99 }}>







        <div style={{
          width: '100%',
          display: 'flex', flexDirection: 'row',
          alignItems: 'center', justifyContent: 'center',
          alignContent: 'center',
          position: 'absolute', zIndex: 100,
          top: statusBarHeight > 0 ? statusBarHeight + 5 : 21
        }} >


          <div onClick={() => {
            if (musicPlaying) {
              if (currentAudio) {
                currentAudio.pause()
              }
              setMusicPlaying(false)
            } else {
              if (currentAudio) {
                currentAudio.play()
              }
              setMusicPlaying(true)
            }
          }} style={{
            borderRadius: '5px',
            display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0  0 0 20px ', justifyContent: 'center', width: '113px', height: '24px',
            border: '1px solid rgba(255, 255, 255, 0.3)', backgroundColor: 'rgba(255, 255, 255, 0.4)'
          }}>

            <img src={musicPlaying ? imgMineMusic : imgMineMusicMute} style={{ width: '14px', height: '14px', margin: '0 5px 0 5px' }} />
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '76px' }}>
              <div className={`${marqeeClass}`} style={{ whiteSpace: 'nowrap', fontSize: '11px', color: '#424242' }}>{detail.bgm >= 0 ? `葬礼bgm:${musicData[detail.bgm].name}` : ''}</div></div>


          </div>

          <div style={{ flexGrow: 1 }} />
          <img src={imgRemake} style={{ width: '82px', height: '25px', margin: '0  15px 0 0' }} onClick={() => { setShowRemake(true) }} />
          <img onClick={() => {
            if (currentAudio) {
              currentAudio.pause();
            }
            doClose()
          }} src={imgClose} style={{ width: '24px', height: '24px', margin: '0 20px 0 0' }} />

        </div>






        {detail.message > 0 ?
          <>

            <AutoScrollList mine={true} userlabel={'你'} style={{ marginTop: `${398 - (inApp ? 0 : 50)}px`, flexGrow: 1 }} data={offerings} />
            <div ref={infoRef} style={{ opacity: 0, marginTop: '10px', fontSize: '13px', color: '#424242' }}>
              {detail.visitCount}人来过 ｜ <span onClick={() => { setShowWishes(true) }} style={{ color: '#CCAC74', textDecoration: 'underline' }}>查看遗愿清单</span> ｜ 收到供品{detail.offerings}
            </div>
            <SoundBtn onClick={() => { clickShowShare() }} style={{ width: '159px', height: '22px', marginBottom: '38px', marginTop: '10px' }}>
              <img src={imgShareSmall} style={{ width: '159px', height: '22px' }} />
            </SoundBtn>
          </>
          :
          <><div style={{ flexGrow: 1 }}> </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', marginBottom: '118px' }} >
              <img src={imgQuote} style={{ width: '24px', height: '24px', marginLeft: '18px' }} />
              <span style={{ marginTop: '12px', height: '115px', fontSize: '17px', lineHeight: '32.53px', whiteSpace: 'pre-line' }}>
                {showContent}
              </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '56px', position: 'relative', width: '100%' }}>

              {
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                  <div ref={infoRef} style={{ opacity: 0, marginTop: '10px', marginBottom: '10px', fontSize: '13px', color: '#424242' }}>
                    {detail.visitCount}人来过 ｜ <span onClick={() => { setShowWishes(true) }} style={{ color: '#CCAC74', textDecoration: 'underline' }}>查看遗愿清单</span> ｜ 收到供品{detail.offerings}
                  </div>
                  <SoundBtn onClick={() => { clickShowShare() }} style={{ width: '310px', height: '48px', }}>
                    <div ref={btnRef} className='Btn' style={{ width: '100%', height: '100%', opacity: 0 }}>
                      <span>邀请朋友参加我的葬礼</span>
                      <img src={imgShare} style={{ width: '30px', height: '30px' }} />
                    </div>
                  </SoundBtn></div>}
              <div ref={lottieRef} style={{
                pointerEvents: 'none', transform: 'translate(0px,1000px)',
                width: 180, height: 180, overflow: 'hidden',
                display: 'flex', justifyContent: 'center', position: 'absolute', top: -160, right: 0,
              }}>
                {ghostLottie && <Lottie style={{ marginRight: -36 }} animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
              </div>
            </div>

          </>}






      </div>


      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0)' }} position='bottom' style={{
        backgroundColor: 'transparent',
      }} visible={showShare} onClose={() => setShowShare(false)}>
        <Share shareImageUrl={shareImageUrl} detail={detail} onClose={() => { setShowShare(false) }} />

      </Popup>

      {
        showWishes ? <div style={{ display: 'flex', position: 'absolute', zIndex: 100, flexDirection: 'column', width: '100vw', height: '100%' }}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: statusBarHeight, marginBottom: '20px'
          }} >
            <img src={imgBack} style={{ width: '24px', height: '24px', margin: '0 0 0 20px' }} onClick={() => {
              if (wishesCompRef.current) {
                wishesCompRef.current.onBack()
              } else {
                setShowWishes(false)
              }
            }} />
            <span style={{ fontSize: '17px' }}>我的遗愿清单</span>
            <div style={{ width: '24px', height: '24px', margin: '0  20px 0 0' }}> </div>
          </div>
          <WishesComp ref={wishesCompRef} visitor={false} sex={detail.sex} wishes={detail.lastwish} onFinish={(update) => {
            if (update) {
              loadData()
            }
            setShowWishes(false)
          }}></WishesComp>
        </div> : <></>
      }


      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} style={{
        backgroundColor: 'transparent',
      }} visible={remake} onClose={() => setShowRemake(false)}>
        <div className='CommonDialog' style={{ width: '315px', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ width: '280px', margin: '20px 0 0 0 ', lineHeight: '30px', textAlign: 'center', fontFamily: 'ipix' }}>重新制作会清空当前的所有数据
            <br />确定吗?</span>

          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '22px 0 20px 0', alignItems: 'center', justifyContent: 'center' }}>
            <div onClick={() => { setShowRemake(false) }} className='Btn' style={{ width: '112px', height: '42px', fontSize: '17px', fontFamily: 'ipix' }}>取消</div>
            <div onClick={() => { doremake(); }} className='Btn' style={{ width: '112px', height: '42px', marginLeft: '23px', fontSize: '17px', fontFamily: 'ipix' }}>确定</div>
          </div>
        </div>

      </Popup>

    </div>
  );
}

export default Mine;

