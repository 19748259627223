import React from 'react';
import { Environment } from '../util/environment';
import { UserInfo, WebUserInfo } from '../beans/DataTypes';


interface XSDataContextType {
    statusBarHeight: number;
    setStatusBarHeight: (number) => void;
    keyboardEvent: { show: boolean, height: number };
    onKeyBoardShow: (show: boolean, height: number) => void;
    environment: Environment;
    inApp: boolean;
    user: UserInfo;
    setUser: (UserInfo) => void;
    webUser: WebUserInfo;
    setWebUser: (WebUserInfo) => void;
    ghostLottie: {};
    makeoffringLottie: {};
    devTest:boolean;
}


const XSDataContext = React.createContext<XSDataContextType>({
    statusBarHeight: 52,
    setStatusBarHeight: (number) => { },
    keyboardEvent: { show: false, height: 0 },
    onKeyBoardShow: (show: boolean, height: number) => { },
    environment: Environment.MOBILE_BROWSER,
    inApp: false,
    user: null,
    setUser: (UserInfo) => { },
    webUser: null,
    setWebUser: (WebUserInfo) => { },
    ghostLottie: {},
    makeoffringLottie: {},
    devTest:false,
});

export default XSDataContext;