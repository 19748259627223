// ThemeProvider.js
import React, { useEffect, useRef, useState } from 'react';
import XSDataContext from './XSDataContext';
import { Environment } from '../util/environment';
import { getStatusBarHeight } from '../util/NativeBridge';
import { xslog } from '../util/XSLog';
import { UserInfo, WebUserInfo } from '../beans/DataTypes';
import { LOCALSTORAGE_KEY } from '../beans/Constrants';
import { XsHttpCient } from '../util/HttpUtil';
import axios from 'axios';

interface XSDataProviderProps {
    children: any;
}

export const XSDataProvider: React.FC<XSDataProviderProps> = ({ children }) => {

    const [statusBarHeight, setStatusBarHeight] = useState(0);

    const [user, setUser] = useState<UserInfo>(null);

    const [webUser, setWebUser] = useState<WebUserInfo>(null);

    const [keyboardEvent, setKeyboardEvent] = useState({ show: false, height: 0 });

    const onKeyBoardShow = (_show: boolean, _height: number) => {
        setKeyboardEvent({
            show: _show,
            height: _height / (window.devicePixelRatio || 1)
        })
    }
    const environment = window.appEnvironment as Environment;

    const [ghostLottie, setghostLottie] = useState(null);

    const [makeoffringLottie, setmakeoffringLottie] = useState(null);
    

    const inApp = environment == Environment.ANDROID_APP || environment == Environment.IOS_APP

    const devTest = false && process.env.NODE_ENV !== 'production';


    useEffect(() => {
        xslog('[[[ XSDataProvider start ]]]')
        if (!inApp) {
            xslog('xsdataprovider not in app')
            let t = localStorage.getItem(LOCALSTORAGE_KEY.webuser);
            xslog('xsdataprovider LOCALSTORAGE_KEY.webuser ' + t);
            if (t && t !== '') {
                let u = JSON.parse(t)
                setWebUser(u)

                xslog('xsdataprovider setWebUser done');
            }
        }

            try {
              axios.get('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/lotties/ghost.json').then((res) => {
               setghostLottie(res.data)
              }).catch((error) => {
              });
              axios.get('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/funeral/lotties/makeoffring.json').then((res) => {
                setmakeoffringLottie(res.data)
               }).catch((error) => {
               });
            
            } catch (error) {
             xslog(error);
            }
         

            xslog('[[[ XSDataProvider end ]]]')
        return () => { };
    }, []);
    return (

        <XSDataContext.Provider value={{
            statusBarHeight, setStatusBarHeight, keyboardEvent, onKeyBoardShow,
            environment, inApp, user, setUser, webUser, setWebUser, 
            ghostLottie,makeoffringLottie,devTest
        }}>
            {children}
        </XSDataContext.Provider>
    );
};