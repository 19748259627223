import axios from "axios";
import { notify } from "./NotifyUtil";
import { xslog } from "./XSLog";
import { API_SERVER } from "../beans/Environment";

let code = ""
const client = axios.create({
  baseURL: API_SERVER,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  //   // other headers if necessary
  // }
});

// Add a request interceptor
client.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers['x-token'] = window.xsxtoken;

  config.headers['x-uuid'] = window.xsuuid;



  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
client.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // raidlog("interceptors response  " + response);
  if (response.data.code != 0) {
    xslog('网络错误')
    xslog(response)
    return Promise.reject({ code: response.data.code, msg: response.data.msg });
  }
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  xslog("interceptors response error " + error);
  // notify("网络错误");
  return Promise.reject(error);
});

// const fetchGet = (url: string, params: any) => {
//   // let list = [];
//   // for (let key in params) {
//   //   let str = `${key}=${params[key]}`
//   //   list.push(str);
//   // }
//   // const data = list.join("&");
//   // let allUrl = `${baseURL}${url}?${data}`
//   // return fetch(allUrl).then((res: Response) => {
//   //   return res.json();
//   // }).catch((err => {
//   //   console.error(err)
//   // }))
// }

// const fetchPost = (url: string, params: any) => {
//   client.post(url, { params: params }).then(res => {
//     res.
//     return JSON.parse(res);
//   }).catch((err => {
//     console.error(err)
//   }))
//   // let allUrl = `${baseURL}${url}`
//   // return fetch(allUrl,
//   //   {
//   //     method: 'POST',
//   //     headers: { 'Content-Type': 'application/json;charset=utf-8' },
//   //     body: JSON.stringify(params)
//   //   }).then((res: Response) => {
//   //     return res.json();
//   //   }).catch((err => {
//   //     console.error(err)
//   //   }))
// }


// const fetchFilePost = (url: string, params: FormData) => {
//   // let allUrl = `${baseURL}${url}`
//   // return fetch(allUrl,
//   //   {
//   //     method: 'POST',
//   //     // headers: { 'Content-Type': 'application/json;charset=utf-8' },
//   //     body: params
//   //   }).then((res: Response) => {
//   //     return res.json();
//   //   }).catch((err => {
//   //     console.error(err)
//   //   }))
// }
//export { }
export { client as XsHttpCient }

export enum ContentAuditScene {
  //0-上传头像；1-登记姓名；2-登记原因；3-墓志铭；4-遗愿清单；5-贡品名字；6-贡品留言；7-个人信息名字 ; 8-关系

  avatar = 0, name = 1, reason = 2, words = 3, list = 4,offername = 5,offerwords = 6,infoName = 7,relation = 8
}

export const ContentAudit = (scene: ContentAuditScene, content: string, succ: () => void, error: (err) => void) => {
  client.post('funeral/content/audit', { "scene": scene, "content": content }).then((res) => {
    succ()
  }).catch((err) => {
    error(err)
  });
}


export const ERROR_CODE = { funeral_not_exist: 110001 }
