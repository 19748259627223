import React from 'react';
import { CreateInfo } from '../beans/DataTypes';


interface XSMusicContextType {
    bgPlayerRef:React.MutableRefObject<any>;
    musicPlayerRef:React.MutableRefObject<any>;
    startPlayBgMusic:()=>void;
    pauseOrPlayMusic:(pause:boolean)=>void;
    playClickSound:()=>void;
    playClickTombSound:()=>void;
    playClickMusicSound:()=>void;
}


const XSMusicContext = React.createContext<XSMusicContextType>({
    bgPlayerRef:null,
    musicPlayerRef:null,
    pauseOrPlayMusic:(pause:boolean)=>{},
    startPlayBgMusic:()=>{},
    playClickSound:()=>{},
    playClickTombSound:()=>{},
    playClickMusicSound:()=>{},
});

export default XSMusicContext;