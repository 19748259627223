import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './Welcome.css';
import XSDataContext from './context/XSDataContext';
import imgQuote from './images/icon_quote.png';
import imgLogo1 from './images/img_logo_1.png';
import imgLogoText from './images/img_logo_text.png';


import SoundBtn from './components/SoundBtn';
import { XsHttpCient } from './util/HttpUtil';
import { notify } from './util/NotifyUtil';
import { xslog } from './util/XSLog';


import Lottie from 'lottie-react';
import { animate, cubicBezier, linear } from 'popmotion';
import { outAnimation } from './util/CommonUtil';

interface WelcomeProps {
  goNext: () => void;
}
const Welcome = forwardRef<any, WelcomeProps>((props, ref) => {
  const { statusBarHeight, ghostLottie } = React.useContext(XSDataContext);
  const [showContent, setShowContent] = useState("")

  const [showBtn, setShowBtn] = useState(false)

  const [count, setCount] = useState(0)

  const [contentText, setContentText] = useState<String>()


  const lottieRef = useRef(null);
  const rootRef = useRef(null);
  const logoText = useRef(null);
  const ghostBg = useRef(null);
  const contentGroup = useRef(null);
  let interval

  useEffect(() => {
    if (contentText) {
      let index = 0;
      interval = setInterval(() => {
        setShowContent((prev) => prev + contentText[index++]);
        if (index >= contentText.length - 1) {
          clearInterval(interval);
          setShowBtn(true)
        }
      }, 50); // 控制每个字符出现的速度
    }
    return () => { if (interval) { clearInterval(interval) } };
  }, [contentText]);


  useEffect(() => {
    enterAnimation();
    return () => { };
  }, []);

  const btnRef = useRef(null);
  useEffect(() => {
    if (showBtn) {
      setTimeout(() => {
        animate({
          from: 0,
          to: 1,
          ease: linear,
          duration: 300,
          onUpdate: (progress) => {
            if (btnRef.current) {
              btnRef.current.style.opacity = progress
            }
          }
        })
      }, 200)
    } else {
      if (btnRef.current) {
        btnRef.current.style.opacity = 0
      }
    }
  }, [showBtn]);



  const enterAnimation = () => {
    logoText.current.style.opacity = 0;
    ghostBg.current.style.opacity = 0;
    contentGroup.current.style.opacity = 0;
    const ghostTransY = 221
    lottieRef.current.style.transform = `translate(${ghostTransY}px,0px)`;
    let c = 0
    XsHttpCient.get('funeral/getTotalCount').then((res) => {
      xslog(res);
      c = res.data.data;

    }).catch((error) => {
      notify(error.msg);
    });
    animate({
      from: 0,
      to: 1,
      ease: linear,
      duration: 500,
      onUpdate: (progress) => {
        logoText.current.style.opacity = progress;
        ghostBg.current.style.opacity = progress;
      },
      onComplete: () => {
        setTimeout(() => {
          animate({
            from: 0,
            to: 1,
            ease: cubicBezier(0, 0, 0.42, 1),
            duration: 1500,
            onUpdate: (progress) => {
              lottieRef.current.style.transform = `translate(${ghostTransY - ghostTransY * progress}px,0px)`;
            },
            onComplete: () => {
              setTimeout(() => {
                setContentText(`欢迎来到 赛博墓园\n你是第 ${c} 个为自己办葬礼的人\n先登记一下吧`)
                contentGroup.current.style.opacity = 1;
              }, 200)
            }
          })
        }, 200)

      }
    })

  }

  return (
    <div ref={rootRef} style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden',position:'relative'}}>
      <img ref={logoText} src={imgLogoText} style={{ width: `${window.innerWidth}px`,height:`${708*window.innerWidth/1179}px`, margin: `${statusBarHeight}px 0 0 0` }} />
      <div style={{ position: 'relative' }}>
        <img ref={ghostBg} src={imgLogo1} style={{ width: `${window.innerWidth}px`,height:`${345*window.innerWidth/1179}px`, margin: '88px 0 0 0' }} />
        <div ref={lottieRef} style={{
          pointerEvents: 'none',
          width: 227, height: 227,
          display: 'flex', justifyContent: 'center', position: 'absolute', bottom: 14, right: -26,
        }}>
          {ghostLottie && <Lottie animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
        </div>
      </div>
      <div style={{ flexGrow: 1 }} />
      <div ref={contentGroup} style={{ display: 'flex', flexDirection: 'row' ,opacity:0}} className='TextContent'>

        <img src={imgQuote} style={{ width: '24px', height: '24px', marginLeft: '20px' }} />
        <p style={{ marginTop: '12px', fontSize: '19px', lineHeight: '40px', height: '106px', whiteSpace: 'pre-line' }}>
          {showContent}
        </p>

      </div>
      {showBtn ?
        <SoundBtn onClick={() => { outAnimation(rootRef.current, () => { props.goNext() }) }} style={{  alignSelf: 'center', position: 'absolute', bottom: `${((window.innerHeight > 700) ? 57 : 5)}px` }}>
          <div ref={btnRef} style={{ opacity: 0, width: '313px', height: '48px', alignSelf: 'center' }} className="Btn">登记</div>
        </SoundBtn> : <></>}

    </div>
  );
})

export default Welcome;


