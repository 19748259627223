import React, { forwardRef, useEffect, useState } from 'react';
import './Tombstone.css';


import { stoneData, StoneDataKey } from './beans/Constrants';
import imgRefresh from './images/icon_refresh.png';
import { formatDateByFormat, replaceHttpsUrl } from './util/CommonUtil';
import { xslog } from './util/XSLog';
import { notify } from './util/NotifyUtil';

interface TombstoneCompProps {
  edit: boolean;
  detail: {
    skin: number;
    epitaph: string;
    birthday: number;
    dieday: number;
    name: string;
    avatar: string;
  };
  editEpitaph?: string;
  setEditEpitaph?: React.Dispatch<React.SetStateAction<string>>;
  clickSelectAvatar?: () => void;
}
const TombstoneComp = forwardRef<any, TombstoneCompProps>((props, ref) => {

  class ViewConfig {
    width: number;
    height: number;
    avatarStyle: React.CSSProperties;
    changeAvatarStyle: React.CSSProperties;
    epitaphStyle: React.CSSProperties;
    nameStyle: React.CSSProperties;
    dateStyle: React.CSSProperties;
    brandbarStyle?: React.CSSProperties;
  }


  const [viewConfig, setViewConfig] = useState(null)

  useEffect(() => {
    // notify(`window.innerWidth ${window.innerWidth}`)
    const ratio = window.innerWidth / 393
    let vc = new ViewConfig();
    vc.width = 393 * ratio;
    vc.height = 760 * ratio;
    if (props.detail.skin == StoneDataKey.brand) {
      vc.epitaphStyle = {
        fontFamily: 'aliph',
        fontSize: `${25 * ratio}px`,
        transform: 'rotate(-2.5deg)',
        position: 'absolute',
        width: `${296 * ratio}px`,
        top: `${110* ratio}px`,
        left: `${54 * ratio}px`,
        color: '#FFFFFF',
        textShadow: ` ${0.5 * ratio}px -${1 * ratio}px 0px #2B4571`,
        textAlign: 'center',
      };
      vc.avatarStyle = {
        width: `${17 * ratio}px`, height: `${17 * ratio}px`, border: `${1 * ratio}px solid #0A59C2`, borderRadius: '50%', margin: `${4 * ratio}px ${4 * ratio}px ${4 * ratio}px 0px`
      };
      vc.changeAvatarStyle = {
        width: `${14 * ratio}px`, height: `${14 * ratio}px`, position: 'absolute', bottom: '1px', right: `${-0.27 * ratio}px`
      };
      vc.brandbarStyle = {
        top: `${152 * ratio}px`,
        width: `${187 * ratio}px`,
        left: `${109 * ratio}px`,
        transform: 'rotate(-2.5deg)',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center'
      };
      vc.nameStyle = {
        fontFamily: 'aliph',
        fontSize: `${12 * ratio}px`,
        color: '#0657C0',
        textAlign: 'center'
      };
      vc.dateStyle = {
        marginLeft: `${3 * ratio}px`,
        fontFamily: 'aliph',
        fontSize: `${12 * ratio}px`,
        color: '#0657C0',
        textAlign: 'center'
      };
    } else if (props.detail.skin == StoneDataKey.apple) {
      vc.epitaphStyle = {
        fontFamily: 'aliph',
        fontSize: `${17 * ratio}px`,
        transform: 'skew(0deg, 1deg)',
        width: `${100 * ratio}px`,
        position: 'absolute',
        height: 92 * ratio,
        top: 125 * ratio,
        left: `${150 * ratio}px`,
        color: '#FFFFFF',
        textShadow: ` ${0.5 * ratio}px -${0.3 * ratio}px ${0.6 * ratio}px  #3D2934`,
        textAlign: 'center',
        wordBreak: 'break-word'
      };
      vc.avatarStyle = {
        width: `${22 * ratio}px`, height: `${22 * ratio}px`, border: `${1 * ratio}px solid #776575`, borderRadius: '50%', margin: `0 ${2 * ratio}px 0 ${4 * ratio}px`
      };
      vc.changeAvatarStyle = {
        width: `${14 * ratio}px`, height: `${14 * ratio}px`, position: 'absolute', bottom: `${0.54 * ratio}px`, right: `-${0.8 * ratio}px`
      };
      vc.nameStyle = {
        fontFamily: 'aliph',
        fontSize: `${9 * ratio}px`,
        transform: 'skew(0deg, -4deg)',
        position: 'absolute',
        top: `${269 * ratio}px`,
        right: `${149 * ratio}px`,
        color: '#FFFFFF',
        textShadow: ` ${0.4 * ratio}px -${0.5 * ratio}px ${0.4 * ratio}px #3D2934`,
        textAlign: 'center'
      };
      vc.dateStyle = {
        fontFamily: 'aliph',
        fontSize: `${8 * ratio}px`,
        transform: 'skew(0deg, -4deg)',
        position: 'absolute',
        top: `${294 * ratio}px`,
        right: `${149 * ratio}px`,
        color: '#FFFFFF',
        textShadow: `${0.4 * ratio}px -${0.5 * ratio}px ${0.4 * ratio}px #3D2934`,
        textAlign: 'center'
      };
    } else if (props.detail.skin == StoneDataKey.kfc) {
      vc.epitaphStyle = {
        fontFamily: 'aliph',
        fontSize: `${17 * ratio}px`,
        transform: 'skew(0deg, 2deg)',
        width: `${100 * ratio}px`,
        position: 'absolute',
        height: 92 * ratio,
        top: 119 * ratio,
        left: `${156 * ratio}px`,
        color: '#FFFFFF',
        textShadow: `${0.5 * ratio}px -${0.3 * ratio}px ${0.6 * ratio}px  #9A1337`,
        textAlign: 'center',
        wordBreak: 'break-word'
      };
      vc.avatarStyle = {
        width: `${22 * ratio}px`, height: `${22 * ratio}px`, border: `${1 * ratio}px solid #FCBDA9`, borderRadius: '50%', margin: `0 ${2 * ratio}px 0 ${3 * ratio}px`
      };
      vc.changeAvatarStyle = {
        width: `${14 * ratio}px`, height: `${14 * ratio}px`, position: 'absolute', bottom: `${0 * ratio}px`, right: `${0 * ratio}px`
      };
      vc.nameStyle = {
        fontFamily: 'aliph',
        fontSize: `${10 * ratio}px`,
        transform: 'skew(0deg, 0deg)',
        position: 'absolute',
        top: `${227 * ratio}px`,
        right: `${140 * ratio}px`,
        color: '#FFFFFF',
        textShadow: ` ${0.4 * ratio}px -${0.5 * ratio}px ${0.4 * ratio}px #9A1337`,
        textAlign: 'center'
      };
      vc.dateStyle = {
        fontFamily: 'aliph',
        fontSize: `${9 * ratio}px`,
        transform: 'skew(0deg, 0deg)',
        position: 'absolute',
        top: `${252 * ratio}px`,
        right: `${141 * ratio}px`,
        color: '#FFFFFF',
        textShadow: ` ${0.4 * ratio}px -${0.5 * ratio}px ${0.4 * ratio}px #9A1337`,
        textAlign: 'center'
      };
    } else if (props.detail.skin == StoneDataKey.normal) {
      vc.epitaphStyle = {
        fontFamily: 'aliph',
        fontSize: `${17 * ratio}px`,
        transform: 'skew(0deg, -5deg)',
        width: `${101 * ratio}px`,
        position: 'absolute',
        height: 92 * ratio,
        top: 151 * ratio,
        left: `${156 * ratio}px`,
        color: '#949494',
        textShadow: ` ${0.5 * ratio}px -${0.5 * ratio}px ${0.4 * ratio}px #434343`,
        textAlign: 'center',
        wordBreak: 'break-word'
      };
      vc.avatarStyle = {
        transform: 'skew(0deg, -3deg)',
        width: `${35 * ratio}px`,
        height: `${35 * ratio}px`,
        position: 'absolute',
        top: `${99 * ratio}px`,
        left: `${190 * ratio}px`,
        border: `${1 * ratio}px solid #949283`,
        borderRadius: '50%'
      };
      vc.changeAvatarStyle = {
        width: `${14 * ratio}px`, height: `${14 * ratio}px`,
        position: 'absolute',
        top: `${123 * ratio}px`,
        left: `${214 * ratio}px`,
      };
      vc.nameStyle = {
        fontFamily: 'aliph',
        fontSize: `${9 * ratio}px`,
        transform: 'skew(-1deg, -9deg)',
        position: 'absolute',
        top: `${269 * ratio}px`,
        right: `${134 * ratio}px`,
        color: '#949494',
        textShadow: ` ${0.2 * ratio}px -${0.2 * ratio}px ${0.2 * ratio}px #1E1E1D`,
        textAlign: 'center'
      };
      vc.dateStyle = {
        fontFamily: 'aliph',
        fontSize: `${8 * ratio}px`,
        transform: 'skew(-1deg, -9deg)',
        position: 'absolute',
        top: `${283 * ratio}px`,
        right: `${133 * ratio}px`,
        color: '#949494',
        textShadow: `${0.2 * ratio}px -${0.2 * ratio}px ${0.2 * ratio}px #1E1E1D`,
        textAlign: 'center'
      };
    } else if (props.detail.skin == StoneDataKey.pink) {
      vc.epitaphStyle = {
        fontFamily: 'aliph',
        fontSize: `${17 * ratio}px`,
        transform: 'skew(2deg, -7deg)',
        width: `${96 * ratio}px`,
        height: 92 * ratio,
        position: 'absolute',
        top: 149 * ratio,
        left: `${169 * ratio}px`,
        color: '#fff',
        textShadow: ` ${0.5 * ratio}px -${0.3 * ratio}px ${0.6 * ratio}px #993751`,
        textAlign: 'center',
        wordBreak: 'break-word'
      };

      vc.avatarStyle = {
        transform: 'skew(1deg, -3deg)',
        width: `${35 * ratio}px`,
        height: `${35 * ratio}px`,
        position: 'absolute',
        top: `${105 * ratio}px`,
        left: `${195 * ratio}px`,
        border: `${1 * ratio}px solid #EFD7FF`,
        borderRadius: '50%'
      };

      vc.changeAvatarStyle = {
        width: `${14 * ratio}px`, height: `${14 * ratio}px`,
        position: 'absolute',
        top: `${126 * ratio}px`,
        left: `${220 * ratio}px`,
      };
      vc.nameStyle = {
        fontFamily: 'aliph',
        fontSize: `${10 * ratio}px`,
        transform: 'skew(0deg, -9deg)',
        position: 'absolute',
        top: `${266 * ratio}px`,
        right: `${130 * ratio}px`,
        color: '#fff',
        textShadow: ` ${0.5 * ratio}px -${0.5 * ratio}px ${0.4 * ratio}px  #993751`,
        textAlign: 'center'
      };
      vc.dateStyle = {
        fontFamily: 'aliph',
        fontSize: `${9 * ratio}px`,
        transform: 'skew(0deg, -9deg)',
        position: 'absolute',
        top: `${282 * ratio}px`,
        right: `${129 * ratio}px`,
        color: '#fff',
        textShadow: ` ${0.5 * ratio}px -${0.5 * ratio}px ${0.4 * ratio}px  #993751`,
        textAlign: 'center'
      };
    } else if (props.detail.skin == StoneDataKey.gold) {
      vc.epitaphStyle = {
        fontFamily: 'aliph',
        fontSize: `${18 * ratio}px`,
        transform: 'skew(1deg, -3deg)',
        width: `${96 * ratio}px`,
        position: 'absolute',
        height: 92 * ratio,
        top: 142 * ratio,
        left: `${156 * ratio}px`,
        color: '#D48647',
        textShadow: ` ${0.3 * ratio}px -${0.3 * ratio}px ${0.6 * ratio}px   #1E1E1D`,
        textAlign: 'center',
        wordBreak: 'break-word'
      };
      vc.avatarStyle = {
        transform: 'skew(0deg, -3deg)',
        width: `${35 * ratio}px`,
        height: `${35 * ratio}px`,
        position: 'absolute',
        top: `${99 * ratio}px`,
        left: `${189 * ratio}px`,
        border: `${1 * ratio}px solid #E5B86C`,
        borderRadius: '50%'
      };

      vc.changeAvatarStyle = {
        width: `${14 * ratio}px`, height: `${14 * ratio}px`,
        position: 'absolute',
        top: `${123 * ratio}px`,
        left: `${213 * ratio}px`,
      };
      vc.nameStyle = {
        fontFamily: 'aliph',
        fontSize: `${10 * ratio}px`,
        transform: 'skew(-4deg, -7deg)',
        position: 'absolute',
        top: `${267 * ratio}px`,
        right: `${147 * ratio}px`,
        color: '#D48647',
        textShadow: ` ${0.1 * ratio}px -${0.4 * ratio}px ${0.4 * ratio}px #1E1E1D`,
        textAlign: 'center'
      };
      vc.dateStyle = {
        fontFamily: 'aliph',
        fontSize: `${9 * ratio}px`,
        transform: 'skew(-4deg, -7deg)',
        position: 'absolute',
        top: `${282 * ratio}px`,
        right: `${148 * ratio}px`,
        color: '#D48647',
        textShadow: ` ${0.1 * ratio}px -${0.4 * ratio}px ${0.4 * ratio}px #1E1E1D`,
        textAlign: 'center'
      };
    }
    xslog("setViewConfig", vc);
    setViewConfig(vc);

    let c = props.editEpitaph;
    if (props.detail.skin == StoneDataKey.brand && c != null && c != undefined && c.length > 10) {
      c = c.substring(0, 10)
      props.setEditEpitaph(c);
    }

    return () => {

    };
  }, []);


  const defaulContent = () => {
    let c = props.editEpitaph;
    // if (props.detail.skin == StoneDataKey.brand && c != null && c != undefined && c.length > 10) {
    //   c = c.substring(0, 10)
    // }
    return c
  }



  const cusstyle = () => {
    if (viewConfig != null && viewConfig.epitaphStyle != undefined && viewConfig.epitaphStyle != null && viewConfig.epitaphStyle.color != undefined && viewConfig.epitaphStyle.textShadow != undefined) {
      return `
  .TsTa::placeholder {
     color: ${viewConfig.epitaphStyle.color};
     opacity:1;
      text-shadow: ${viewConfig.epitaphStyle.textShadow};
  }
  `;
    } else {
      return '';
    }
  }

  const epTextStyle = () => {
    let s = {
      ...viewConfig.epitaphStyle,
      border: '0px',
      borderRadius: '0px',
      margin: '0 0 0 0',
      padding: '0 0 0 0',
    };
    if(props.detail.skin != StoneDataKey.brand){
      let top = viewConfig.epitaphStyle.top-20;
      let height = viewConfig.epitaphStyle.height+40;
      s.top = top;
      s.height = height;
      // s.backgroundColor = '#FF0000';
      s.display = 'flex';
      s.justifyContent = 'center';
      s.alignItems = 'center';

    }
    return s;
  }

  if (viewConfig == null) {
    return (<></>);
  }
  return (
    <div style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center',
      width: `${viewConfig.width}px`, height: `${viewConfig.height}px`,
      backgroundImage: `url(${props.edit ? stoneData[props.detail.skin].eidtBg : stoneData[props.detail.skin].viewBg})`,
      backgroundSize: 'cover'
    }}>
      {props.detail.skin == -1 || viewConfig.width <= 0 ? <></> :
        <>
          <style>

            {cusstyle()}
          </style>

          {props.detail.skin != StoneDataKey.kfc && props.detail.skin != StoneDataKey.apple && props.detail.skin != StoneDataKey.brand ?
            <>
              <img onClick={() => { if (props.clickSelectAvatar) { props.clickSelectAvatar() } }} src={replaceHttpsUrl(props.detail.avatar)} style={{ ...viewConfig.avatarStyle }} />
              {props.edit && <img src={imgRefresh} style={{ ...viewConfig.changeAvatarStyle, pointerEvents: 'none' }} />}

            </>
            : <></>}

          {props.edit ?
            <textarea style={{
              ...viewConfig.epitaphStyle,
              backgroundColor:'transparent',
              border: '0px',
              borderRadius:'0px',
              margin:'0 0 0 0',
              padding:'0 0 0 0',
              overflow: 'hidden',
              height: `150px`,
            }} maxLength={props.detail.skin != StoneDataKey.brand ? 20 : 10} className='TsTa'
              placeholder='点击输入' value={defaulContent()} onChange={(e) => { props.setEditEpitaph(e.target.value) }}>
            </textarea>
            :
            <div style={epTextStyle()}>
              {props.detail.epitaph}
            </div>
          }
          {
            props.detail.skin == StoneDataKey.brand ?
              <div style={{ ...viewConfig.brandbarStyle }}>
                <div style={{ position: 'relative', display: 'flex' }}>
                  <img onClick={() => { if (props.clickSelectAvatar) { props.clickSelectAvatar() } }} src={replaceHttpsUrl(props.detail.avatar)} style={{
                    ...viewConfig.avatarStyle
                  }} />
                  {props.edit && <img src={imgRefresh} style={{ ...viewConfig.changeAvatarStyle, pointerEvents: 'none' }} />}
                </div>
                <div style={{ ...viewConfig.nameStyle }}>
                  {props.detail.name}
                </div>
                <div style={{ ...viewConfig.dateStyle }}>
                  {`${formatDateByFormat(props.detail.birthday, 'yyyy.MM')}-${formatDateByFormat(props.detail.dieday, 'yyyy.MM')}`}
                </div>
              </div>
              :
              <>
                <div style={{ ...viewConfig.nameStyle, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <span >—</span>
                  {props.detail.skin == StoneDataKey.kfc || props.detail.skin == StoneDataKey.apple ?
                    <div style={{ position: 'relative' }}><img onClick={() => { if (props.clickSelectAvatar) { props.clickSelectAvatar() } }} src={replaceHttpsUrl(props.detail.avatar)} style={{
                      ...viewConfig.avatarStyle
                    }} />
                      {props.edit && <img src={imgRefresh} style={{ ...viewConfig.changeAvatarStyle, pointerEvents: 'none' }} />}</div>
                    : <></>
                  }
                  <span >{props.detail.name}</span>
                </div>
                <div style={{ ...viewConfig.dateStyle }}>
                  {`${formatDateByFormat(props.detail.birthday, 'yyyy.MM')}-${formatDateByFormat(props.detail.dieday, 'yyyy.MM')}`}
                </div>
              </>
          }

        </>}
    </div>
  );
})

export default TombstoneComp;




