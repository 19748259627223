import React, { CSSProperties, FC, ReactNode, useContext, useState } from 'react';
import XSMusicContext from '../context/XSMusicContext';

interface SoundBtnProps {
    children?: ReactNode;
    style?: CSSProperties;

    onClick?: (e)=>void;
    [key: string]: any; // 允许传递其他属性
}

const SoundBtn: FC<SoundBtnProps> = ({ children, style,onClick, ...rest }) => {
    
  const { playClickSound } = React.useContext(XSMusicContext);
    return (
        <div style={{  ...style }} onClick={(e)=>{
            if(onClick){
                
                playClickSound();
                onClick(e);
            }

        }} {...rest}>
            {children}
        </div>
    );
};

export default SoundBtn;