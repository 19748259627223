import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Notify } from 'react-vant';
import './App.css';
import { UserInfo } from './beans/DataTypes';
import XSDataContext from './context/XSDataContext';
import XSMusicContext from './context/XSMusicContext';
import Create from './Create';
import Download from './Download';
import FuneralDetail from './FuneralDetail';
import Home from './Home';
import Mine from './Mine';
import { Environment } from './util/environment';
import { doClose, getStatusBarHeight, getUserInfo } from './util/NativeBridge';
import { xslog } from './util/XSLog';

import imgEnter from './images/enter.png';
import imgClose from './images/icon_close.png';
import imgLogoB from './images/logo_bottom.png';
import SoundBtn from './components/SoundBtn';
import { stoneData } from './beans/Constrants';
import { notify } from './util/NotifyUtil';



Notify.setDefaultOptions({ color: '#FF0000', background: '#000000' });



function App() {

  const { statusBarHeight, setStatusBarHeight, onKeyBoardShow, environment, setUser, devTest, inApp } = React.useContext(XSDataContext);


  const { pauseOrPlayMusic } = React.useContext(XSMusicContext);
  window.onKeyBoardShow = (show: boolean, height: number) => {
    xslog('onKeyBoardShow', show, height)
    onKeyBoardShow(show, height)
  }

  window.testNative = (value: string) => {
    xslog(`testNative ${value}`)

  }

  window.visibilitychange = (value: boolean) => {
    xslog(`visibilitychange ${value}`)
    pauseOrPlayMusic(!value)

  }

  const [home, setHome] = useState(false)


  useEffect(() => {
    xslog('[[[[[[[[[[[[[[[[[[[[[[[APP_START]]]]]]]]]]]]]]]]]]]]')
    if (environment == Environment.IOS_APP || environment === Environment.ANDROID_APP) {
      getStatusBarHeight(height => {
        xslog(`NativeBridge().getStatusBarheight ${height}`)
        setStatusBarHeight(height / (window.devicePixelRatio || 1))
      }, () => {
        setStatusBarHeight(50)
      })
      getUserInfo((user => {
        window.xsxtoken = user.token
        setUser(user)
      }), (() => {
      }))
    } else {
      if (environment !== Environment.WECHAT && devTest) {
        let u = new UserInfo()
        u.uid = 'J9VNeGvpJKag7gMYxd4D'
        u.name = "大傻哔大傻哔大"
        u.avatar = "http://files.cijian.link/avatar/20240722/7988420c1d3149289725141758504c11.jpeg?x-oss-process\u003dstyle/smallavatar"
        u.birthData = 976464000000
        u.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjIxNTgsImV4cCI6MjAzNzM0NDk2Mn0.d9j_oz8U7jz6tJhBMzfIqEPkGZJHx91DTWsHldC-Us4'
        window.xsxtoken = u.token
        setStatusBarHeight(50)
        setUser(u)
      } else {
      }
    }

    window.onNativeBack = () => {
      xslog('APP onNativeBack')
      pauseOrPlayMusic(true)
      return false;
    }
    setTimeout(() => {
      preload()
    }, 100)


    const currentUrl = window.location.href;
    xslog(`currentUrl is ${currentUrl}`);
    if (currentUrl.toLocaleLowerCase().indexOf('download')>=0) {
      setHome(true)
    }

    const url = new URL(currentUrl);
    let isCallback = url.searchParams.get('isCallback');
    if (isCallback === 'true') {
      url.searchParams.set('isCallback', 'step2')
      window.location.replace(url.toString());
    } else if (isCallback === 'step2') {
      xslog('wxcallback to home')
      setHome(true)
    }
    return () => { };
  }, [])

  const clickClose = () => {
    doClose();
  }

  const preload = () => {

    stoneData.forEach(item => {
      const img = new Image();
      img.src = item.btn;
      const img2 = new Image();
      img2.src = item.eidtBg;

      const img3 = new Image();
      img3.src = item.img;

      const img4 = new Image();
      img4.src = item.viewBg;

    })
  }

  return (<div className="Main" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>{home ? <BrowserRouter>
    <Routes>

      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/create" element={<Create />} />
      <Route path="/view/:id" element={<FuneralDetail />} />
      <Route path="/mine/:fromcreate?" element={<Mine />} />
      <Route path="/download/:canback?" element={<Download />} />
    </Routes>

  </BrowserRouter> :
    <div className="Main" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>

      <SoundBtn style={{
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', textAlign: 'center',
        alignItems: 'center', position: 'relative', width: '100%', height: '100%'
      }} onClick={() => {
        setHome(true)
      }} >

        <span style={{ fontFamily: 'qssx', opacity: 0 }}>1</span>
        <span style={{ fontFamily: 'aliph', opacity: 0 }}>2</span>
        <span style={{ fontFamily: 'ipix', opacity: 0 }}>3</span>
        <img className='EnterBtn' src={imgEnter} style={{ width: '160px', height: '24px' }} />

        {inApp &&
          <img onClick={(e) => { clickClose(); e.stopPropagation() }} src={imgClose} style={{ width: '24px', height: '24px', top: `${statusBarHeight}px`, right: '20px', position: 'absolute' }} />
        }

        <span style={{ fontFamily: 'qssx', opacity: 0 }}>1</span>
        <span style={{ fontFamily: 'aliph', opacity: 0 }}>2</span>
        <span style={{ fontFamily: 'ipix', opacity: 0 }}>3</span>

        <img src={imgLogoB} style={{ width: '75%',bottom: '50px', 
        position: 'absolute' }}/>

      </SoundBtn>

    </div>}</div>)
    ;
}

export default App;
