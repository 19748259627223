import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { XSDataProvider } from './context/XSDataProvier';
import { xslog } from './util/XSLog';
import { Environment } from './util/environment';
import { notify } from './util/NotifyUtil';
import { XSMusicProvider } from './context/XSMusicProvier';

xslog('[[[[[[[[[[[[[ index.tsx start ]]]]]]]]]]]]]]]')

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
function detectEnvironment(): Environment {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;


  if (/MicroMessenger/i.test(userAgent)) {
    return Environment.WECHAT;
  } else if (/QQ\/\d/.test(userAgent) && /MQQBrowser\/\d/.test(userAgent)) {
    return Environment.QQ_APP;
  } else if (userAgent.includes("cjlkios")) {
    return Environment.IOS_APP;
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    if (userAgent.includes("cjlkios")) {
      return Environment.IOS_APP;
    } else {
      return Environment.MOBILE_iOS;
    }
  } else if (/android/i.test(userAgent)) {
    if (window.cjlk) {
      return Environment.ANDROID_APP;
    } else {
      return Environment.MOBILE_Android;
    }
  } else if (/Mobi|Android|iPhone|iPad|iPod|Windows Phone|webOS|BlackBerry|Opera Mini|IEMobile|Mobile/i.test(userAgent)) {
    return Environment.MOBILE_BROWSER;
  } else {
    return Environment.DESKTOP_OR_UNKNOWN;
  }
}

// 定义全局变量
window.appEnvironment = detectEnvironment();


root.render(
  <XSDataProvider>
    <XSMusicProvider>
    <App />
    </XSMusicProvider>
  </XSDataProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
