import Lottie from 'lottie-react';
import { animate, cubicBezier, linear } from 'popmotion';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popup } from 'react-vant';
import { DefaultAvatars, LOCALSTORAGE_KEY, musicData, stoneData, taLabel } from './beans/Constrants';
import SoundBtn from './components/SoundBtn';
import XSDataContext from './context/XSDataContext';
import XSMusicContext from './context/XSMusicContext';
import './Create.css';
import './DetailView.css';
import imgClose from './images/icon_close.png';
import imgMakeMine from './images/icon_makemine.png';
import imgMineMusic from './images/icon_mine_music.png';
import imgMakingfuneral from './images/makingfuneral.png';
import imgMineMusicMute from './images/icon_mine_music_mute.png';
import imgQuote from './images/icon_quote.png';
import imgResend from './images/icon_resend.png';
import imgGhostBg from './images/img_ghost_bg.png';
import imgChangeAvatar from './images/icon_change_avatar.png';
import imgMakingBg from './images/img_making_bg.png';
import imgMaskInputBg from './images/img_mask_input_bg.png';
import AutoScrollList from './MessageAutoScrollList';
import './Mine.css';
import TombstoneComp from './TombstoneComp';
import { ContentAudit, ContentAuditScene, XsHttpCient } from './util/HttpUtil';
import { doClose } from './util/NativeBridge';
import { notify } from './util/NotifyUtil';
import { xslog } from './util/XSLog';
import WishesComp from './WishesComp';
import { WebUserInfo } from './beans/DataTypes';
import { Environment } from './util/environment';
import { goDownload, isEmpty } from './util/CommonUtil';
interface DetailViewProps {
  uid: string;
  fromParticipate: boolean;
}
const DetailView = forwardRef<any, DetailViewProps>((props, ref) => {
  const { statusBarHeight, environment, keyboardEvent, inApp, webUser, setWebUser, ghostLottie, makeoffringLottie } = React.useContext(XSDataContext);
  const { musicPlayerRef } = React.useContext(XSMusicContext);
  const titleBarHeight = statusBarHeight + 31
  const [showContent, setShowContent] = useState("")
  const [showBtn, setShowBtn] = useState(false)

  const [showGuid, setShowGuide] = useState(props.fromParticipate)

  const [offeringName, setOfferingName] = useState("")
  const [offeringWords, setOfferingWords] = useState("")
  const [fillUserName, setFilluserName] = useState("")
  const [fillAvatarIndex, setFillAvatarIndex] = useState(0)
  const textContentRef = useRef(null);
  const rootRef = useRef(null);

  const [showOffer, setShowOffer] = useState(false);
  const [showOfferStep2, setShowOfferStep2] = useState(false);
  const [fillUserInfo, setFillUserInfo] = useState(false);

  const [showWishes, setShowWishes] = useState(false);
  const navigate = useNavigate();

  const [currentAudio, setCurrentAudio] = useState(null);
  const [musicPlaying, setMusicPlaying] = useState(false)
  const [downloadGuid, setDownloadGuid] = useState(false);
  const [btnClickable, setBtnClickable] = useState(false)
  const btnRef = useRef(null);
  const infoRef = useRef(null)

  const [words, setWords] = useState('');
  const [showWords, setShowWords] = useState(false);

  const [detail, setDetail] = useState({
    "avatar": "",
    "birthday": 0,
    "createTime": 0,
    "dieday": 0,
    "epitaph": "",
    "id": 0,
    "skin": -1,
    "bgm": -1,
    "message": 0,
    "name": "",
    "offerings": 0,
    "reason": "",
    "updateTime": 0,
    "visitCount": 0,
    "sex": -1,
    "relation": "",
    "lastwish": []
  })

  const [offerings, setOfferings] = useState([])

  const [contentText, setContentText] = useState(null);//`一段话`


  const [marqeeClass, setMarqeeClass] = useState('');//

  useEffect(() => {
    xslog("useEffect words,showWords ", words, showWords);
    if (words != "" && showWords) {
      xslog('show words')
      setContentText(words)
    }
  }, [words, showWords])

  useEffect(() => {
    if (currentAudio) {
      setMusicPlaying(true)
      currentAudio.play().catch(() => {
        setMusicPlaying(false)
      });
    }
    return () => {
      if (currentAudio) {
        currentAudio.pause();
      }
    };
  }, [currentAudio]);
  let interval;
  useEffect(() => {
    if (contentText) {
      let index = 0;
      interval = setInterval(() => {
        setShowContent((prev) => prev + contentText[index++]);
        if (index >= contentText.length - 1) {
          clearInterval(interval);
          setShowBtn(true)
          setTimeout(() => {
            if (showGuid && detail.message > 0 && textContentRef.current) {
              animate({
                from: 0,
                to: 1,
                duration: 200,
                onUpdate: (progress) => {
                  if (textContentRef.current) {
                    textContentRef.current.style.opacity = `${1 - progress}`
                  }
                },
                onComplete: () => {
                  setShowGuide(false)
                }
              })
            } else {
              setShowGuide(false)
            }
          }, 2000)

        }
      }, 50); // 控制每个字符出现的速度
    }
    return () => clearInterval(interval);
  }, [contentText]);

  useEffect(() => {
    loadData(false)
    loadOfferings()

    return () => {
      if (currentAudio) {
        currentAudio.pause();
      }
    };
  }, [])


  useEffect(() => {
    if (showWishes) {
      window.onNativeBack = () => {
        xslog('onNativeBack 1',)
        setShowWishes(false)
        return true;
      }
    } else {
      window.onNativeBack = () => {
        xslog('onNativeBack 2', showWishes)

        return false;
      }
    }
  }, [showWishes]);

  const makeDiaRef = useRef(null)
  const makeStep2DiaRef = useRef(null)
  const fillUserInfoDiaRef = useRef(null)

  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    if (!showOverlay && makingMaskRef.current) {
      makingMaskRef.current.style.transform = `translateY(0px)`
    }
  }, [showOverlay]);

  useEffect(() => {
    if (!showOffer && makeDiaRef.current) {
      makeDiaRef.current.style.transform = `translateY(0px)`
    }
  }, [showOffer]);

  useEffect(() => {
    if (!showOfferStep2 && makeStep2DiaRef.current) {
      makeStep2DiaRef.current.style.transform = `translateY(0px)`
    }
  }, [showOfferStep2]);

  useEffect(() => {
    if (environment == Environment.ANDROID_APP) {
      if (keyboardEvent.show) {
        if (makeDiaRef.current) {
          makeDiaRef.current.style.transform = `translateY(${- keyboardEvent.height}px)`
        }
        if (makeStep2DiaRef.current) {
          makeStep2DiaRef.current.style.transform = `translateY(${- keyboardEvent.height}px)`
        }
        if (fillUserInfoDiaRef.current) {
          fillUserInfoDiaRef.current.style.transform = `translateY(${- keyboardEvent.height}px)`
        }

      } else {
        if (makeDiaRef.current) {
          makeDiaRef.current.style.transform = `translateY(0px)`
        }
        if (makeStep2DiaRef.current) {
          makeStep2DiaRef.current.style.transform = `translateY(0px)`
        }
        if (fillUserInfoDiaRef.current) {
          fillUserInfoDiaRef.current.style.transform = `translateY(0px)`
        }
      }
    }
  }, [keyboardEvent]);

  const loadData = (fromSendSucc: boolean) => {
    XsHttpCient.get(`funeral/detail?uidStr=${props.uid}`).then((res) => {
      setDetail(res.data.data)
      const sexlabel = taLabel(res.data.data.sex);
      setWords(`好了 我就带你到这里了\n你在这好好陪陪${sexlabel}吧\n对了 你还可以给${sexlabel}送一些供品\n${sexlabel}收到后一定会很开心的！`)
      xslog('setWords')
      if (!fromSendSucc) {
        if (!musicPlayerRef || !musicPlayerRef.current || !musicPlayerRef.current.paused) {
          const newAudio = new Audio(musicData[res.data.data.bgm].url);
          newAudio.loop = true;
          musicPlayerRef.current = newAudio;
          setCurrentAudio(newAudio);
        } else {
          setCurrentAudio(musicPlayerRef.current)
        }
        setMarqeeClass('marquee')
      }
    }).catch((error) => {

    });
  }



  const [aniStep, setAniStep] = useState(0);

  const showMask = () => {
    setShowOverlay(true)
    setAniStep(1)
  }

  const makingMaskRef = useRef(null);
  const makingMaskTitleRef = useRef(null);
  const makingMaskGhostRef = useRef(null);
  const makingMaskInputGroupRef = useRef(null);
  const makingMaskResultRef = useRef(null);
  const makingMaskButtontGroupRef = useRef(null);

  useEffect(() => {
    if (aniStep == 1) {
      animate({
        from: 0,
        to: 1,
        duration: 300,
        onUpdate: (progress) => {
          if (makingMaskRef.current) {
            let ele = makingMaskRef.current as HTMLElement;
            ele.style.backgroundColor = `rgba(0,0,0,${0.7 * progress})`;
          }

          if (makingMaskTitleRef.current) {
            let ele = makingMaskTitleRef.current as HTMLElement;
            ele.style.opacity = `${progress}`;
          }

          if (makingMaskGhostRef.current) {
            let ele = makingMaskGhostRef.current as HTMLElement;
            ele.style.opacity = `${progress}`;
          }

        },
        onComplete: () => {
        }
      })
      setTimeout(() => {
        animate({
          from: 0,
          to: 1,
          duration: 300,
          onUpdate: (progress) => {
            if (makingMaskInputGroupRef.current) {
              let ele = makingMaskInputGroupRef.current as HTMLElement;
              ele.style.opacity = `${progress}`;
              ele.style.transform = `translateY(${-10 + 10 * progress}px)`;
            }


          }
        })
      }, 2000)

    } else if (aniStep == 2) {
      animate({
        from: 0,
        to: 1,
        duration: 200,
        onUpdate: (progress) => {

          if (makingMaskGhostRef.current) {
            let ele = makingMaskGhostRef.current as HTMLElement;
            ele.style.opacity = `${1 - progress}`;
          }

        },
        onComplete: () => {
          animate({
            from: 0,
            to: 1,
            duration: 200,
            onUpdate: (progress) => {

              if (makingMaskResultRef.current) {
                let ele = makingMaskResultRef.current as HTMLElement;
                ele.style.opacity = `${progress}`;
              }
              if (makingMaskButtontGroupRef.current) {
                let ele = makingMaskButtontGroupRef.current as HTMLElement;
                ele.style.opacity = `${progress}`;
              }
            },
            onComplete: () => {
            }
          })
        }
      })


    }
  }, [aniStep]);

  const makeOfferNext = () => {
    if (isEmpty(offeringName)) {
      notify('请输入供品')
      return;
    }
    ContentAudit(ContentAuditScene.offername, offeringName, () => {
      setShowOffer(false)
      setShowOfferStep2(true)
    }, (error) => {
      notify(error.msg);
    })
  }

  const showDownloadGuide = () => {
    if (!inApp) {
      let guideShown = localStorage.getItem(LOCALSTORAGE_KEY.downloadGuide)
      if (guideShown === null || guideShown === undefined || guideShown === '') {
        setTimeout(() => {
          localStorage.setItem(LOCALSTORAGE_KEY.downloadGuide, "shown")
          setDownloadGuid(true)
        }, 5000)
      }
    }
  }



  const sendDoit = (wu?: WebUserInfo) => {

    // "uidStr": "vZK4XrxpoQ0Wy213Lnja",
    // "offeringsName": "供品名称",
    // "message": "一路走好",
    // "relation": "父子",
    // "userName": "用户姓名",
    // "userAvatar": "https://xxxx.com"

    let realWu = webUser;
    if (wu) {
      realWu = wu;
    }

    let poData = { uidStr: props.uid, offeringsName: offeringName, message: offeringWords, relation: detail.relation };
    if (!inApp && realWu) {
      poData['userName'] = realWu.name;
      poData['userAvatar'] = realWu.avatar;
    }
    XsHttpCient.post(`funeral/sendOfferings`, poData).then((res) => {
      notify("送出供品成功")
      setFillUserInfo(false)
      setShowOfferStep2(false)
      setShowOffer(false)
      loadData(true)
      loadOfferings()
      showDownloadGuide()
      setOfferingName("")
      setOfferingWords("")


      // setAniStep(3)
    }).catch((error) => {
      notify(error.msg)
      xslog(error)
    });

  }

  const makeOfferDone = () => {

    const doit = () => {
      if (!inApp && !webUser) {
        setFillUserInfo(true)
        return;
      } else {
        sendDoit()
      }
    }
    if (offeringWords !== '') {
      ContentAudit(ContentAuditScene.offerwords, offeringWords, () => {
        doit()
      }, (error) => {
        notify(error.msg);
      })
    } else {
      doit()
    }

  }

  const changeFillAvatar = () => {
    let newindex = fillAvatarIndex + 1;
    if (newindex >= DefaultAvatars.length) {
      newindex = 0;
    }
    setFillAvatarIndex(newindex);
  }

  const fillAndSend = () => {
    if (isEmpty(fillUserName)) {
      notify('请输入名字');
      return
    }
    ContentAudit(ContentAuditScene.infoName, fillUserName, () => {
      let wu = new WebUserInfo(2, fillUserName, DefaultAvatars[fillAvatarIndex]);
      let str = JSON.stringify(wu)
      xslog('WebUserInfo is ' + str);
      localStorage.setItem(LOCALSTORAGE_KEY.webuser, str)
      setWebUser(wu)
      sendDoit(wu)
    }, (error) => {
      notify(error.msg);
    })


  }



  const loadOfferings = () => {
    XsHttpCient.get(`funeral/getOfferings?uidStr=${props.uid}`).then((res) => {
      xslog(res.data.data)
      setOfferings(res.data.data)
    }).catch((error) => {

    });
  }










  useEffect(() => {
    enterAnimation()
    return () => { }
  }, []);

  useEffect(() => {
    if (showBtn) {
      setTimeout(() => {
        animate({
          from: 0,
          to: 1,
          ease: linear,
          duration: 300,
          onUpdate: (progress) => {
            if (btnRef.current) {
              btnRef.current.style.opacity = progress
            }
            if (infoRef) {
              infoRef.current.style.opacity = progress
            }
          },
          onComplete: () => {
            setBtnClickable(true)
          }
        })
      }, 200)
    } else {
      setBtnClickable(false)
    }
  }, [showBtn]);

  const lottieRef = useRef(null);
  const contentRef = useRef(null);

  const enterAnimation = () => {
    if (rootRef.current) {
      rootRef.current.style.opacity = 0;
    }

    setTimeout(() => {

      if (contentRef.current) {
        contentRef.current.style.opacity = 0;
      }
      animate({
        from: 0,
        to: 1,
        ease: linear,
        duration: 1000,
        onUpdate: (progress) => {

          if (rootRef.current) {
            rootRef.current.style.opacity = progress;
          }
        },
        onComplete: () => {
          if (lottieRef.current) {
            const ghostTransX = 200
            lottieRef.current.style.transform = `rotateY(180deg) translate(${ghostTransX}px,0px)`;

            setTimeout(() => {
              animate({
                from: 0,
                to: 1,
                ease: cubicBezier(0, 0, 0.45, 0.99),
                duration: 800,
                onUpdate: (progress) => {

                  if (lottieRef.current) {
                    lottieRef.current.style.transform = `rotateY(180deg) translate(${ghostTransX - ghostTransX * progress}px,0px)`;
                  }
                },
                onComplete: () => {
                  if (contentRef.current) {
                    contentRef.current.style.opacity = 1;
                  }

                  setShowWords(true);

                }
              })
            }, 300)
          } else {
            setShowBtn(true);
          }


        }
      })
    }, 500)

  }




  return (
    <div ref={rootRef} className='Main' style={{ display: 'flex', position: 'relative', width: '100%', height: '100%', backgroundColor: '#33000', opacity: 0, overflow: 'hidden' }}>

      {detail.skin >= 0 && <div style={{ width: '100%', position: 'absolute', top: `${inApp ? '0px' : '-50px'}`, zIndex: 98, filter: showWishes ? 'blur(10px)' : '' }}>
        <TombstoneComp edit={false} detail={{ ...detail }} />
      </div>}
      <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column', alignItems: 'center', filter: showWishes ? 'blur(10px)' : '', zIndex: 99 }}>



        {((!showGuid) && detail.message > 0) ?
          <AutoScrollList mine={false} userlabel={taLabel(detail.sex)} style={{ marginTop: `${398 - (inApp ? 0 : 50)}px`, flexGrow: 1 }} data={offerings} />
          :

          <><div style={{ flexGrow: 1 }}> </div>
            <div ref={textContentRef} style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', marginBottom: '40px', position: 'relative' }} className='TextContent'>
              <div ref={contentRef} style={{ display: 'flex', flexDirection: 'row', position: 'relative', marginTop: '160px' }}>
                <img src={imgQuote} style={{ width: '24px', height: '24px', marginLeft: '28px' }} />
                <div style={{ marginTop: '12px', height: '115px', fontSize: '17px', lineHeight: '35.53px', whiteSpace: 'pre-line' }}>
                  {showContent}
                </div>
              </div>
              <div ref={lottieRef} style={{
                pointerEvents: 'none',
                width: 200, height: 200, transform: 'rotateY(180deg) translate(-1000px,0px)',
                display: 'flex', justifyContent: 'center', position: 'absolute', top: '-10px', left: '-34px',
              }}>
                {ghostLottie && <Lottie animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
              </div>
            </div></>}




        <div ref={infoRef} style={{ opacity: 0, marginBottom: '1px', fontSize: '13px', color: '#424242' }}>
          {detail.visitCount}人来过 ｜ <span onClick={() => { setShowWishes(true) }} style={{ pointerEvents: btnClickable ? 'auto' : 'none', color: '#CCAC74', textDecoration: 'underline' }}>查看遗愿清单</span> ｜ 收到供品{detail.offerings}
        </div>
        <div style={{ width: window.innerWidth, height: window.innerWidth * 75 / 393, marginBottom: '30px' }}> {
          <SoundBtn style={{ pointerEvents: btnClickable ? 'auto' : 'none' }} onClick={() => {
            //  makeOffer()
            setShowOffer(true)
          }}>
            <img ref={btnRef} src={detail.skin >= 0 ? stoneData[detail.skin].btn : ''} style={{ width: '100%', height: '100%', opacity: 0 }} />
          </SoundBtn>}</div>
      </div>

      {
        showWishes ?
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'absolute', width: '100%', zIndex: 100 }}>

            {detail.lastwish.length > 0 ?
              <>
                <div style={{ fontSize: '20px', margin: `${statusBarHeight + 31.3}px 0 24px 0`, width: '100%', textAlign: 'center' }}>{`点击撕下便签 帮${taLabel(detail.sex)}实现遗愿`}</div>

                <WishesComp visitor={true} sex={detail.sex} wishes={detail.lastwish} setWishes={(data) => {
                  let d = detail;
                  d.lastwish = data;
                  setDetail(d);
                }} relation={detail.relation} /></>
              :
              <div style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '20px' }}>{`「${taLabel(detail.sex)}一生似乎很圆满，没有什么遗愿」`}</div>
            }


            <SoundBtn onClick={() => { setShowWishes(false) }} style={{ alignSelf: 'center', position: 'absolute', bottom: `${((window.innerHeight > 700) ? 57 : 5)}px` }}>
              <div style={{ width: '313px', height: '48px', alignSelf: 'center' }} className="Btn">完成</div>
            </SoundBtn>
          </div> : <></>
      }

      {(!showWishes) && <div style={{
        width: '100%',
        display: 'flex', flexDirection: 'row',
        alignItems: 'center', justifyContent: 'center',
        alignContent: 'center',
        position: 'absolute', zIndex: 100,
        top: statusBarHeight > 0 ? statusBarHeight + 5 : 21
      }} >


        <div onClick={() => {
          if (musicPlaying) {
            if (currentAudio) {
              currentAudio.pause()
            }
            setMusicPlaying(false)
          } else {
            if (currentAudio) {
              currentAudio.play()
            }
            setMusicPlaying(true)
          }
        }} style={{
          borderRadius: '5px',
          display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0  0 0 20px ', justifyContent: 'center', width: '113px', height: '24px',
          border: '1px solid rgba(255, 255, 255, 0.3)', backgroundColor: 'rgba(255, 255, 255, 0.4)'
        }}>

          <img src={musicPlaying ? imgMineMusic : imgMineMusicMute} style={{ width: '14px', height: '14px', margin: '0 5px 0 5px' }} />
          <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '76px' }}>
            <div className={`${marqeeClass}`} style={{ whiteSpace: 'nowrap', fontSize: '11px', color: '#424242' }}>{detail.bgm >= 0 ? `葬礼bgm:${musicData[detail.bgm].name}` : ''}</div></div>


        </div>

        <div style={{ flexGrow: 1 }} />
        {inApp && <img onClick={() => {
          if (currentAudio) {
            currentAudio.pause();
          }
          doClose()
        }} src={imgClose} style={{ width: '24px', height: '24px', margin: '0 20px 0 0' }} />}

        {!inApp && <img onClick={() => { goDownload(environment, navigate, true) }} src={imgMakingfuneral} style={{ width: '83px', height: '26px', margin: '0 20px 0 0' }} />}
      </div>}



      <Popup
        position='bottom' overlayStyle={{ backgroundColor: 'rgba(255,255,255,0.05)' }} style={{
          width: "100%", backgroundColor: 'transparent', height: '100%'
        }} visible={showOffer} onClose={() => setShowOffer(false)} onClick={() => { setShowOffer(false) }}>
        <div ref={makeDiaRef} style={{ width: "100%", display: 'flex', flexDirection: 'row', position: 'absolute', bottom: 0, alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}
          onClick={() => { setShowOffer(false); }}>
          <div onClick={(event) => { event.stopPropagation(); }}
            style={{
              marginTop: '80px',
              width: '100%',
              display: 'flex', border: '2px solid #424242', backgroundColor: '#FFFFFF',
              flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
            }} >
            <div style={{ fontFamily: 'ipix', marginTop: '68px', fontSize: '20px', textAlign: 'center' }}>可以送上任何你能想到的供品</div>
            <input placeholder='比如，一包辣条!' maxLength={15} className='Input' style={{ fontFamily: 'ipix', marginTop: '20px', width: 'calc(100% - 60px)', height: '40px', fontSize: '15px' }} type="text" value={offeringName} onChange={(e) => { setOfferingName(e.target.value) }} />

            <SoundBtn className="Btn" style={{ width: '94px', height: '42px', alignSelf: 'center', margin: '20px 0 50px 0', fontSize: '15px', fontFamily: 'ipix' }}
              onClick={() => {
                makeOfferNext();
              }}
            >下一步</SoundBtn>


          </div>
          <img src={imgGhostBg} style={{ width: '149px', height: '29px', position: 'absolute', top: '130px' }} />
          <div style={{
            pointerEvents: 'none', transform: 'rotateY(180deg) translateX(-20px)',
            width: '160px', height: '160px', position: 'absolute', top: '0px'
          }}>
            {ghostLottie && <Lottie animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
          </div>
        </div>

      </Popup>

      <Popup
        position='bottom' overlayStyle={{ backgroundColor: 'rgba(255,255,255,0.05)' }} style={{
          width: "100%", backgroundColor: 'transparent', height: '100%'
        }} visible={showOfferStep2} onClose={() => setShowOfferStep2(false)} onClick={() => { setShowOffer(false) }}>
        <div ref={makeStep2DiaRef} style={{ width: "100%", display: 'flex', flexDirection: 'row', position: 'absolute', bottom: 0, alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}
          onClick={() => { setShowOfferStep2(false); }}>
          <div onClick={(event) => { event.stopPropagation(); }}
            style={{
              marginTop: '80px',
              width: '100%',
              display: 'flex', border: '2px solid #424242', backgroundColor: '#FFFFFF',
              flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
            }} >
            <div style={{ fontFamily: 'ipix', marginTop: '68px', fontSize: '20px', textAlign: 'center' }}>想再捎句话吗？</div>
            <textarea placeholder='(选填)' maxLength={100} className='Input' style={{ padding: '10px 13px 10px 13px', fontFamily: 'ipix', marginTop: '20px', width: 'calc(100% - 60px)', height: '72px', fontSize: '15px', textAlign: 'center' }} value={offeringWords} onChange={(e) => { setOfferingWords(e.target.value) }} />

            <SoundBtn className="Btn" style={{ width: '94px', height: '42px', alignSelf: 'center', margin: '20px 0 50px 0', fontSize: '15px', fontFamily: 'ipix' }}
              onClick={() => {
                makeOfferDone();
              }}
            >送出</SoundBtn>


          </div>
          <img src={imgGhostBg} style={{ width: '149px', height: '29px', position: 'absolute', top: '130px' }} />
          <div style={{
            pointerEvents: 'none', transform: 'rotateY(180deg) translateX(-20px)',
            width: '160px', height: '160px', position: 'absolute', top: '0px'
          }}>
            {ghostLottie && <Lottie animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
          </div>
        </div>

      </Popup>





      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} style={{
        backgroundColor: 'transparent',
      }} visible={fillUserInfo} onClose={() => setFillUserInfo(false)}>
        <div className='CommonDialog' style={{ width: '333px', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ margin: '20px 0 0 0 ', lineHeight: '30px', textAlign: 'center', fontFamily: 'ipix', fontSize: '20px' }}>且慢！最后请留一下你的信息</span>
          <div onClick={changeFillAvatar} style={{ position: "relative", height: '90px', display: 'flex', flexDirection: 'row', margin: '10px 0 10px 0', alignItems: 'center', justifyContent: 'center' }}>
            <img src={DefaultAvatars[fillAvatarIndex]} style={{ width: '70px', height: '70px', border: '2px solid #424242', borderRadius: '5px' }} />
            <img src={imgChangeAvatar} style={{ width: '57px', height: '16px', position: 'absolute', bottom: '0px' }} />

          </div>

          <input placeholder={`你的名字是?  (要写个${taLabel(detail.sex)}认得的哦)`} maxLength={4} className='Input' style={{ fontFamily: 'ipix', width: 'calc(100% - 60px)', height: '40px', fontSize: '15px' }} type="text" value={fillUserName} onChange={(e) => { setFilluserName(e.target.value) }} />

          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '20px 0 20px 0', alignItems: 'center', justifyContent: 'center' }}>
            <div onClick={() => {
              setFillUserInfo(false);


            }} className='Btn' style={{ width: '112px', height: '42px', fontSize: '17px', fontFamily: 'ipix' }}>取消</div>
            <div onClick={() => {
              fillAndSend();
            }} className='Btn' style={{ width: '112px', height: '42px', marginLeft: '23px', fontSize: '17px', fontFamily: 'ipix' }}>确定</div>
          </div>
        </div>

      </Popup>



      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} style={{
        backgroundColor: 'transparent',
      }} visible={downloadGuid} onClose={() => setDownloadGuid(false)}>
        <div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}
          onClick={() => { setDownloadGuid(false); }}>
          <div className='CommonDialog' style={{ width: '327px', flexDirection: 'column', alignItems: 'center', margin: '80px 0 80px 0', position: 'relative' }}>

            <span style={{ fontFamily: 'ipix', fontSize: '20px', color: '#424242', WebkitTextStroke: '0.2px #424242', marginTop: '60px' }}>想给自己也办场葬礼吗?</span>

            <span style={{ fontFamily: 'ipix', fontSize: '15px', width: '287px', lineHeight: '25px', color: '#424242', marginTop: '10px', textAlign: 'center' }}>好消息！现在入住赛博墓园，多种墓碑款式，多支葬礼乐队，任你挑选！</span>
            <div onClick={() => {
              goDownload(environment, navigate, true);
            }} className='Btn' style={{ width: '257px', height: '42px', marginLeft: '23px', fontSize: '17px', fontFamily: 'ipix', margin: '20px 0 30px 0' }}>入住墓园</div>

            <img onClick={() => { setDownloadGuid(false); }} src={imgClose} style={{ width: '20px', height: '20px', position: 'absolute', top: '8.5px', right: '10px' }} />
          </div>
          <img src={imgGhostBg} style={{ width: '149px', height: '29px', position: 'absolute', top: '130px' }} />
          <div style={{
            pointerEvents: 'none', transform: 'rotateY(180deg) translateX(-20px)',
            width: '160px', height: '160px', position: 'absolute', top: '0px'
          }}>
            {ghostLottie && <Lottie animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
          </div>
        </div>

      </Popup>






    </div>
  );
})

export default DetailView;

