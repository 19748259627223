import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Popup } from 'react-vant';
import './Relation.css';
import './Welcome.css';
import imgClose from './images/icon_close.png';
import XSDataContext from './context/XSDataContext';
import imgEdit from './images/icon_edit.png';
import imgNext from './images/icon_next.png';
import imgQuote from './images/icon_quote.png';
import imgLogo1 from './images/img_logo_1.png';
import imgLogoText from './images/img_logo_text.png';
import { notify } from './util/NotifyUtil';
import { doWechatAuth } from './util/WechatAuth';
import { Environment } from './util/environment';
import { LOCALSTORAGE_KEY, taLabel } from './beans/Constrants';
import { xslog } from './util/XSLog';
import XSMusicContext from './context/XSMusicContext';
import SoundBtn from './components/SoundBtn';
import Lottie from 'lottie-react';
import { animate, cubicBezier, linear } from 'popmotion';
import { isEmpty, outAnimation } from './util/CommonUtil';
import { doClose } from './util/NativeBridge';
import { ContentAudit, ContentAuditScene } from './util/HttpUtil';
interface TRelationProps {
  uid: String;
  detail: any;

  callbackdata?: any;
  goNext: (relation: string) => void;
}

const Relation = forwardRef<any, TRelationProps>((props, ref) => {
  const { statusBarHeight, environment, user, webUser, setWebUser, ghostLottie, inApp } = React.useContext(XSDataContext);
  const { bgPlayerRef, startPlayBgMusic } = React.useContext(XSMusicContext);
  const [showContent, setShowContent] = useState("")
  const [btnClickable, setBtnClickable] = useState(false)

  const [customRl, setCustomRl] = useState(false)
  const [startWXAuth, setStartWXAuth] = useState(false)
  const [selectRl, setSelectRl] = useState(null)

  const inputDialogRef = useRef(null);
  const textIntervalRef = useRef(null);
  const inputRlRef = useRef(null);
  const [rls, setRls] = useState([{ key: 0, label: '家人' }, { key: 1, label: '铁子' }, { key: 2, label: '伴侣' }, { key: 3, label: '同学' }, { key: 4, label: '路人' }, { key: 5, label: "" }])

  const lottieRef = useRef(null);
  const rootRef = useRef(null);
  const logoText = useRef(null);
  const ghostBg = useRef(null);
  const contentGroup = useRef(null);

  const [showBtn, setShowBtn] = useState(false)

  const [contentText, setContentText] = useState(null)

  const btnRef = useRef(null);
  useEffect(() => {
    if (showBtn) {
      setTimeout(() => {
        animate({
          from: 0,
          to: 1,
          ease: linear,
          duration: 300,
          onUpdate: (progress) => {
            if (btnRef.current) {
              btnRef.current.style.opacity = progress
            }
          },
          onComplete:()=>{
            setBtnClickable(true)
          }
        })
      }, 200)
    } else {
      if (btnRef.current) {
        btnRef.current.style.opacity = 0
      }
      setBtnClickable(false)
    }
  }, [showBtn]);

  useEffect(() => {
    if (props.callbackdata && props.callbackdata.relation) {
      xslog('Relation page start from callback')
      if (logoText.current) {
        logoText.current.style.opacity = 1;
      }
      if (ghostBg.current) {
        ghostBg.current.style.opacity = 1;
      }
      if (lottieRef.current) {
        lottieRef.current.style.opacity = 1;

        lottieRef.current.style.transform = `translate(0px,0px)`;
      }
      if (contentGroup.current) {
        contentGroup.current.style.opacity = 1;
      }
      doChooseRelation(props.callbackdata.relation)
    } else {
      enterAnimation();
    }



    return () => { };
  }, []);

  const enterAnimation = () => {
    if (logoText.current) {
      logoText.current.style.opacity = 0;
    }

    if (ghostBg.current) {
      ghostBg.current.style.opacity = 0;
    }
    if (contentGroup.current) {
      contentGroup.current.style.opacity = 0;
    }
    const ghostTransY = 221
    if (lottieRef.current) {
      lottieRef.current.style.transform = `translate(${ghostTransY}px,0px)`;
    }

    animate({
      from: 0,
      to: 1,
      ease: linear,
      duration: 500,
      onUpdate: (progress) => {
        if (logoText.current) {
          logoText.current.style.opacity = progress;
        }

        if (ghostBg.current) {
          ghostBg.current.style.opacity = progress;
        }
      },
      onComplete: () => {
        setTimeout(() => {
          animate({
            from: 0,
            to: 1,
            ease: cubicBezier(0, 0, 0.42, 1),
            duration: 1500,
            onUpdate: (progress) => {
              if (lottieRef.current) {
                lottieRef.current.style.transform = `translate(${ghostTransY - ghostTransY * progress}px,0px)`;
              }
            },
            onComplete: () => {
              setTimeout(() => {
                setContentText(
                  `欢迎来到赛博墓园\n请问你是 ${props.detail.name} 的什么人?`);


                if (contentGroup.current) { contentGroup.current.style.opacity = 1; }
              }, 200)
            }
          })
        }, 200)

      }
    })

  }

  useEffect(() => {
    if (contentText) {
      if (textIntervalRef.current) {
        clearInterval(textIntervalRef.current)
      }
      let index = 0;
      setShowContent('')
      textIntervalRef.current = setInterval(() => {
        setShowContent((prev) => {
          return prev + contentText[index++]
        });
        if (index >= contentText.length - 1) {


          setShowBtn(true);

          if (textIntervalRef.current) {
            clearInterval(textIntervalRef.current)
          }
        }
      }, 50); // 控制每个字符出现的速度
    }
    return () => {
      if (textIntervalRef.current) {
        clearInterval(textIntervalRef.current)
      }
    };
  }, [contentText]);

  const chooseRelation = (item) => {
    if (item.key === 5) {
      setCustomRl(true)
    } else {
      doChooseRelation(item)
    }
  }

  const doChooseRelation = (item) => {
    const doit = (username) => {

      xslog('doChooseRelation doit ' + username)
      setSelectRl(item)
      setShowBtn(false)
      setContentText(`好的 ${username}\n请跟我来\n我这就带你去参加${taLabel(props.detail.sex)}的葬礼`)
    }
    if (user) {
      doit(user.name);
    } else {
      xslog('webuser ' + webUser)
      if (webUser != null) {
        doit(webUser.name);
      } else {
        if (environment == Environment.WECHAT) {
          //  localStorage.setItem(LOCALSTORAGE_KEY.tempauthdata, JSON.stringify({
          //   detail: props.detail,
          //   relation: item
          // }))
          // window.location.href = `http://192.168.124.11:3000/view/${props.uid}?isCallback=true&name=abc&avatar=eee`;
          localStorage.setItem(LOCALSTORAGE_KEY.tempauthdata, JSON.stringify({
            detail: props.detail,
            relation: item
          }))
          doWechatAuth(props.uid);
        } else {
          doit("");

          // localStorage.setItem(LOCALSTORAGE_KEY.tempauthdata, JSON.stringify({
          //   detail: props.detail,
          //   relation: item
          // }))
          // window.location.href = `${window.location.origin}/view/${props.uid}?isCallback=true&name=abc&avatar=eee`;
        }
      }
    }
  }

  const doSetCustomRelation = () => {
    if (inputRlRef.current) {
      let v = inputRlRef.current.value
      if (isEmpty(v)) {
        notify("请输入内容")
        return
      }
      ContentAudit(ContentAuditScene.relation, v, () => {
        setCustomRl(false);
        doChooseRelation({ key: 5, label: v })
      }, (error) => {
        notify(error.msg);
      })
    }
  }


  const RlsBtn = (item) => {
    return (<SoundBtn onClick={() => { chooseRelation(item) }} className='Btn' key={item.key} style={{ display: 'flex', position: 'relative', flexDirection: 'row', alignItems: 'center', width: '78px', height: '38px', marginBottom: item.key < 3 ? 9 : 0,pointerEvents:btnClickable?'auto':'none' }}>
      {item.label === '' ? <>其他<img src={imgEdit} style={{ width: '20px', height: '20px', marginLeft: '5px' }} /></> : <>{item.label}</>}
    </SoundBtn>)
  }

  return (
    <div ref={rootRef} style={{ display: 'flex',position:'relative', flexDirection: 'column', height: '100%', widows: '100%', overflow: 'hidden' }}>
      <img ref={logoText} src={imgLogoText} style={{ width: `${window.innerWidth}px`, height: `${708 * window.innerWidth / 1179}px`, margin: `${statusBarHeight}px 0 0 0` }} />
      <div style={{ position: 'relative',transform:window.innerHeight<600?'translate(0px,-100px)':''}}>
        <img ref={ghostBg} src={imgLogo1} style={{ width: `${window.innerWidth}px`, height: `${345 * window.innerWidth / 1179}px`, margin: '88px 0 0 0' }} />
        <div style={{
          pointerEvents: 'none',
          width: 227, height: 227, overflow: 'hidden',
          display: 'flex', justifyContent: 'center', position: 'absolute', bottom: 14, right: 0,
        }}>
          <div ref={lottieRef} style={{
            pointerEvents: 'none',
            width: 227, height: 227, overflow: 'hidden',
            display: 'flex', justifyContent: 'center',
          }}>
            {ghostLottie && <Lottie style={{ marginRight: -52 }} animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
          </div>
        </div>
      </div>

      <div ref={contentGroup} style={{ display: 'flex', flexDirection: 'row', opacity: 0,marginTop:`${window.innerHeight<600?-100 :(window.innerHeight<700?-37:0)}px` }} >

        <img src={imgQuote} style={{ width: '24px', height: '24px', marginLeft: '28px' }} />
        <p style={{ marginTop: '12px', fontSize: '20px', lineHeight: '40px', height: '76px', whiteSpace: 'pre-line' }}>
          {showContent}
        </p>
      </div>
      {
        <div ref={btnRef} style={{ opacity: 0, width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
          {selectRl == null && <div style={{ display: 'grid', width: '300px', gridTemplateColumns: '1fr 1fr 1fr' }}>
            {rls.map(item => (
              RlsBtn(item)
            ))}
          </div>}
          {selectRl != null && <SoundBtn onClick={() => {
            if (rootRef.current) {
              outAnimation(rootRef.current, () => {
                props.goNext(selectRl.label)
              })
            }
          }} className='Btn' style={{ position: 'absolute', bottom: window.innerHeight < 700 ? '10px' : '60px', width: '303px', height: '42px', display: 'flex', alignItems: 'center', justifyContent: 'center',pointerEvents:btnClickable?'auto':'none' }}>
            <img src={imgNext} style={{ width: '26px', height: '26px' }} />
          </SoundBtn>}
        </div>}

      {inApp &&
        <div style={{
          width: '100%',
          display: 'flex', flexDirection: 'row',
          alignItems: 'center', justifyContent: 'center',
          alignContent: 'center',
          position: 'absolute', zIndex: 100,
          top: statusBarHeight > 0 ? statusBarHeight + 5 : 21
        }} >
          <div style={{ flexGrow: 1 }} />
          <img onClick={() => {
            doClose()
          }} src={imgClose} style={{ width: '24px', height: '24px', margin: '0 20px 0 0' }} />


        </div>
      }





      <Popup overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }} style={{
        width: "100%", height: '100%', backgroundColor: 'transparent',
      }} visible={customRl} onClose={() => setCustomRl(false)}>
        <div style={{ width: "100%", height: '100%', display: 'flex', position: 'relative', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}
          onClick={() => { setCustomRl(false); }}>
          <div ref={inputDialogRef} onClick={(event) => { event.stopPropagation(); }} style={{ width: "262px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className='RlDialogBg'>
            <div style={{ fontFamily: 'ipix', fontSize: '25px' }}>{`你是${taLabel(props.detail.sex)}的什么人？`}</div>
            <input ref={inputRlRef} placeholder='点击输入' maxLength={4} style={{ marginTop: '30px', width: '100%', height: '38px' }} className='Input' type="text" />

            <SoundBtn className="Btn" style={{ width: '94px', height: '42px', alignSelf: 'center', margin: 'auto', marginTop: '23px', fontFamily: 'ipix' }}
              onClick={() => {
                doSetCustomRelation()
              }}
            >确认</SoundBtn>


          </div>
        </div>

      </Popup>

    </div>
  );
})

export default Relation;


