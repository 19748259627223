const dev = window.funeral_debug;

export const BASE_URL = dev ?
"http://test.cijianlink.com"
:
"https://funeral.cijianlink.com"
export const API_SERVER =  `${BASE_URL}/api/`;

export const WX_REDIRECT = `${BASE_URL}/api/openapi/weixin/callback`

export const SHARE_URL = `${BASE_URL}/view/`

export const WX_APPID = 'wxe60cce306c683fa9';