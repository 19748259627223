import React, { forwardRef, useState } from 'react';
import './CheckIn.css';
import XSDataContext from './context/XSDataContext';
import imgCloud from './images/download_cloud.png';
import imgBack from './images/icon_back.png';
import imgDownloadBtn from './images/img_download_btn.png';
import imgLogo from './images/img_download_logo.png';
import imgDownloadTips from './images/img_download_tips.png';
import imgLogo1 from './images/img_logo_1.png';


import Lottie from 'lottie-react';
import { Environment } from './util/environment';
import { useNavigate, useParams } from 'react-router-dom';
import { xslog } from './util/XSLog';

interface DownloadProps {
}
const Download = forwardRef<any, DownloadProps>((props, ref) => {
  const { environment } = React.useContext(XSDataContext);
  const [mask, setMask] = useState(false);

  const { ghostLottie } = React.useContext(XSDataContext);

  const params = useParams();
  const navigate = useNavigate();
  xslog(`params['canback'] ${params['canback']}`);
  const [cangoBack, setcangoBack] = useState(params['canback'] === 'canback')


  const download = () => {
    if (environment == Environment.WECHAT || environment == Environment.QQ_APP) {
      setMask(true)
    } else if (environment == Environment.MOBILE_Android) {
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4 && xhr.status === 200) {
          // console.log('1', this.responseText);
          // console.log('2', eval('[' + this.responseText) + ']');
          var obj = JSON.parse(this.responseText)
          console.log('1', obj.code);
          console.log('2', obj.data.url);
          let androidUrl = obj.data.url
          window.location.href = androidUrl;
        }
      });
      xhr.open("GET", "https://collection.cijian.link/open-api/version");
      xhr.send();


    } else if (environment == Environment.MOBILE_iOS) {

      window.location.href = "https://apps.apple.com/cn/app/%E5%B9%B8%E8%AF%86/id1502798617"
    }
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <div className='Main' style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100vw', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>

      <img src={imgCloud} style={{ width: '100vw', position: 'absolute', top: '0px' }} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', alignContent: 'center', position: 'absolute', width: '100%', height: '100%' }}>
        <img src={imgLogo} style={{ width: '100%', paddingBottom: '86px' }} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center', position: 'absolute', width: '100%', bottom: '0px' }} >
        <img src={imgLogo1} style={{ width: '100%', marginBottom: '20px' }} />
        <div style={{ marginBottom: '110px', position: 'relative', height: '58px' }}>
          <img onClick={() => { download() }} src={imgDownloadBtn} style={{ width: '319px' }} />

          <div style={{
            pointerEvents: 'none',
            width: 58, height: 58, overflow: 'hidden', transform: 'rotateY(180deg)',
            display: 'flex', justifyContent: 'center', position: 'absolute', left: '7px', top: '0px'
          }}>
            {ghostLottie && <Lottie animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
          </div>
        </div>
      </div>

      {cangoBack && <div style={{
        width: '100vw', 
        display: 'flex', height: '30px',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        position: 'absolute',top:'0px'

      }} >
        <img src={imgBack} style={{ width: '24px', height: '24px', margin: '15px 0 0 15px', }} onClick={goBack} />


      </div>}


      {mask ? <div style={{ position: 'absolute', width: '100%', height: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.4)' }}>
        <img src={imgDownloadTips} style={{ width: '338px', position: 'absolute', right: '10px' }} />

      </div> : <></>}




    </div>
  );
})

export default Download;




