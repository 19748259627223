import { Toast } from "react-vant";
import { xslog } from "./XSLog";


export function notify(message: string) {
   
    xslog(`notify ${message}`)
    // Notify.show({ message: message, color: '#FFFFFF', background: '#000000' });
    Toast.info(message)

}