import Lottie from 'lottie-react';
import { animate, cubicBezier, linear } from 'popmotion';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Welcome.css';
import SoundBtn from './components/SoundBtn';
import XSCreateContext from './context/XSCreateContext';
import XSDataContext from './context/XSDataContext';
import imgQuote from './images/icon_quote.png';
import imgCloud from './images/img_confirm_cloud.png';
import { isEmpty, outAnimation } from './util/CommonUtil';
import { XsHttpCient } from './util/HttpUtil';
import { notify } from './util/NotifyUtil';

interface ConfirmProps {
  goNext: () => void;
}
const Confirm = forwardRef<any, ConfirmProps>((props, ref) => {
  const { statusBarHeight, ghostLottie } = React.useContext(XSDataContext);
  const { createInfo, setCreateInfo } = React.useContext(XSCreateContext);
  const [showContent, setShowContent] = useState("")
  const rootRef = useRef(null);
  const lottieRef = useRef(null);
  const contentRef = useRef(null);
  const cloudRef = useRef(null);
  const navigate = useNavigate();
  const [contentText, setContentText] = useState(null);
  const [showBtn, setShowBtn] = useState(false)
  let interval
  useEffect(() => {
    if (contentText) {
      let index = 0;
      interval = setInterval(() => {
        setShowContent((prev) => prev + contentText[index++]);
        if (index >= contentText.length - 1) {
          clearInterval(interval);

          setShowBtn(true)
        }
      }, 50); // 控制每个字符出现的速度
    }
    return () => clearInterval(interval);
  }, [contentText]);

  useEffect(() => {
    enterAnimation();
    return () => { };
  }, []);
  const enterAnimation = () => {
    // contentRef.current.style.opacity = 0;
    if (cloudRef.current) {
      cloudRef.current.style.opacity = 0;
    }
    const ghostTransY = 234
    if (lottieRef.current) {
      lottieRef.current.style.transform = `rotateY(180deg) translate(${ghostTransY}px,0px)`;
    }
    animate({
      from: 0,
      to: 1,
      ease: linear,
      duration: 500,
      onUpdate: (progress) => {
        if (cloudRef.current) {
          cloudRef.current.style.opacity = progress;
        }
      },
      onComplete: () => {


      }
    })
    setTimeout(() => {
      animate({
        from: 0,
        to: 1,
        ease: cubicBezier(0, 0, 0.45, 0.99),
        duration: 800,
        onUpdate: (progress) => {

          if (lottieRef.current) {
            lottieRef.current.style.transform = `rotateY(180deg) translate(${ghostTransY - ghostTransY * progress}px,0px)`;
          }
        },
        onComplete: () => {

        }
      })
    }, 300)
    setContentText(`好了 我们已经在赛博墓园\n为你安排好了一处绝佳的位置\n希望你从此能在这里安眠`);
  }

  const okokok = () => {
    let postData = {
      name: createInfo.name,
      avatar: createInfo.avatar,
      birthday: createInfo.birthday,
      reason: createInfo.reason,
      skin: createInfo.stoneIndex,
      epitaph: createInfo.words,
      bgm: createInfo.musicIndex,
      lastwishList: []
    }
    createInfo.wishes.forEach((item) => {
      if (!isEmpty(item.content)) {
        postData.lastwishList.push(item.content)
      }
    })
    XsHttpCient.post('funeral/registe', postData).then((res) => {


      outAnimation(rootRef.current, () => { navigate('/mine/1') })

    }).catch((err) => {
      notify(err.msg)
    });



  }

  const btnRef = useRef(null);
  useEffect(() => {
    if (showBtn) {
      setTimeout(() => {
        animate({
          from: 0,
          to: 1,
          ease: linear,
          duration: 300,
          onUpdate: (progress) => {
            if (btnRef.current) {
              btnRef.current.style.opacity = progress
            }
          }
        })
      }, 200)
    } else {
      if (btnRef.current) {
        btnRef.current.style.opacity = 0
      }
    }
  }, [showBtn]);

  return (
    <div ref={rootRef} style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>

      <img ref={cloudRef} src={imgCloud} style={{ width: '100%', marginTop: `${statusBarHeight + 17}px` }} />

      <div style={{ flexGrow: 1 }} />
      <div style={{ position: 'relative' }}>
        <div ref={contentRef} style={{ display: 'flex', flexDirection: 'row', position: 'relative', marginBottom: window.innerHeight < 700 ? 100 : 240 }} >

          <img src={imgQuote} style={{ width: '24px', height: '24px', marginLeft: '28px' }} />
          <p style={{ marginTop: '12px', fontSize: '20px', lineHeight: '40px', height: '106px', whiteSpace: 'pre-line' }}>
            {showContent}
          </p>



        </div>
        <div ref={lottieRef} style={{
          pointerEvents: 'none',
          width: 250, height: 250, transform: 'rotateY(180deg)',
          display: 'flex', justifyContent: 'center', position: 'absolute', top: '-250px', left: '-16px',
        }}>
          {ghostLottie && <Lottie animationData={ghostLottie} loop={true} autoPlay={true} onComplete={() => { }} />}
        </div>
      </div>
      {showBtn &&
        <SoundBtn onClick={() => { okokok() }}  style={{ alignSelf: 'center', position: 'absolute', bottom: `${((window.innerHeight > 700) ? 57 : 10)}px` }} >
          <div ref={btnRef} className="Btn" style={{ opacity: 0, width: '313px', height: '50px' }}> 确认安葬</div>

        </SoundBtn>
      }
    </div>
  );
})

export default Confirm;


