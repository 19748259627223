// ThemeProvider.js
import React, { useState } from 'react';
import XSCreateContext from './XSCreateContext';

interface XSCreateProviderProps {
    children: any;
}

export const XSCreateProvider: React.FC<XSCreateProviderProps> = ({ children }) => {

    const [createInfo, setCreateInfo] = useState(null);

 

 

    return (

        <XSCreateContext.Provider value={{createInfo,setCreateInfo }}>
            {children}
        </XSCreateContext.Provider>
    );
};