// ThemeProvider.js
import React, { useEffect, useRef, useState } from 'react';
import XSMusicContext from './XSMusicContext';
import { bgmusic } from '../beans/Constrants';
import { xslog } from '../util/XSLog';

interface XSMusicProviderProps {
    children: any;
}


export const XSMusicProvider: React.FC<XSMusicProviderProps> = ({ children }) => {

    const bgPlayerRef = useRef(null)
    const clickPlayerRef = useRef(null)
    const clickTombPlayerRef = useRef(null)
    const clickMusicPlayerRef = useRef(null)

    const musicPlayerRef = useRef(null)

    const [musicStatus, setMusciStatus] = useState(0)
    useEffect(() => {

        xslog('[[[ XSMusicProvider start ]]]')
        const bgPlayer = new Audio(bgmusic);
        bgPlayer.loop = true;
        bgPlayerRef.current = bgPlayer


        const clickPlayer = new Audio('/music/click.mp3');
        clickPlayerRef.current = clickPlayer

        const clickTombPlayer = new Audio('/music/click_tomb.mp3');
        clickTombPlayerRef.current = clickTombPlayer

        const clickMusicPlayer = new Audio('/music/click_music.mp3');
        clickMusicPlayerRef.current = clickMusicPlayer
        xslog('[[[ XSMusicProvider end ]]]')
        // 清除事件监听器
        return () => {

        };
    }, []);


    const startPlayBgMusic = () => {
        xslog("startPlayBgMusic");
        if (bgPlayerRef.current && bgPlayerRef.current.pause) {
            bgPlayerRef.current.play().catch(error => {
                xslog("startPlayBgMusic error",error);
              });;

            xslog("startPlayBgMusic done");
        }
    }

    const playClickSound = () => {
        if (clickPlayerRef.current) {
            clickPlayerRef.current.play().catch(error => {
                xslog("play error",error);
              });;;
        }
    }

    const playClickTombSound = () => {
        if (clickTombPlayerRef.current) {
            if (!clickTombPlayerRef.current.paused) {
                clickTombPlayerRef.current.pause();
                clickTombPlayerRef.current.currentTime = 0;
              }
            clickTombPlayerRef.current.play().catch(error => {
                xslog("play error",error);
              });;;;
        }
    }

    const playClickMusicSound = () => {
        if (clickMusicPlayerRef.current) {
            if (!clickMusicPlayerRef.current.paused) {
                clickMusicPlayerRef.current.pause();
                clickMusicPlayerRef.current.currentTime = 0;
              }
            clickMusicPlayerRef.current.play().catch(error => {
                xslog("play error",error);
              });;;;
        }
    }


    const pauseOrPlayMusic = (pause: boolean) => {
        xslog("pauseOrPlayMusic", pause, bgPlayerRef.current, musicPlayerRef.current);
        if (pause) {

            if (bgPlayerRef.current) {

                xslog("pauseOrPlayMusic bgPlayerRef ", bgPlayerRef.current.paused);
                if (!bgPlayerRef.current.paused) { setMusciStatus(1) }
                bgPlayerRef.current.pause()
            }
            if (musicPlayerRef.current) {

                xslog("pauseOrPlayMusic musicPlayerRef ", musicPlayerRef.current.paused);
                if (!musicPlayerRef.current.paused) {
                    setMusciStatus(2)
                }
                musicPlayerRef.current.pause()
            }
        } else {
            if (musicStatus == 1) {
                if (bgPlayerRef.current) {
                    bgPlayerRef.current.play().catch(error => {
                        xslog("play error",error);
                      });;;
                }
            } else if (musicStatus == 2) {
                if (musicPlayerRef.current) {
                    musicPlayerRef.current.play().catch(error => {
                        xslog("play error",error);
                      });;;
                }
            }
            setMusciStatus(0)
        }
    }


    return (

        <XSMusicContext.Provider value={{ bgPlayerRef, musicPlayerRef, pauseOrPlayMusic, startPlayBgMusic, playClickSound ,playClickTombSound,playClickMusicSound,}}>
            {children}
        </XSMusicContext.Provider>
    );
};