import { Environment } from "./environment";
import { xslog } from "./XSLog";

import { ShareData, UserInfo } from '../beans/DataTypes';

export function getUserInfo(callback, error?) {
    callNativeMethod('getUserInfo', {}, (value: {}) => {
        xslog("getUserInfo "+value);
        var user = value as UserInfo
        callback(user)
    }, error)
}

export function selectAvatar() {
    callNativeMethod('selectAvatar', {}, ()=>{})
}

export function doClose() {
    callNativeMethod('doClose', {}, ()=>{})
}

export function getStatusBarHeight(callback, error?) {
    callNativeMethod('getStatusBarheight', {}, callback, error)
}


export function shareNative(shareData: ShareData, callback) {
    callNativeMethod('share', shareData, callback)
}


export function callNativeMethod(methodName, params = {}, callback, error?: (() => void)) {
    const message = {
        method: methodName,
        params: params
    };

    try {
        const environment = window.appEnvironment as Environment;
        if (environment == Environment.ANDROID_APP) {
            // Check if the Android interface is available
            const result = window.cjlk[methodName](JSON.stringify(params));
            xslog(`callNativeMethod ANDROID_APP result ${result}`)
            callback(JSON.parse(result));
        }
        // Check if the iOS interface is available
        else if (environment == Environment.IOS_APP) {
            // Define a handler to receive the result from iOS
            window[`${methodName}Handler`] = {
                postMessage: function (result) {
                    xslog(`callNativeMethod IOS_APP result ${result}`)
                    callback(result);
                }
            };
            // Send a message to iOS to call the native method
            window.webkit.messageHandlers.nativeHandler.postMessage(message);
        } else {

            callback({});
        }
    } catch (e) {
        xslog(`callNativeMethod error ${e}`)
        if (error) {
            error();
        } else {
            callback({});
        }
    }
}