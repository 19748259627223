import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './Epitaph.css';

import XSDataContext from './context/XSDataContext';
import imgTitle from './images/img_title_epitaph.png';
import { xslog } from './util/XSLog';

import SoundBtn from './components/SoundBtn';
import XSCreateContext from './context/XSCreateContext';
import TombstoneComp from './TombstoneComp';
import { inAnimation, isEmpty, outAnimation, replaceHttpsUrl, shuffleArray } from './util/CommonUtil';
import { ContentAudit, ContentAuditScene } from './util/HttpUtil';
import { selectAvatar } from './util/NativeBridge';
import { notify } from './util/NotifyUtil';
import { Environment } from './util/environment';

interface EpitaphProps {
  goNext: () => void;
}
const Epitaph = forwardRef<any, EpitaphProps>((props, ref) => {

  const [showMaskAndDm, setShowMaskAndDm] = useState(false);

  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const { createInfo, setCreateInfo } = React.useContext(XSCreateContext);
  const { statusBarHeight, environment,keyboardEvent, onKeyBoardShow, user } = React.useContext(XSDataContext);

  const rootRef = useRef(null);
  const dmContainerRef = useRef(null);

  const dmGroupRef = useRef(null);

  const stoneRef = useRef(null);
  const stoneInitTop = 155;

  


 













 







  const dmDataSrc= [{ key: 0, content: '没什么事儿我先挂了' },
  { key: 1, content: '这个人很懒 什么东西都没留' },
  { key: 2, content: '当你看到这行字时你已经踩到我了' },
  { key: 3, content: '谢谢你来看我 有空我也会去看你的' },
  { key: 4, content: '供品不要放香菜 谢谢' },
  { key: 5, content: '一居室，求合租，细节面议' },
  { key: 6, content: '广告位招租' },
  { key: 7, content: '陪聊，提供夜间服务' },
  { key: 8, content: '清冷骨感我悟了' },
  { key: 9, content: '住房问题已解决' },
  { key: 10, content: '接墓地种草、测评广 私信滴滴 备注品牌' },
  { key: 11, content: '观看30秒广告即可复活墓主' },
  { key: 12, content: '来都来了 磕一个吧' },
  { key: 13, content: '今日不便破土相迎，他日必将深夜拜访' },
  { key: 14, content: '灵柩长埋深谷底～' },
  { key: 15, content: '有大瓜请烧给我，要无删减的谢谢' },
  { key: 16, content: '爱马仕Kelly橙色荔枝皮' },
  { key: 17, content: 'cpdd #185+ #篮球 #黑皮体育生' },
  { key: 18, content: '臣退了，这一退，就是一辈子' },
  { key: 19, content: '以后不怕鬼了' },
  { key: 20, content: '这一次谁都别想叫醒我' },
  { key: 21, content: '没时间来看我，可以点俩男模替你烧纸' },
  { key: 22, content: '做了鬼还能做你的舔狗吗' },
  { key: 23, content: '这辈子就这样丸辣！' }]

  const [dmData ,setDmData] = useState([])

  const imgStyle = {
    width: '120px',
    height: '115px'
  }

  const DM = (item) => {
    return (<div key={item.key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: `10px` }}>
      <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.11)', padding: '8px 15px 8px 15px', fontSize: '18px', width: `calc(100% - 60px)`, color: '#FFFFFF', textAlign: 'center' }}>   {`“${item.content}”`}</div>
    </div>)
  }
  const SelHeight = 38;
  const SelWidth = 144;
  const wordsValue = () => {
    if (createInfo && createInfo.words !== null && createInfo.words !== '') {
      return createInfo.words
    }
    return ''
  }

  const [words, setWords] = useState(wordsValue());

  const avatarValue = () => {
    if (createInfo && createInfo.avatar !== null && createInfo.avatar !== undefined && createInfo.avatar !== '') {
      return replaceHttpsUrl(createInfo.avatar)
    }
    if (user.avatar != null) { return replaceHttpsUrl(user.avatar); }
    return ''
  }

  const [avatar, setAvatar] = useState(avatarValue());

  useEffect(() => {
    xslog('epitaph onKeyBoardShow  ', stoneRef.current, JSON.stringify(keyboardEvent));
    if(environment ===Environment.ANDROID_APP){
    if (stoneRef.current ) {
      if (keyboardEvent.show) {
        let transY = -(stoneInitTop+10);
        stoneRef.current.style.top = `${stoneInitTop + transY}px`
        setKeyboardHeight(keyboardEvent.height);
      } else {
        stoneRef.current.style.top = `${stoneInitTop}px`
        xslog("no")
      }
    }}else{
      if (stoneRef.current ) {
        if (keyboardEvent.show) {
          stoneRef.current.style.top = `${stoneInitTop -50}px`
          setKeyboardHeight(250);
        } else {

          stoneRef.current.style.top = `${stoneInitTop }px`
        }
      }
    }

    setShowMaskAndDm((pre) => !pre);
  }, [keyboardEvent]);

  // useEffect(() => {

  //   xslog(`showMaskAndDmshowMaskAndDm ${showMaskAndDm}`)
  // }, [showMaskAndDm]);

  // const resetAnimation = () =>{
  //   dmContainerRef.current.style.animation = 'none';
  //   dmContainerRef.current.style.animation = '';
  // }
  useEffect(() => {
xslog("DMS src ",dmDataSrc)
shuffleArray(dmDataSrc)
dmDataSrc.forEach((item,index)=>{
  item.key = index;
})

xslog("DMS shuffleArray ",dmDataSrc)
setDmData(dmDataSrc)



    onKeyBoardShow(false, 0);
    // window.onKeyBoardShow = (show: boolean, height: number) => {
    //   xslog('onKeyBoardShow', show, height)
    //   if (stoneRef.current) {
    //     if (keyboardEvent.show) {
    //       const rect = stoneRef.current.getBoundingClientRect();
    //       let vH = (window.innerHeight - keyboardEvent.height);
    //       let transY = 0;
    //       if (vH > rect.height) {
    //         transY = (vH - rect.height) / 2 - rect.top;
    //       } else {
    //         transY = vH - rect.bottom;
    //       }
    //       stoneRef.current.style.transform = `translateY(${transY}px)`
    //       setKeyboardHeight(height/ (window.devicePixelRatio || 1));
    //     } else {
    //       stoneRef.current.style.transform = ``
    //     }
    //   }

    //    setShowMaskAndDm((pre)=>!pre);

    // }
    window.avatarSelected = (url) => {
      ContentAudit(ContentAuditScene.avatar, url, () => {
        setAvatar(url)
      }, (error) => {
        notify(error.msg);
      })

    }


    inAnimation(rootRef.current,()=>{})
    return () => {
      // window.onKeyBoardShow = null
    };
  }, []);

  const clickSelectAvatar = () => {
    selectAvatar();
  }

  const clickNext = () => {

    if (isEmpty(words)) {
      notify('请留下你的墓志铭')
      return
    }
    ContentAudit(ContentAuditScene.words, words, () => {
      let _createInfo = createInfo
      _createInfo.avatar = avatar
      _createInfo.words = words
      setCreateInfo(_createInfo)
      outAnimation( rootRef.current,()=>{   props.goNext()})  
    }, (error) => {
      notify(error.msg);
    })


  }





  return (
    <div ref={rootRef} style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100vw', alignItems: 'center', position: 'relative' ,overflow:'hidden'}}>

      <img   src={imgTitle} style={{ width: `${34500/393}%`, marginTop: `${statusBarHeight + ((window.innerHeight > 700) ? 56 : 28)}px` , zIndex: showMaskAndDm?0:101}} />

      {showMaskAndDm && (
        <div style={{ pointerEvents: 'none', backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 99, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute' }}>

        </div>)

      }
      {/* <div ref={stoneRef} style={{ zIndex: 100, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>

        <img src={imgStoneApple} style={{ width: '415px', height: '417px' }} />
        <img onClick={clickSelectAvatar} src={avatar} style={{ width: '30px', height: '30px', position: 'absolute', top: '70px', }} />
        <textarea style={{
          backgroundColor: 'transparent',
          border: '0px',
          transform: 'skew(0deg, -10deg)',
          marginLeft: '15px', width: '100px', position: 'absolute', top: '119px',
          fontSize: '25px', color: '#FFFFFF'
        }}
          className='StoneShadow' placeholder='点击输入' value={words} onChange={(e) => { setWords(e.target.value) }}>
        </textarea>
      </div> */}

      <div ref={stoneRef} style={{ width: '100%', position: 'absolute', top: `${stoneInitTop}px`, zIndex: 100 }}>
        <TombstoneComp edit={true} detail={{
          skin: createInfo.stoneIndex,
          epitaph: createInfo.words,
          birthday: createInfo.birthday,
          dieday: Date.now(),
          name: createInfo.name,
          avatar: avatar,
        }} editEpitaph={words} setEditEpitaph={setWords} clickSelectAvatar={clickSelectAvatar} />
      </div>


      {showMaskAndDm ? <></> : <SoundBtn onClick={() => { clickNext() }} className='Btn' style={{ height: `48px`, width: `313px`, position: 'absolute', bottom: `${((window.innerHeight > 700) ? 49 : 10)}px` ,zIndex:101}}>就这样</SoundBtn>}
      {showMaskAndDm && (
        <div ref={dmGroupRef} style={{ pointerEvents: 'none', zIndex: 101, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute' }}>
          <div className='DmContainer' style={{ overflow: 'hidden', width: '100%', position: 'absolute', height: '146px', bottom: `${keyboardHeight}px` }}>
            <div ref={dmContainerRef} className='DmScroll' style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop:'10px', marginBottom: `15px` }}>
      <div style={{  fontSize: '30px',  color: '#FFFFFF', textAlign: 'center' }}>「 看看大家的墓志铭 」</div>
    </div>
             
              {dmData.map(item => (
                DM(item)
              ))}</div>
          </div>
        </div>)

      }
    </div>
  );
})

export default Epitaph;




